import { useEffect, useState } from "react";
import {  Button, Drawer, message, Popconfirm } from "antd";
import Styles from "./Productdetails.module.css";
import { _GET, _POST } from "../../../common/commonFunc";
import { Box, Divider, Skeleton} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import CustomIconButton from "./CustomIconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip } from "@mui/material";


const ProductCollaborationGrid = ({data} : any) => {

console.log("collaboration details data is : ", data)
    const [extednCollaboration, setExtendCollaboration] = useState(false);
    const [service, setService] = useState<any>([]);
    const [rows, setRows] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);


    const extendCollab = async (id: any) => {
        console.log("🚀 ~ endCollab ~ id:", id);
        const payload = {};
        try {
          const response = await _POST(
            `supplier/extend-collaboration-request/${id}`,
            payload,
            true
          );
          if (response.status === true && response.statusCode === 200) {
            message.success("Collaboration extended successfully");
          } else {
            message.error(
              response.data && response.statusCode
                ? response.message
                : "Extend Collaboration failed"
            );
          }
        } catch (error) {
          console.log("🚀 ~ extendCollab ~ error:", error);
        }
      };

    const confirmExtendCollab = (request_id : any ) => {
        extendCollab(request_id);
      };

    const columnsCollab = [
        {
            field: 'collabname',
            headerName: '', width: 250, sortable: false, renderCell: (params : GridRenderCellParams) => {
                console.log("🚀 ~ ProductCollabGrid ~ params:", params);
                return (
                    <div className={Styles.dgRowDiv2}>
                    {params.row.profilePic ? 
                    <img src={params.row.profilePic} alt="Product" className={Styles.productImage} />
                    :
                    <div
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      backgroundColor: "darkslategrey",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                      marginRight: "10px"
                    }}
                  >
                    {params?.row?.fullName[0].toUpperCase()}
                  </div>
                }
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{textOverflow:"clip"}}>{params.row.fullName} </div>

                    </div>
                </div>
                )
            }
            
        },
        {
            field: 'duration',
            headerName: '', width: 280, sortable: false, renderCell: (params : GridRenderCellParams) => (
                <div className={Styles.dgRowDiv2}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ color: "#30263E80", fontSize: "10px" }}>Collaboration Duration</div>
                        <div>{params.row.startDate} to {params.row.endDate}</div>
                        {params.row.daysLeft <= 10 ? 
                        (
                        <div style={{ fontSize: "10px", color:"red" }}>{params.row.daysLeft <= 0 ? "collaboration expired" : `${params.row.daysLeft} Days Left`}</div>
                        )
                        :(
                        <div style={{ fontSize: "10px" }}>{params.row.daysLeft} Days Left</div>
                        )}
                    </div>

                </div>
            )
        },
        {
            field: 'assigned',
            headerName: '', width: 130, sortable: false, renderCell: (params : GridRenderCellParams) => (
                <div className={Styles.dgRowDiv2}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ color: "#30263E80", fontSize: "10px" }}>Assigned</div>
                        <div style={{alignItems:"center", justifyContent:"center",display:"flex", flexDirection:"row"}}><div>{params.row.availableQty} </div> 
                        <div style={{marginTop:"5px", marginLeft:"1px"}}><Tooltip title="Assigned Qty">
                            <InfoOutlinedIcon sx={{ fontSize: "medium" }} />
                        </Tooltip> </div></div>
                    </div>

                </div>
            )
        },
        {
            field: 'actions',
            headerName: '',
            width: 150,
            sortable: false,

            renderCell: (params : GridRenderCellParams) => (
                <div className={Styles.dgRowDiv3}>
                    { params.row.daysLeft <= 10 && (
                        <Popconfirm
                        title="Are you sure you want to extend this collaboration?"
                        onConfirm={() => confirmExtendCollab(params.row.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                    <Button className={Styles.addButton}>Extend</Button>
                    </Popconfirm>)}
                        <CustomIconButton icon={<ClearIcon />} status={"true"}/>
                </div>
            ),
        },
    ];

    const nodata  = [
        {
            field: 'field1',
            headerName: '', width: 250, sortable: false, renderCell: () => (
                <></>
            )
        },
        {
            field: 'field2',
            headerName: '', width: 450, sortable: false, renderCell: (params: GridRenderCellParams) => (
               <h3>
                {params.row.message}
                </h3>
            )
        },
        
    ]

    const formatDate = (unixTimestamp: any) => {
        const date = new Date(parseInt(unixTimestamp) * 1000);
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      };

    
    useEffect(() => {
        if (data && Array.isArray(data.collaborations) && data.collaborations.length > 0 ) {
            const filteredCollaborations = data.collaborations.filter((collab : any) => (
                collab &&
                collab.collaboration_id !== null &&
                collab.collaboration_start_date !== null &&
                collab.collaboration_end_date !== null &&
                collab.days_left !== null &&
                collab.collaborated_freelancer &&
                collab.collaborated_freelancer.first_name !== null &&
                collab.collaborated_freelancer.last_name !== null &&
                // collab.collaborated_freelancer.profile_pic !== null &&
                collab.total_assigned_quantity !== null
            ));
          const mappedRows = filteredCollaborations.map((collab: any) => ({
            id: collab.collaboration_id,
            startDate: formatDate(collab.collaboration_start_date),
            endDate: formatDate(collab.collaboration_end_date),
            daysLeft: collab.days_left,
            fullName: collab.collaborated_freelancer.first_name + " " + collab.collaborated_freelancer.last_name ,
            profilePic: collab.collaborated_freelancer.profile_pic,
            availableQty: collab.total_assigned_quantity
          }));
          if(filteredCollaborations.length > 0){
          setRows(mappedRows);
          setColumns(columnsCollab)
        }else {
            setRows([ {
                id: 1,
                message: "No Collaborations made for this product"
            }])
            setColumns(nodata)
          }

        } else {
            setRows([ {
                id: 1,
                message: "No Collaborations made for this product"
            }])
            setColumns(nodata)
        }
      }, [data]);


    return (
<div>

<div className={Styles.commonDiv}>
                                    {rows && <DataGrid rows={rows}
                                        columns={columns}
                                        disableColumnMenu
                                        hideFooterSelectedRowCount
                                        disableColumnFilter
                                        disableDensitySelector
                                        hideFooter={true}
                                        columnHeaderHeight={0}
                                        rowHeight={70}
                                        sx={{
                                            '&, [class^=MuiDataGrid]': { border: 'none' },
                                            '&>.MuiDataGrid-main': {
                                                '&>.MuiDataGrid-columnHeaders': {
                                                    borderBottom: 'none',
                                                },
                                                '& .MuiDataGrid-cell': {
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    borderBottom: "none"
                                                },
                                                "& .MuiDataGrid-columnHeaderTitle": {
                                                    whiteSpace: "normal",
                                                    lineHeight: "normal",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                },
                                                "& .MuiDataGrid-columnHeader": {
                                                    height: "unset !important",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                },
                                                "& .MuiDataGrid-columnHeaders": {
                                                    maxHeight: "unset !important",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                },
                                                "& .MuiDataGrid-row": {
                                                    borderBottom: "1px solid #ccc"
                                                },
                                                '& .MuiDataGrid-colCellTitle': {
                                                    display: 'none',
                                                },

                                            },
                                        }}

                                    />}
                                </div>
                                <Drawer
                                    title="EXTEND COLLABORATION"
                                    placement="right"
                                    closable={true}
                                    onClose={() => setExtendCollaboration(false)}
                                    open={extednCollaboration}
                                    width={600}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: service?.service_desc,
                                        }}
                                        className={Styles.serviceDescription}
                                    ></div>
                                </Drawer>
                                <Divider />
                                </div>
)    
}

export default ProductCollaborationGrid;