import React, { Dispatch } from "react";
import { AnyAction } from "redux";
import { _GET, _PATCH, _POST } from "../../common/commonFunc";
import ActionType from "../types";
import _ from "lodash";

export const getFreelancerProfile =
  (freelancerId: any, callback: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await _GET(
        `userProfile/freelancerProfile/${freelancerId}`,
        true,
        false
      );

      let servicesResData = [];
      let productDetails = [];

      try {
        const servicesRes = await _GET(
          `services/getFreelancerAllServices/${freelancerId}`,
          true,
          false
        );
        servicesResData = _.get(servicesRes, "data", []);
      } catch (servicesError: any) {
        if (servicesError.response && servicesError.response.status === 404) {
          servicesResData = [];
        }
      }

      try {
        const ProductsRes = await _GET(
          `userProfile/freelancerAffiliatedProducts/${freelancerId}`,
          true,
          false
        );
        productDetails = _.get(ProductsRes, "data", []);
      } catch (productsError: any) {
        if (productsError.response && productsError.response.status === 404) {
          productDetails = [];
        }
      }

      const posts = await _GET(
        `social/user/post?userId=${freelancerId}`,
        true,
        false
      );

      let freelancerDetails = _.get(res, "data", []);
      const postData = _.get(posts, "data", []);

      if (res && res.statusCode === 200) {
        freelancerDetails = {
          ...freelancerDetails,
          freelancerServices: servicesResData,
          freelancerPosts: postData,
          freelancerProducts: productDetails,
        };

        await dispatch({
          type: ActionType.FREELANCER_PROFILE_DRAWER,
          payload: true,
        });

        await dispatch({
          type: ActionType.GET_FREELANCER_PROFILE,
          payload: freelancerDetails,
        });

        await callback(true);
      } else {
        await dispatch({
          type: ActionType.GET_FREELANCER_PROFILE,
          payload: [],
        });
        callback(false);
      }
    } catch (error) {}
  };

export const drawerNeedsToBeOpen =
  (freelancerId: any, callback: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      await dispatch({
        type: ActionType.FREELANCER_PROFILE_DRAWER,
        payload: true,
      });
    } catch (error) {}
  };
export const drawerNeedsToBeClose =
  (callback: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      await dispatch({
        type: ActionType.FREELANCER_PROFILE_DRAWER,
        payload: false,
      });
    } catch (error) {}
  };

export const followUser =
  (followingEntityId: any, followingType: string) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const payload = {
        following_entity_id: followingEntityId,
        following_type: followingType,
      };

      const res = await _POST("userProfile/follow", payload, true);
      if (res && res.statusCode === 200) {
      } else {
        console.error("Follow user failed:", res);
      }
    } catch (error) {
      console.error("Error in followUser action:", error);
    }
  };

export const UnfollowFollowing =
  (followingEntityId: any) => async (dispatch: Dispatch<AnyAction>) => {
    try {
      const payload = {};

      const res = await _PATCH(
        `userProfile/unfollowFollowings/${followingEntityId}`,
        payload,
        true
      );

      if (res && res.statusCode === 200) {
      } else {
        console.error("Unfollow user failed:", res);
      }
    } catch (error) {
      console.error("Error in Unfollowing action:", error);
    }
  };

export const getFreelancerChatData =
  (freelancerId: any, callback: any) =>
  async (dispatch: Dispatch<AnyAction>) => {
    try {
      const res = await _GET(
        `userProfile/freelancerProfile/${freelancerId}`,
        true
      );
      // const servicesRes = await _GET(
      //   `services/getFreelancerAllServices/${freelancerId}`,
      //   true
      // );
      let freelancerDetails = _.get(res, "data", []);
      // const servicesResData = _.get(servicesRes, "data", []);
      if (res && res.statusCode === 200) {
        await dispatch({
          type: ActionType.GET_FREELANCER_PROFILE,
          payload: freelancerDetails,
        });
        await callback(true);
      } else {
        await dispatch({
          type: ActionType.GET_FREELANCER_PROFILE,
          payload: [],
        });

        callback(false);
      }
    } catch (error) {}
  };
