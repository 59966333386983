import React from "react";
import Styles from "./Service.module.css";
export const FreelancerPostCard = ({ props, index }: any) => {
  function renderFile(url: string, index: number) {
    const extension = url.split(".").pop()?.toLowerCase() || "";

    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

    const videoExtensions = ["mp4"];

    if (imageExtensions.includes(extension)) {
      return (
        <img
          className={Styles.serviceImageDiv}
          key={index}
          src={url}
          alt="Uploaded file"
        />
      );
    }

    // Check if the extension is in the videoExtensions list
    else if (videoExtensions.includes(extension)) {
      return (
        <video
          className={Styles.serviceImageDiv}
          key={index}
          src={url}
          autoPlay={false}
          muted={false}
        ></video>
      );
    } else {
      return (
        <img
          className={Styles.serviceImageDiv}
          key={index}
          src={url}
          alt="Uploaded file"
        />
      );
    }
  }
  return (
    <div className={Styles.mainServiceBox}>
      {/* <img
        className={Styles.serviceImageDiv}
        src={props.post.imageUrls[0]}
        alt=""
      ></img> */}
      {renderFile(props.post.imageUrls[0], index)}
      <div className={Styles.serviceCategoryDiv}>
        <div>{props.post.caption}</div>
      </div>
      {/* <div className={Styles.seerviceDurationDiv}>{props.duration}</div> */}
    </div>
  );
};
