import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, Button, Dropdown, Input, Layout, Tabs } from "antd";
import { useHistory } from "react-router-dom";
import CollabTable from "../../OrderPages/freelancerComponets/SupplierColabTable";
import { _GET, getSupplierID } from "../../../common/commonFunc";
import Styles from "../Store/MyStore.module.css";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import InstaIcon from "../../../assests/InstaIcon.svg";
import TickTokIcon from "../../../assests/ticTokIcon.svg";
import FaceBookIcon from "../../../assests/faceBookIcon.svg";
import SnapIcon from "../../../assests/snapIcon.svg";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import CollabDetails from "../Inventory/CollabDetails";
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { filterFreelancers } from "../Utils/Filters";
import CollabTableList from "../../OrderPages/freelancerComponets/SupplierCollabTableList";
const { TabPane } = Tabs;

const Collab = () => {

  const [acceptedFreelancers, setAcceptedFreelancers] = useState<any[]>([]);
  const [pendingFreelancers, setPendingFreelancers] = useState<any[]>([]);
  const [rejectedFreelancers, setRejectedFreelancers] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const supplierID = getSupplierID();
  console.log("🚀 ~ Collab ~ SupplierId:", supplierID);



  const handleTabChange = (status: any) => {
    setActiveTab(status);
  };

  const history = useHistory();
  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const headers = [
    { id: "supplier_id", label: "ID", width: "0%" },
    { id: "full_name", label: "SERVICE PROVIDER NAME", width: "25%" },
    {
      id: "collaboration_start_date",
      label: "START DATE",
      width: "20%",
    },
    {
      id: "collaboration_duration_days",
      label: "NO. OF DAYS",
      width: "10%",
    },
    { id: "collaboration", label: "COLLABORATION", width: "20%" },
    { id: "action", label: "ACTION", width: "25%" },
  ];

  const rejectedHeaders = [
    { id: "supplier_id", label: "ID", width: "0%" },
    { id: "full_name", label: "SERVICE PROVIDER NAME", width: "20%" },
    { id: "collaboration_reject_reason", label: "REASON", width: "40%" },
    { id: "rejected_on", label: "REJECTED ON", width: "20%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];

  const reviewHeaders = [
    { id: "supplier_id", label: "ID", width: "0%" },
    { id: "full_name", label: "SERVICE PROVIDER NAME", width: "20%" },
    { id: "start_end_date", label: "START & END DATE", width: "20%" },
    { id: "collaborations", label: "COLLABORATION", width: "15%" },
    { id: "requested_products", label: "REQUESTED PRODUCTS", width: "15%" },
    { id: "request_type", label: "REQUEST TYPE", width: "15%" },
    { id: "action", label: "ACTION", width: "10%" },
  ];

  const getFreelancerList = async () => {
    try {
      const res = await _GET(
        // `supplier/allCollaborationRequests/${supplierID}`,
        'supplier/v1/allCollaborationRequests',
        true,
        false
      );
      if (res && res.status && res.data) {
        const formattedData = res.data.map((item: any) => ({
          ...item,
          full_name: `${item.freelancer_first_name} ${item.freelancer_last_name}`,
          social_media: {
            instagram: item.has_instagram ? InstaIcon : null,
            facebook: item.has_facebook ? FaceBookIcon : null,
            snapchat: item.has_snapchat ? SnapIcon : null,
            tiktok: item.has_tiktok ? TickTokIcon : null,
          },
          profile_pic:
            item.freelancer_profile_pic !== "NULL"
              ? item.freelancer_profile_pic
              : "Default Image URL",
        }));
        setAcceptedFreelancers(
          formattedData.filter(
            (freelancer: any) => freelancer.status === "Accepted"
          )
        );
        setPendingFreelancers(
          formattedData.filter(
            (freelancer: any) => freelancer.status === "pending"
          )
        );
        setRejectedFreelancers(
          formattedData.filter(
            (freelancer: any) => freelancer.status === "Rejected"
          )
        );
      }
    } catch (error) {
      console.log("Error fetching freelancers:", error);
    }
  };

  useEffect(() => {
    getFreelancerList();
  }, []);

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <h1>Collaborations</h1>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
              <TabPane
                tab={
                  <span className={Styles.countingSpanParent}>
                    ACCEPTED COLLABORATIONS{" "}
                    <span className={Styles.countingSpan}>
                      {acceptedFreelancers.length}
                    </span>
                  </span>
                }
                key="All"
              />
              {/* <TabPane
                tab={
                  <span className={Styles.countingSpanParent}>
                    REJECTED COLLABORATIONS{" "}
                    <span className={Styles.countingSpan}>
                      {rejectedFreelancers.length}
                    </span>
                  </span>
                }
                key="rejected"
              /> */}
{/* 
              <TabPane
                tab={
                  <span className={Styles.countingSpanParent}>
                    SENT REQUESTS{" "}
                    <span className={Styles.countingSpan}>
                      {pendingFreelancers.length}
                    </span>
                  </span>
                }
                key="pending"
              /> */}
            </Tabs>
          </div>
          <div className={Styles.actionsContainerNext}>
            <div className={Styles.searchDiv}>
              <img src={SearchIcon} alt="SearchIcon" />
              <Input
                placeholder="Search by ID, Name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={Styles.searchInput}
              />

              <IconButton>
                <Filter />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/home")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item>Collaborations</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{ height: "auto", width: "100%" }}
        className={Styles.parentContentdiv}
      >
        {activeTab === "All" && (
          <CollabTableList
            headers={headers}
            data={acceptedFreelancers}
            status="Accepted"
            
          />
        )}

        {/* {activeTab === "rejected" && (
          <CollabTableList
            headers={rejectedHeaders}
            data={rejectedFreelancers}
            status="Rejected"
          />
        )}

        {activeTab === "pending" && (
          <CollabTableList
            headers={reviewHeaders}
            data={pendingFreelancers}
            status="Completed"
          />
        )} */}
      </div>
    </Layout>
  );
};

export default Collab;
