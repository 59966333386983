import React, { useState } from 'react';
import Styles from "./CustomImageGallery.module.css"
import { Button, ButtonProps, IconButton, IconButtonProps, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from '@mui/material/styles';

// interface Image {
//     original: string;
//     thumbnail: string;
//   }

interface CustomImageGalleryProps {
  images: string[];
  color: any;
}

const placeholderImage = 'https://via.placeholder.com/300x200?text=Image+Not+Available'

const ColorButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  backgroundColor: "rgba(255,255,255,0.5)",
  color: "black",
  height: "50px",
  width: "50px",
  '&:hover': {
    backgroundColor: "rgba(255,255,255,0.8)",
  },
}));

function CustomImageGallery({ images, color }: CustomImageGalleryProps) {
  console.log("Images are : ", images)
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <>
      {color.color && 
      <div style={{ display: "flex", justifyContent: "left", marginLeft: "7px", alignItems:"center" }}>
        <div style={{
          background: color?.colorCode,
          height: "15px",
          width: "15px",
          borderRadius: "50%",
          border: "1px solid grey",
          marginRight: "5px"
        }}>
        </div>
        <Typography fontWeight={"bold"}>{color.color} </Typography>
      </div>}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "column", paddingBottom: "5px", paddingLeft: "5px", paddingRight: "5px", height: "100%" }}>

        <div className={Styles.customImageGallery}>

          {
            images ? (
              // <img src={images[currentImageIndex]} alt={`Image ${currentImageIndex + 1}`} />
              <img src={images[currentImageIndex] ? images[currentImageIndex] : placeholderImage} alt={"Image Not Available"} />
            ) : <></>
          }


          {images.length > 1 && <div className={Styles.navigationButtons}>
            <ColorButton onClick={handlePrevClick} className="elevatedElement iconBtn">
              < KeyboardArrowLeftIcon />
            </ColorButton>
            <ColorButton onClick={handleNextClick} className="elevatedElement iconBtn">
              <KeyboardArrowRightIcon />
            </ColorButton>
          </div>}


        </div>
        <div className={Styles.thumbnailContainer}>
          {images && images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={currentImageIndex === index ? Styles.activeThumbnail : ""}
              onClick={() => setCurrentImageIndex(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default CustomImageGallery;