import React, { useEffect, useState } from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useParams } from "react-router-dom";
import { CometChatMessages } from "@cometchat/chat-uikit-react";
import { v4 as uuidv4 } from "uuid";
import styles from "./ConferenceCall.module.css";
import "./my.css";
import ChatSidebar from "./ChatBar/ChatBar";
import { notification } from "antd";

const COMETCHAT_CONSTANTS = {
  APP_ID: "2507641c4fafd871",
  REGION: "EU",
  AUTH_KEY: "3d35c30bc412accc62d26140125ab94488be325c",
};

const PRESENTER_ID = "408e4b03-1039-4d60-919f-c7b027bb17a7";

const ConferenceCall = () => {
  const { sessionID: paramSessionID } = useParams();
  const [sessionID, setSessionID] = useState("");
  const [callSettings, setCallSettings] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [isPresenter, setIsPresenter] = useState(false);
  const [chatGroup, setChatGroup] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const groupGUID = "3d3b231b-e525-467f-b662-dee51a92394e";

  useEffect(() => {
    initializeCometChat();
  }, []);

  const initializeCometChat = () => {
    CometChat.init(
      COMETCHAT_CONSTANTS.APP_ID,
      new CometChat.AppSettingsBuilder()
        .setRegion(COMETCHAT_CONSTANTS.REGION)
        .build()
    )
      .then(() => {
        loginUser();
      })
      .catch((error) => {
        console.log("CometChat initialization failed with error:", error);
      });
  };

  const loginUser = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
    const UID = userInfo.id;
    if (!UID) {
      console.log("User ID not found");
      return;
    }
    CometChat.login(UID, COMETCHAT_CONSTANTS.AUTH_KEY)
      .then(() => {
        const isCurrentUserPresenter = UID === PRESENTER_ID;
        setIsPresenter(isCurrentUserPresenter);
        if (paramSessionID) {
          joinConferenceCall(paramSessionID, isCurrentUserPresenter);
        } else {
          createConferenceCall();
        }
      })
      .catch((error) => {
        console.log("Login failed with error:", error);
      });
    CometChat.getGroup(groupGUID).then(
      (group) => {
        setChatGroup(group);
        if (group.hasJoined === false) {
          CometChat.joinGroup(groupGUID, CometChat.GROUP_TYPE.PUBLIC).then(
            (joinedGroup) => {
              setChatGroup(joinedGroup);
            },
            (joinError) => {
              console.error("Error joining group:", joinError);
            }
          );
        }
      },
      (error) => {
        console.log("Error fetching group:", error);
      }
    );
  };

  const createConferenceCall = () => {
    const generatedSessionID = uuidv4();
    setSessionID(generatedSessionID);
    const settings = new CometChat.CallSettingsBuilder()
      .setSessionID(generatedSessionID)
      .enableDefaultLayout(true)
      .setIsAudioOnlyCall(false)
      .startWithAudioMuted(false)
      .startWithVideoMuted(false)
      .showMuteAudioButton(true)
      .showPauseVideoButton(true)
      .showScreenShareButton(true)
      .showEndCallButton(true)
      .build();
    setCallSettings(settings);
    setCallInProgress(true);
  };

  const joinConferenceCall = (existingSessionID, isPresenter) => {
    setSessionID(existingSessionID);
    const settings = new CometChat.CallSettingsBuilder()
      .setSessionID(existingSessionID)
      .enableDefaultLayout(true)
      .setIsAudioOnlyCall(!isPresenter)
      .startWithAudioMuted(!isPresenter)
      .startWithVideoMuted(!isPresenter)
      .showMuteAudioButton(isPresenter)
      .showPauseVideoButton(isPresenter)
      .showScreenShareButton(isPresenter)
      .showEndCallButton(true)
      .build();
    setCallSettings(settings);
    setCallInProgress(true);

    const presentationSettings = new CometChat.PresentationSettingsBuilder()
      .isPresenter(isPresenter)
      .build();

    CometChat.startPresentation(existingSessionID, presentationSettings);
  };

  const renderCall = () => {
    if (callSettings) {
      return <div id="conference-call" className={styles.conferenceCall} />;
    }
    return null;
  };

  const renderChat = () => {
    return (
      <CometChatMessages
        group={chatGroup}
        className={styles.chatHeader}
        messagesStyle={{
          height: "100%",
          msOverflowStyle: "none",
        }}
        hideMessageHeader
      />
    );
  };

  useEffect(() => {
    if (callInProgress && callSettings) {
      CometChat.startCall(
        callSettings,
        document.getElementById("conference-call"),
        new CometChat.OngoingCallListener({
          onUserJoined: (user) => {
            console.log("User joined the call:", user);
          },
          onUserLeft: (user) => {
            console.log("User left the call:", user);
          },
          onCallEnded: (call) => {
            console.log("Call ended:", call);
            setCallInProgress(false);
            notification.success({
              message: "Call ended",
              description: "Call ended successfully",
              placement: "top",
              onClose: () => {
                window.location.href = "/";
              },
            });
          },
          onError: (error) => {
            console.log("Error during the call:", error);
          },
        })
      );
    }
  }, [callInProgress, callSettings]);

  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.heading}>
        {paramSessionID ? "Join Conference Call" : "Create Conference Call"}
      </h2>
      <button
        className={styles.showInfoButton}
        onClick={() => setShowDetails(!showDetails)}
      >
        {showDetails ? "Hide Info" : "Show Info"}
      </button>
      {showDetails && (
        <>
          <p className={styles.conferenceID}>Conference ID: {sessionID}</p>
          {!paramSessionID && sessionID && (
            <p className={styles.shareLink}>
              Share this link to join: {window.location.origin}/ConferenceCall/
              {sessionID}
            </p>
          )}
        </>
      )}
      <div className={styles.container}>
        {renderCall()}
        <ChatSidebar renderChat={renderChat} />
      </div>
    </div>
  );
};

export default ConferenceCall;
