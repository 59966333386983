import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { Button, Modal } from "antd";
import styles from "./ProductConfirmation.module.css";
import tickPop from "../../../assests/tickPop.png";
import confettiIMG from "../../../assests/confettiIMG.png";
import FailedTick from "../../../assests/customDeleteMark.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { _DELETE, _GET, _POST } from "../../../common/commonFunc";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { deleteCartItem } from "../redux/actions/bookingActions";

interface RouteParams {
  order_id: string;
  charge_id: string;
}

function ProductConfirmation() {
  const { order_id, charge_id } = useParams<RouteParams>();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();

  const tap_id = queryParams.get("tap_id");
  const data: any = queryParams.get("data");

  // const [paymentData, setPaymentData] = useState<any>(null);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState<boolean>(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);

    const dispatch = useDispatch();

    const userdata: any = localStorage.getItem("userInfo");
    const parsedUserData = JSON.parse(userdata);
    const userId = parsedUserData?.id;

  const showSuccessModal = () => {
    setIsSuccessModalVisible(true);
  };


  const showFailureModal = () => {
    setIsFailureModalVisible(true);
  };

  const GetDataFromUrl = async () => {
    const currentPageUrl = window.location.href;

    const url = new URL(currentPageUrl);
    const params = new URLSearchParams(url.search);

    const orderIdParam = order_id;
    const chargeIdParam = params.get("tap_id");
    const apiUrl = `payment/product/getPaymentById?chargeId=${chargeIdParam}&order_id=${orderIdParam}`;
    const response = await _GET(apiUrl, true);
    console.log("response is", response);
    if(response.status && response.statusCode === 200){

    const statusCheck = _.get(response, "data.dataInserted", true);
    if (statusCheck) {
      setFailedMessage("Success");
      showSuccessModal();
    }

    try {
      const res = await _GET(`supplier/getOrderDetailsByOrderId/${orderIdParam}`, true, false);

      if (res.status === true) {
        const selectedProd = res.data;
       if (selectedProd.length > 0) {
        selectedProd.map((item : any) => {
          try {
            const url = `cart/removeItem/product/${userId}/${item?.product_id}-${item?.inventory_id}`;
            const response = _DELETE(url, true, false);
            dispatch(deleteCartItem(`${item?.product_id}-${item?.inventory_id}`));
        } catch (error) {
            console.error("Error in deleting items:", error);
        }
        })
       }
      } else {
        console.error("Error deleting cart items: ", res.message);
      }
    } catch (error) {
      console.error("Error fetching order details: ", error);
    }

  } else {

    setFailedMessage("Failed");
    showFailureModal();
    }}

  useEffect(() => {
    GetDataFromUrl();
  }, [order_id, charge_id, history, tap_id]);

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
    history.push("/store");
  };
  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
    // history.push("/productDetails");
    history.push("/order");
  };

  return (
    <div
      style={{
        padding: "24px",
        borderRadius: "1px",
        // backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // border: "1px solid red",
      }}
    >
      {failedMessage === "Success" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your order is placed!</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "Success" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Try Again!
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ProductConfirmation;
