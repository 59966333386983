import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CircleIcon from "@mui/icons-material/Circle";
import { _DELETENX, _PATCHNX, getUserId } from "../../commonFunc";
import { motion, AnimatePresence } from "framer-motion";

const NotificationsList = ({
  notifications,
  setNotifications,
  onToggleDoNotDisturb,
}: any) => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedNotification, setSelectedNotification] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [menuLoading, setMenuLoading] = useState(false);

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLButtonElement>,
    notification: any
  ) => {
    setMenuAnchor(event.currentTarget);
    setSelectedNotification(notification);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedNotification(null);
    setMenuLoading(false);
  };

  const handleAction = async (action: string) => {
    if (selectedNotification) {
      setMenuLoading(true);
      const notificationId = selectedNotification.id;

      if (action === "markAsViewed") {
        const endpoint = `notification/users/view/notify?notify_id=${notificationId}`;
        try {
          const response = await _PATCHNX(endpoint, true, true);
          if (response && response.status) {
            setNotifications((prev: any) =>
              prev.map((n: any) =>
                n.id === notificationId ? { ...n, is_viewed: true } : n
              )
            );
          }
        } catch (error) {
          console.error("Error marking notification as viewed:", error);
        }
      } else if (action === "delete") {
        const endpoint = `notification/remove/user/notify?notify_id=${notificationId}`;
        try {
          const response = await _DELETENX(endpoint, true);
          if (response && response.status) {
            setNotifications((prev: any) =>
              prev.filter((n: any) => n.id !== notificationId)
            );
          }
        } catch (error) {
          console.error("Error deleting notification:", error);
        }
      }
      handleMenuClose();
    }
  };

  const handleMarkAllAsRead = async () => {
    setLoading(true);
    const userID = getUserId();

    try {
      const endpoint = `notification/users/view/all/notify?user_id=${userID}`;
      const response = await _PATCHNX(endpoint, true, true);

      if (response && response.status) {
        const unreadNotifications = notifications.filter(
          (notification: any) => !notification.is_viewed
        );

        for (const notification of unreadNotifications) {
          setNotifications((prev: any) =>
            prev.map((n: any) =>
              n.id === notification.id ? { ...n, is_viewed: true } : n
            )
          );

          await new Promise((resolve) => setTimeout(resolve, 200));
        }
      }
    } catch (error) {
      console.error("Error marking all notifications as viewed:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const sortedNotifications = [...notifications].sort((a: any, b: any) => {
    if (a.is_viewed !== b.is_viewed) {
      return a.is_viewed ? 1 : -1;
    }
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <Box
      sx={{
        width: { xs: "90%", sm: 350 },
        backgroundColor: "#fff",
        borderRadius: 3,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        p: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h6" sx={{ color: "#31263e", fontWeight: "bold" }}>
          Notifications
        </Typography>
        <Box display="flex" alignItems="center">
          <Tooltip title="Mark All as Viewed">
            <IconButton
              onClick={handleMarkAllAsRead}
              disabled={loading || notifications.every((n: any) => n.is_viewed)}
              sx={{
                color:
                  loading || notifications.every((n: any) => n.is_viewed)
                    ? "#ccc !important"
                    : "#4e4dff",
                opacity:
                  loading || notifications.every((n: any) => n.is_viewed)
                    ? 0.5
                    : 1,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                <Typography variant="body2" sx={{ color: "#4e4dff" }}>
                  Mark All as Read
                </Typography>
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <List sx={{ maxHeight: 300, overflowY: "auto", p: 0, pr: 1 }}>
        <AnimatePresence>
          {sortedNotifications.length > 0 ? (
            sortedNotifications.map((notification: any) => (
              <motion.div
                key={notification.id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{
                  layout: { duration: 0.5, ease: "easeOut" },
                  opacity: { duration: 0.3 },
                }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    py: 1.5,
                    px: 2,
                    mb: 2,
                    borderRadius: 3,
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {!notification.is_viewed && (
                    <Tooltip title="New">
                      <CircleIcon
                        sx={{
                          fontSize: 10,
                          color: "#4e4dff",
                          mt: "2px",
                        }}
                      />
                    </Tooltip>
                  )}

                  <ListItemText
                    primary={notification.title}
                    secondary={formatDate(notification.created_at)}
                    primaryTypographyProps={{
                      variant: "body1",
                      color: "#31263e",
                      fontWeight: "medium",
                    }}
                    secondaryTypographyProps={{
                      variant: "body2",
                      color: "text.secondary",
                    }}
                  />

                  <IconButton
                    edge="end"
                    onClick={(event) => handleMenuOpen(event, notification)}
                    disabled={menuLoading}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    {menuLoading &&
                    selectedNotification?.id === notification.id ? (
                      <CircularProgress size={20} />
                    ) : (
                      <MoreVertIcon />
                    )}
                  </IconButton>
                </ListItem>
              </motion.div>
            ))
          ) : (
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mt: 2 }}
            >
              No new notifications
            </Typography>
          )}
        </AnimatePresence>
      </List>

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => {
          setMenuAnchor(null);
          setSelectedNotification(null);
        }}
      >
        {menuLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
            }}
          >
            <CircularProgress size={24} color="inherit" />
          </Box>
        ) : (
          [
            selectedNotification && !selectedNotification.is_viewed && (
              <MenuItem
                key="markAsViewed"
                onClick={() => handleAction("markAsViewed")}
              >
                Mark as Read
              </MenuItem>
            ),
            <MenuItem key="delete" onClick={() => handleAction("delete")}>
              Delete
            </MenuItem>,
          ]
        )}
      </Menu>
    </Box>
  );
};

export default NotificationsList;
