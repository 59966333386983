import react, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Table,
  Button,
  Input,
  Divider,
  Dropdown,
  // Modal,
  Row,
  Col,
  Card,
  Spin,
} from "antd";
import { Menu as AntMenu } from "antd";
import {
  EyeOutlined,
  CalendarOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  FilterOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import { _GET, _POST, getUserId } from "../../../common/commonFunc";
import CustomFreelancerTable from "../freelancerComponets/CustomFreelancerTable";
import DetailsPage from "../../ServiceProvioder/Order/detailPage";
import EventFreelancerTable from "../freelancerComponets/EventFreelancerTable";
import ServiceProviderEventDrawer from "../../ServiceProvioder/EventDetails/ServiceProviderEventDrawer";
import Styles from "./FreelancerOrderPage.module.css";
import {
  filterUpcomingServices,
  filterInProgressServices,
  filterCompletedServices,
  filterCancelledServices,
  filterAllServices,
} from "../Utils/filterUtils";
import {
  filterUpcomingevents,
  filterInProgressevents,
  filterCompletedevents,
  filterCancelledevents,
} from "../Utils/filterEventUtils";
import Papa from "papaparse";
import CSVIcon from "../../../assests/CSV.png";
import Modal from "@mui/material/Modal";

import {
  setCartEventItems,
  setCartItems,
} from "../../ServiceProvioder/redux/actions/bookingActions";
import { debounce, get } from "lodash";
import { Upcoming } from "@mui/icons-material";
import SearchIcon from "../../../assests/searchiconnew.svg";
import Filter from "../../../assests/customIcons/Filter";
import {
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { RootState } from "../../../redux/store";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import GenericPage from "../../SupplierScreens/Profile/GenericPage";
import Cardcontainer from "../../ServiceProvioder/Order/Cardcontainer";
import { useHistory } from "react-router";
import Eventcartcontainer from "../../ServiceProvioder/Order/EventCardContainer";
import "../../../pages/ServiceProvioder/Order/popupstyles.css";
import Cross from "../../../assests/customIcons/Cross";
// /Users/jiyauddinsaiyad/octopus_web_frontend/src/pages/Store/Order/popupstyles.css
const { TabPane } = Tabs;

interface Cartitem {
  itemType: string;
  itemId: string;
  type: string;
  quantity: number;
  service_category_main: string;
  service_category_sub: string;
}

const FreelancerOrderPage = () => {
  const userId = getUserId();
  const history = useHistory();
  const [serviceData, setServiceData] = useState<any>([]);
  const [upComingServices, setUpComingServices] = useState<any>([]);
  const [inProgressServices, setInProgressServices] = useState([]);
  const [completedServices, setCompletedServices] = useState([]);
  const [cancelledServices, setCancelledServices] = useState([]);
  const [quotesRecieved, setQuotesRecieved] = useState([]);
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [activeFilter, setActiveFilter] = useState("Services");
  const [data, setData] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEventDrawer, setOpenEventDrawer] = useState(false);

  // const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [eventData, setEventData] = useState([]);
  const [eventItems, setEventItems] = useState([]);
  const [upComingEvents, setUpComingEvents] = useState([]);
  const [inProgressEvents, setInProgressEvents] = useState([]);
  const [completedEvents, setCompletedEvents] = useState([]);
  const [cancelledEvents, setCancelledEvents] = useState([]);
  const [refundRequests, setRefundRequests] = useState([]);
  const [recievedQuotes, setRecievedQuotes] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const limit = 10;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [serviceDataSearch, setServiceDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const inputRef = useRef<any>(null);

  const showModal = () => {
    setIsModalVisible(true);
    inputRef.current?.focus();
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setServiceDataSearch([]);
    setSearchTerm("");
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      debouncedSearch(value);
    } else {
      setServiceDataSearch([]);
    }
  };

  const debouncedSearch = debounce(async (searchValue: string) => {
    setLoading(true);
    try {
      const payload = {
        searchTerm: searchValue,
        limit: 10,
        page: 1,
        userId: userId,
      };

      const res = await _POST("search/octopus/providers/orders", payload, true);
      if (res?.data?.results) {
        setServiceDataSearch(res.data.results);
        setIsSearched(true);
      }
    } catch (error) {
      console.error("Search API error", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  const dispatch = useDispatch();

  const updatedBookings = useSelector(
    (state: RootState) => state.booking.bookings
  );

  const cartItems: any = useSelector(
    (state: RootState) => state.cart.cartItems
  );

  const cartEventItems: any = useSelector(
    (state: RootState) => state.cart.eventCartItems
  );

  useEffect(() => {
    if (updatedBookings) {
      getServices();
    }
  }, [updatedBookings]);

  // const exportCSV = (data: any, filename: any) => {
  //   const csv = Papa.unparse(data);
  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.setAttribute("download", filename);
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const showDetails = async (bookingId: string, type: string) => {
    try {
      setLoading(true);
      const payload = {
        booking_type: type,
        booking_id: bookingId,
      };

      const res = await _POST(
        `userProfile/users/booking`,
        payload,
        true,
        false
      );
      console.log("➡️   ~ showDetails ~ res:", res);

      if (res.status && res.data) {
        setData(res.data[0]);
        if (type === "service_booking") {
          setOpenDrawer(true);
        } else {
          setOpenEventDrawer(true);
        }
        handleModalClose();
      } else {
        console.error({ message: "Failed to fetch booking details" });
      }
    } catch (error) {
      console.error({ message: "Error fetching booking details" });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setOpenDrawer(false);
  };

  const closeEventModal = () => {
    setOpenEventDrawer(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isModalVisible]);

  const flattenObject = (obj: any, parent: string = "", res: any = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const exportCSV = (data: any, filename: any) => {
    const flattenedData = data.map((item: any) => {
      if (typeof item === "object" && item !== null) {
        return flattenObject(item);
      }
      return item;
    });

    const csv = Papa.unparse(flattenedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleExport = (type: any, status: any) => {
    let filteredData = [];
    let filename = "";

    if (type === "Bookings") {
      switch (status) {
        case "All":
          filteredData = serviceData;
          filename = "All_Bookings.csv";
          break;
        case "Upcoming":
          filteredData = upComingServices;
          filename = "Upcoming_Bookings.csv";
          break;
        case "In Progress":
          filteredData = inProgressServices;
          filename = "InProgress_Bookings.csv";
          break;
        case "Completed":
          filteredData = completedServices;
          filename = "Completed_Bookings.csv";
          break;
        case "Cancelled":
          filteredData = cancelledServices;
          filename = "Cancelled_Bookings.csv";
          break;
        case "quotesRecieved":
          filteredData = cancelledServices;
          filename = "Quotes_Recieved.csv";
          break;
        // ... handle other statuses ...
      }
    } else if (type === "Events") {
      switch (status) {
        case "All":
          filteredData = eventData;
          filename = "All_Events.csv";
          break;
        case "Upcoming":
          filteredData = upComingEvents;
          filename = "Upcoming_Events.csv";
          break;
        case "In Progress":
          filteredData = inProgressEvents;
          filename = "InProgress_Events.csv";
          break;
        case "Completed":
          filteredData = completedEvents;
          filename = "Completed_Events.csv";
          break;
        case "Cancelled":
          filteredData = cancelledEvents;
          filename = "Cancelled_Events.csv";
          break;
        // ... handle other statuses ...
      }
    }

    exportCSV(filteredData, filename);
  };

  const exportMenu = (
    <AntMenu>
      <AntMenu.SubMenu title="Export Bookings">
        <AntMenu.Item onClick={() => handleExport("Bookings", "All")}>
          All Bookings
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Bookings", "Upcoming")}>
          Upcoming Bookings
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Bookings", "In Progress")}>
          In Progress Bookings
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Bookings", "Completed")}>
          Completed Bookings
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Bookings", "Cancelled")}>
          Cancelled Bookings
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Bookings", "Cancelled")}>
          Recieved Quotes
        </AntMenu.Item>
      </AntMenu.SubMenu>
      <AntMenu.SubMenu title="Export Events">
        <AntMenu.Item onClick={() => handleExport("Events", "All")}>
          All Events
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Events", "Upcoming")}>
          Upcoming Events
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Events", "In Progress")}>
          In Progress Events
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Events", "Completed")}>
          Completed Events
        </AntMenu.Item>
        <AntMenu.Item onClick={() => handleExport("Events", "Cancelled")}>
          Cancelled Events
        </AntMenu.Item>
      </AntMenu.SubMenu>
    </AntMenu>
  );

  const getServices = async () => {
    const res = await _GET(
      `userProfile/getFreelancerServiceBookings/${userId}`,
      true
    );
    setServiceData(res.data);
  };

  const getEvents = async () => {
    const res = await _GET(`userProfile/getFreelancerEventBookings`, true);
    setEventData(res.data);
  };

  useEffect(() => {
    getEvents();
  }, [userId]);

  useEffect(() => {
    getServices();
  }, [userId]);

  const mapServiceData = (data: any) => {
    if (data) {
      const upComing = data.filter(
        (service: any) => service.booking_status === "Upcoming"
      );
      const inProgress = data.filter(
        (service: any) => service.booking_status === "inProgress"
      );
      const completed = data.filter(
        (service: any) => service.booking_status === "Completed"
      );
      const cancelled = data.filter(
        (service: any) => service.booking_status === "Cancelled"
      );

      setUpComingServices(upComing);
      setInProgressServices(inProgress);
      setCompletedServices(completed);
      setCancelledServices(cancelled);
    }
  };

  const fetchQuotes = async () => {
    try {
      const res = await _GET(
        `services/quote/provider/request?freelancer_id=${userId}`,
        true
      );
      console.log("QUOTES DATA IS", res);
      if (res.status && res.data !== null && res.statusCode === 200) {
        setRecievedQuotes(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchQuotes();
  }, []);

  useEffect(() => {
    if (serviceData.length > 0) {
      mapServiceData(serviceData);
    }
  }, [serviceData]);

  const mapEventData = (data: any) => {
    if (data) {
      const upComing = data.filter(
        (event: any) => event.event_status === "Upcoming"
      );
      const inProgress = data.filter(
        (event: any) => event.event_status === "inProgress"
      );
      const completed = data.filter(
        (event: any) => event.event_status === "Completed"
      );
      const cancelled = data.filter(
        (event: any) => event.event_status === "Cancelled"
      );

      setUpComingEvents(upComing);
      setInProgressEvents(inProgress);
      setCompletedEvents(completed);
      setCancelledEvents(cancelled);
    }
  };

  useEffect(() => {
    if (eventData.length > 0) {
      mapEventData(eventData);
    }
  }, [eventData]);

  useEffect(() => {
    if (serviceData.length > 0) {
      filterRefundRequests(serviceData);
    }
  }, [serviceData]);

  const filterRefundRequests = (data: any) => {
    const refundReq = data.filter((item: any) => item.is_refund_requested);
    setRefundRequests(refundReq);
  };

  if (!serviceData) {
    return <div>Loading...</div>;
  }

  const headers = [
    { id: "id", label: "ID", width: "10%" },
    { id: "service_name", label: "SERVICE NAME", width: "15%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "15%" },
    { id: "service_location", label: "SERVICE LOCATION", width: "15%" },
    { id: "pay", label: "FEES", width: "10%" },
    { id: "status", label: "STATUS", width: "10%" },
    { id: "action", label: "ACTION", width: "25%" },
  ];

  const quoteHeaders = [
    { id: "id", label: "ID", width: "10%" },
    { id: "service_name", label: "SERVICE NAME", width: "15%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "15%" },
    { id: "service_location", label: "SERVICE LOCATION", width: "15%" },
    { id: "pay", label: "FEES", width: "10%" },
    { id: "status", label: "STATUS", width: "10%" },
    { id: "action", label: "ACTION", width: "25%" },
  ];

  const eventHeaders = [
    { id: "event_id", label: "ID", width: "10%" },
    { id: "event_name", label: "EVENT NAME", width: "10%" },
    { id: "booking_date_time", label: "BOOKING DATE", width: "15%" },
    { id: "paid", label: "PAID", width: "10%" },
    { id: "event_location", label: "EVENT LOCATION", width: "15%" },
    { id: "event", label: "EVENT", width: "10%" },
    { id: "booked_seats", label: "BOOKED/JOINED", width: "10%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];

  const refundheaders = [
    { id: "id", label: "transaction ID", width: "10%" },
    { id: "customer_name", label: "CUSTOMER", width: "15%" },
    { id: "service_name", label: "SERVICE NAME", width: "10%" },
    { id: "total_price", label: "TOTAL PRICE", width: "10%" },
    { id: "booked_date", label: "BOOKED DATE", width: "20%" },
    { id: "action", label: "ACTION", width: "25%" },
  ];

  const recievedQuoteHeaders = [
    { id: "req_id", label: "REQUEST ID", width: "10%" },
    { id: "service_name", label: "SERVICE NAME", width: "20%" },
    { id: "request_date", label: "REQUEST DATE", width: "15%" },
    { id: "req_quote_text", label: "QUOTE DESCRIPTION", width: "20%" },
    { id: "quoted_price", label: "QUOTED PRICE", width: "10%" },
    { id: "quoted_duration", label: "QUOTED DURATION", width: "10%" },
    { id: "quote_req_status", label: "STATUS", width: "10%" },
    { id: "quote_action", label: "ACTION", width: "8%" },
  ];

  const handleTabChange = (status: any) => {
    setActiveTab(status);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filteredUpcomingServices = filterUpcomingServices(
    upComingServices,
    searchTerm
  );

  const filteredInProgressServices = filterInProgressServices(
    inProgressServices,
    searchTerm
  );

  const filteredCompletedServices = filterCompletedServices(
    completedServices,
    searchTerm
  );

  const filteredCancelledServices = filterCancelledServices(
    cancelledServices,
    searchTerm
  );

  const filteredUpcomingEvents = filterUpcomingevents(
    upComingEvents,
    searchTerm
  );

  const filteredInProgressEvents = filterInProgressevents(
    inProgressEvents,
    searchTerm
  );

  const filteredCompletedEvents = filterCompletedevents(
    completedEvents,
    searchTerm
  );

  const filteredCancelledEvents = filterCancelledevents(
    cancelledEvents,
    searchTerm
  );

  const filteredAllServiceData = filterAllServices(serviceData, searchTerm);

  const formatDateTime = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.orderContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.serviceHeader}>
            <div className={Styles.actionsContainer}>
              <h1>Bookings</h1> |{" "}
              <Button
                className={`${Styles.filterButton} ${
                  activeFilter === "Services" && Styles.active
                }`}
                onClick={() => setActiveFilter("Services")}
              >
                Services
              </Button>
              <Button
                className={`${Styles.filterButton} ${
                  activeFilter === "Events" && Styles.active
                }`}
                onClick={() => setActiveFilter("Events")}
              >
                Events
              </Button>
            </div>
            <div className={Styles.actionsContainerNext}>
              <SearchOutlined
                className={Styles.searchIconMain}
                onClick={showModal}
                color="#cecbd4"
              />
              {/* <div className={Styles.searchDiv}>
                <img src={SearchIcon} alt="SearchIcon" />
                <Input
                  placeholder="Search by ID, Name, Email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className={Styles.searchInput}
                />

                <IconButton onClick={handleClick}>
                  <Filter />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    "& .MuiMenu-paper": {
                      borderRadius: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    },
                  }}
                >
                  <MenuItem onClick={() => handleTabChange("In Progress")}>
                    Active
                  </MenuItem>
                  <MenuItem onClick={() => handleTabChange("Upcoming")}>
                    Upcoming
                  </MenuItem>

                  <MenuItem onClick={() => handleTabChange("Completed")}>
                    Completed
                  </MenuItem>
                  <MenuItem onClick={() => handleTabChange("Cancelled")}>
                    Cancelled
                  </MenuItem>
                </Menu>
              </div> */}
              <Dropdown overlay={exportMenu}>
                <Button className={Styles.calendarButton}>
                  <img src={CSVIcon} alt="csv" /> Get CSV
                </Button>
              </Dropdown>

              <Button
                className={Styles.calendarButton}
                icon={<CalendarOutlined />}
                onClick={() =>
                  history.push({
                    pathname: `${APPLICATION_ROUTES.CALENDAR}`,
                  })
                }
              >
                Calendar
              </Button>
            </div>
          </div>

          <Modal
            open={isModalVisible}
            onClose={handleModalClose}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className={`${Styles.modalContent} ${Styles.topPosition}`}>
              <div className={Styles.searchDiv}>
                <img src={SearchIcon} alt="SearchIcon" />
                <Input
                  ref={inputRef}
                  placeholder="Search by ID, Name, Email..."
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  className={Styles.searchInput}
                />
                <IconButton onClick={handleModalClose}>
                  <Cross />
                </IconButton>
              </div>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleTabChange("In Progress")}>
                  Active
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Upcoming")}>
                  Upcoming
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Completed")}>
                  Completed
                </MenuItem>
                <MenuItem onClick={() => handleTabChange("Cancelled")}>
                  Cancelled
                </MenuItem>
              </Menu>

              {loading ? (
                <div className={Styles.loadingContainer}>
                  <Spin size="large" />
                </div>
              ) : serviceDataSearch.length > 0 ? (
                <div
                  className={`${Styles.resultsContainer} ${
                    serviceDataSearch.length <= 5 ? Styles.singleCard : ""
                  }`}
                >
                  <Row gutter={[16, 16]}>
                    {serviceDataSearch.map((order) => (
                      <Col xs={24} sm={12} md={8} lg={6} key={order.id}>
                        <Card
                          hoverable
                          cover={
                            <div className={Styles.cardImageWrapper}>
                              <img
                                alt="provider"
                                src={order.images[0]}
                                className={Styles.cardImage}
                              />
                              <div className={Styles.capsule}>
                                <span className={Styles.capsuleItem}>
                                  {order.status}
                                </span>
                                <span className={Styles.capsuleItem}>
                                  {order.booking_type === "service_booking"
                                    ? "Service"
                                    : "Event"}
                                </span>
                              </div>
                            </div>
                          }
                        >
                          <Card.Meta
                            title={order.name}
                            description={`Type: ${
                              order.booking_type === "service_booking"
                                ? "Service"
                                : "Event"
                            }`}
                          />
                          <p>Status: {order.status}</p>
                          <p>Start: {formatDateTime(order.start_time)}</p>
                          <p>End: {formatDateTime(order.end_time)}</p>
                          <p>
                            Total Amount: {order.total_amount}{" "}
                            {order.amount_paid_ccy}
                          </p>
                          <Button
                            onClick={() =>
                              showDetails(order.id, order.booking_type)
                            }
                            type="primary"
                          >
                            {order.booking_type === "service_booking"
                              ? "View Service Details"
                              : "View Event Details"}
                          </Button>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <div className={Styles.noResultsContainer}>
                  <SearchOutlined className={Styles.noResultsIcon} />
                  <p className={Styles.noResultsText}>No results found</p>
                </div>
              )}
            </div>
          </Modal>

          {openDrawer && (
            <DetailsPage
              visible={openDrawer}
              onCancel={closeModal}
              data={data}
            />
          )}

          {openEventDrawer && (
            <ServiceProviderEventDrawer
              visible={openEventDrawer}
              onClose={closeEventModal}
              data={data}
            />
          )}

          {activeFilter === "Services" ? (
            <>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="All" key="All" />
                <TabPane tab="Active" key="In Progress" />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Upcoming{" "}
                      <span className={Styles.countingSpan}>
                        {`${upComingServices.length}`}
                      </span>
                    </span>
                  }
                  key="Upcoming"
                />

                <TabPane tab="Completed" key="Completed" />
                <TabPane tab="Cancelled" key="Cancelled" />
                {/* Refund Requests */}
                <TabPane
                  tab={<span className={Styles.separator}>|</span>}
                  disabled
                  key="Separator"
                />
                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Recieved Quotes{" "}
                      <span className={Styles.countingSpan}>
                        {`${recievedQuotes.length}`}
                      </span>
                    </span>
                  }
                  key="recievedQuote"
                />
                {/* <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Refund Requests{" "}
                      <span className={Styles.countingSpan}>
                        {`${refundRequests.length}`}
                      </span>
                    </span>
                  }
                  key="Refund Requests"
                  disabled
                /> */}
              </Tabs>
            </>
          ) : (
            <>
              <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Active" key="In Progress" />

                <TabPane
                  tab={
                    <span className={Styles.countingSpanParent}>
                      Upcoming{" "}
                      <span className={Styles.countingSpan}>
                        {`${upComingEvents.length}`}
                      </span>
                    </span>
                  }
                  key="Upcoming"
                />
                <TabPane tab="Completed" key="Completed" />
                <TabPane tab="Cancelled" key="Cancelled" />
              </Tabs>
            </>
          )}

          {activeFilter === "Services" && (
            <>
              {activeTab === "Upcoming" && (
                <CustomFreelancerTable
                  headers={headers}
                  data={filteredUpcomingServices}
                  status="Upcoming"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "In Progress" && (
                <CustomFreelancerTable
                  headers={headers}
                  data={filteredInProgressServices}
                  status="inProgress"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Completed" && (
                <CustomFreelancerTable
                  headers={headers}
                  data={filteredCompletedServices}
                  status="Completed"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cancelled" && (
                <CustomFreelancerTable
                  headers={headers}
                  data={filteredCancelledServices}
                  status="Cancelled"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "All" && (
                <CustomFreelancerTable
                  headers={headers}
                  data={filteredAllServiceData}
                  status="All"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "recievedQuote" && (
                <CustomFreelancerTable
                  headers={recievedQuoteHeaders}
                  data={recievedQuotes}
                  status="recievedQuote"
                  fetchData={fetchQuotes}
                />
              )}
              {activeTab === "Refund Requests" && (
                <CustomFreelancerTable
                  headers={refundheaders}
                  data={refundRequests}
                  status="Refund Requests"
                  fetchData={getServices}
                  activeTab={setActiveTab}
                />
              )}
            </>
          )}
          {activeFilter === "Orders" && <div>No Data Available</div>}

          {activeFilter === "Events" && (
            <>
              {activeTab === "Upcoming" && (
                <EventFreelancerTable
                  headers={eventHeaders}
                  data={filteredUpcomingEvents}
                  status="Upcoming"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "In Progress" && (
                <EventFreelancerTable
                  headers={eventHeaders}
                  data={filteredInProgressEvents}
                  status="inProgress"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Completed" && (
                <EventFreelancerTable
                  headers={eventHeaders}
                  data={filteredCompletedEvents}
                  status="Completed"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cancelled" && (
                <EventFreelancerTable
                  headers={eventHeaders}
                  data={filteredCancelledEvents}
                  status="Cancelled"
                  fetchData={getEvents}
                  activeTab={setActiveTab}
                />
              )}
              {activeTab === "Cart" && (
                <div className={Styles.Cartitem}>
                  {cartEventItems.length > 0 ? (
                    cartEventItems.map((item: any, index: any) => (
                      <div style={{ display: "flex", marginLeft: "15px" }}>
                        <Eventcartcontainer key={index} item={item} />
                      </div>
                    ))
                  ) : (
                    <GenericPage
                      pageTitle="No Items Found"
                      pageDescription="Please Add Items to your cart."
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FreelancerOrderPage;
