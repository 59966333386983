import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Space,
  notification,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import countries from "../../SupplierRegister/Data/countries.json";
import Styles from "./BankInfoDrawer.module.css";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
// @ts-ignore
import * as iban from "iban";
import FileUpload from "../../../components/InputComponents/FileUpload";
import Cross from "../../../assests/customIcons/Cross";

const { Option } = Select;

export const BankInfoDrawer = ({
  onClose,
  open,
  bankInfoData,
  fetchData,
}: any) => {
  const [form] = Form.useForm();
  const [isInternational, setIsInternational] = useState<any>(false);
  const [countryDetails, setCountryDetails] = useState<any>({
    name: "",
    flag: "",
  });
  const [existingEventImages, setExistingEventImages] = useState<any>([]);
  const [isTaxRegistered, setIsTaxRegistered] = useState<any>(false);
  const [newEventImages, setNewEventImages] = useState<any>([]);

  useEffect(() => {
    if (bankInfoData && bankInfoData?.bankDetails) {
      const phoneNumber =
        bankInfoData?.bankDetails.beneficiaryPhoneNumber || "";
      const countryCode = phoneNumber.slice(0, 4);
      const number = phoneNumber.slice(4);

      form.setFieldsValue({
        beneficiaryType: bankInfoData?.bankDetails.beneficiaryType,
        beneficiaryFullName: bankInfoData?.bankDetails.beneficiaryFullName,
        beneficiaryIBAN: bankInfoData?.bankDetails.beneficiaryIBAN,
        beneficiaryPhoneNumber: { countryCode, number },
        beneficiaryEmail: bankInfoData?.bankDetails.beneficiaryEmail,
        tax_registration_number: bankInfoData?.tax_registration_number,
      });

      setIsInternational(
        bankInfoData?.bankDetails.beneficiaryType ===
          "International Beneficiary"
      );
      setIsTaxRegistered(bankInfoData?.is_tax_registered);
      setExistingEventImages(
        bankInfoData?.tax_document_link ? [bankInfoData?.tax_document_link] : []
      );
    }
  }, [bankInfoData, form]);

  const handleBeneficiaryTypeChange = (value: any) => {
    setIsInternational(value === "International Beneficiary");
    form.setFieldsValue({ beneficiaryType: value });
  };

  const getCountryDetailsFromIBAN = (ibanValue: any) => {
    const countryCode = ibanValue.slice(0, 2).toUpperCase();
    const country: any = countries.find(
      (c: any) => c.code.toUpperCase() === countryCode
    );
    return country
      ? { name: country.name, flag: country.flag }
      : { name: "", flag: "" };
  };

  const validateIBAN = (rule: any, value: any) => {
    if (value && !iban.isValid(value)) {
      setCountryDetails({ name: "", flag: "" });
      return Promise.reject(new Error("Invalid IBAN"));
    }
    if (value) {
      const details = getCountryDetailsFromIBAN(value);
      setCountryDetails(details);
    }
    return Promise.resolve();
  };

  const updateEventImages = (data: any) => {
    const value = Object.values(data)[0];
    if (value) {
      setNewEventImages([value]);
      setExistingEventImages([]);
    } else {
      setNewEventImages([]);
      setExistingEventImages([]);
    }
  };

  const getUpdatedImages = async () => {
    let allUpdatedImages: any = [...existingEventImages];
    if (newEventImages.length > 0) {
      const formData = new FormData();
      newEventImages.forEach((image: any) => formData.append("images", image));

      const response = await _POST(
        "uploadImagesToS3/supplier_documentRequest",
        formData,
        true
      );
      if (response && response.data?.length > 0) {
        allUpdatedImages = [...allUpdatedImages, ...response.data];
      } else {
        notification.open({
          type: "error",
          message: "Images Upload Failed",
          description: "Please try again to upload your images",
        });
      }
    }
    return allUpdatedImages;
  };

  const onFinish = async (values: any) => {
    const fullPhoneNumber = `${values.beneficiaryPhoneNumber.countryCode}${values.beneficiaryPhoneNumber.number}`;
    const uploadedImages: any = await getUpdatedImages();

    if (isTaxRegistered && (!uploadedImages || uploadedImages.length === 0)) {
      return notification.open({
        type: "info",
        message: "Please upload at least one image",
        description: "Upload your Tax Document",
      });
    }

    const combinedData: any = {
      bankInfo: {
        beneficiaryType: values.beneficiaryType,
        beneficiaryFullName: values.beneficiaryFullName,
        beneficiaryIBAN: values.beneficiaryIBAN,
        beneficiaryPhoneNumber: fullPhoneNumber,
        beneficiaryEmail: values.beneficiaryEmail,
      },
      tax_registered_number: values.tax_registration_number,
      tax_document_link: uploadedImages[0],
      is_tax_registered: isTaxRegistered,
    };

    if (!isTaxRegistered) {
      delete combinedData.tax_document_link;
      delete combinedData.tax_registered_number;
    }

    const response = await _POST(
      "userProfile/update/bankInformation",
      combinedData,
      true
    );
    if (response.status) {
      await showSuccessCustomModal({
        title: "Details Submitted Successfully",
        smallText: "Your Details have been submitted successfully.",
        buttonText: "Continue",
      });
      onClose();
      fetchData();
    }
  };

  const PhoneNumberInput = ({ value = {}, onChange }: any) => {
    const [number, setNumber] = useState<any>(value.number || "");
    const [countryCode, setCountryCode] = useState<any>(
      value.countryCode || "+973"
    );

    const triggerChange = (changedValue: any) => {
      onChange?.({ number, countryCode, ...value, ...changedValue });
    };

    const onNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
      triggerChange({ number: newNumber });
    };

    const onCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
      triggerChange({ countryCode: newCountryCode });
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={onCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
          >
            {countries.map((country: any) => (
              <Option key={country.code} value={country.dial_code}>
                <img
                  src={country.flag}
                  alt={country.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {country.dial_code}
              </Option>
            ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={onNumberChange}
            placeholder="Enter number"
            className={Styles.FormInputNum}
          />
        </Input.Group>
      </div>
    );
  };

  return (
    <Drawer
      title="Edit Bank Information"
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      width="550px"
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          beneficiaryType: "Local Beneficiary",
          beneficiaryPhoneNumber: { countryCode: "+973", number: "" },
        }}
      >
        <Form.Item
          name="beneficiaryType"
          label="Beneficiary Type"
          rules={[{ required: true }]}
        >
          <div className={Styles.selectInput}>
            <Select
              className={Styles.select}
              onChange={handleBeneficiaryTypeChange}
              defaultValue={"Local Beneficiary"}
            >
              <Option value="Local Beneficiary">Local Beneficiary</Option>
              <Option value="International Beneficiary">
                International Beneficiary
              </Option>
            </Select>
          </div>
        </Form.Item>

        <Form.Item
          name="beneficiaryFullName"
          label="Beneficiary Full Name"
          rules={[{ required: true }]}
        >
          <Input className={Styles.FormInput} placeholder="Enter Name" />
        </Form.Item>

        <Form.Item
          name="beneficiaryIBAN"
          label="Beneficiary IBAN"
          rules={[
            { required: true, message: "Please enter IBAN" },
            { validator: validateIBAN },
          ]}
        >
          <Input className={Styles.FormInput} placeholder="Enter IBAN" />
        </Form.Item>

        {countryDetails.name && (
          <div className={Styles.countryDetails}>
            IBAN country:{" "}
            <img
              src={countryDetails.flag}
              alt={countryDetails.name}
              style={{ width: "20px", marginRight: "10px", marginLeft: "5px" }}
            />
            {countryDetails.name}
          </div>
        )}

        {isInternational && (
          <>
            <Form.Item
              name="beneficiaryBankName"
              label="Beneficiary's Bank Name"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter Bank Name"
              />
            </Form.Item>
            <Form.Item
              name="beneficiaryCountry"
              label="Beneficiary Country"
              rules={[{ required: true }]}
            >
              <div className={Styles.selectInput}>
                <Select
                  placeholder="Select your business location"
                  className={Styles.select}
                  defaultValue={countries[0].name}
                  showSearch
                  optionFilterProp="value"
                  onChange={(value) =>
                    form.setFieldsValue({ beneficiaryCountry: value })
                  }
                >
                  {countries.map((country: any) => (
                    <Option key={country.code} value={country.name}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
            <Form.Item
              name="routingNumber"
              label="Routing Number"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter Routing Number"
              />
            </Form.Item>
            <Form.Item
              name="correspondentSwiftBicCode"
              label="SWIFT/BIC Code"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter SWIFT/BIC Code"
              />
            </Form.Item>
            <Form.Item
              name="beneficiaryAccountNumber"
              label="Account Number"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter Account Number"
              />
            </Form.Item>
            <Form.Item
              name="correspondentBank"
              label="Correspondent Bank"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter Correspondent Bank"
              />
            </Form.Item>
            <Form.Item
              name="correspondentSwiftBicCode"
              label="Correspondent Bank SWIFT/BIC Code"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter Correspondent Bank SWIFT/BIC Code"
              />
            </Form.Item>
            <Form.Item
              name="correspondentBankCountry"
              label="Correspondent Bank Country"
              rules={[{ required: true }]}
            >
              <div className={Styles.selectInput}>
                <Select
                  placeholder="Select country"
                  className={Styles.select}
                  defaultValue={countries[0].name}
                  showSearch
                  optionFilterProp="value"
                >
                  {countries.map((country: any) => (
                    <Option key={country.code} value={country.name}>
                      <img
                        src={country.flag}
                        alt={country.name}
                        style={{ width: "20px", marginRight: "10px" }}
                      />
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
            <Form.Item
              name="correspondentIFSC"
              label="IFSC (for INR payments)"
              rules={[{ required: true }]}
            >
              <Input className={Styles.FormInput} placeholder="Enter IFSC" />
            </Form.Item>
          </>
        )}

        <Form.Item
          name="beneficiaryPhoneNumber"
          label="Beneficiary Phone Number"
          rules={[{ required: true }]}
        >
          <PhoneNumberInput />
        </Form.Item>

        <Form.Item
          name="beneficiaryEmail"
          label="Beneficiary Email Address"
          rules={[{ required: true, type: "email" }]}
        >
          <Input className={Styles.FormInput} placeholder="Enter Email" />
        </Form.Item>

        <Radio.Group
          onChange={(e) => setIsTaxRegistered(e.target.value === "Yes")}
          value={isTaxRegistered ? "Yes" : "No"}
        >
          <Radio value="Yes">Tax Registered</Radio>
          <Radio value="No">Not Tax Registered</Radio>
        </Radio.Group>

        {isTaxRegistered && (
          <>
            <Divider />
            <Form.Item
              name="tax_registration_number"
              label="Tax Registration Number"
              rules={[{ required: true }]}
            >
              <Input
                className={Styles.FormInput}
                placeholder="Enter Tax Registration Number"
              />
            </Form.Item>
            <p>
              {" "}
              <span className="error">*</span> Tax Registration Certificate
            </p>
            <FileUpload
              title1="Upload Image"
              description="PDF, JPG, PNG file with max size of 10mb."
              setData={updateEventImages}
              existingPreviews={existingEventImages}
            />
          </>
        )}

        <Divider className={Styles.divider} />
        <Button
          type="primary"
          htmlType="submit"
          className={Styles.submitButton}
        >
          Submit
        </Button>
      </Form>
    </Drawer>
  );
};
