import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  styled,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { Button, Input, Modal, Popconfirm, message, notification } from "antd";
import { Visibility, Schedule, Cancel, MoneyOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ReScheduleDrawer from "../../ServiceProvioder/Order/ReScheduleService";
import CustomDeleteMark from "../../../assests/customDeleteMark.svg";
import chatOutlined from "../../../assests/chatOutlined.svg";
import CommentIcon from "../../../assests/Chat.svg";
import ServiceCard from "./ServiceCards";
import {
  showSuccessCustomModal,
  showConfirmCustomModal,
} from "../../../common/CustomModal/CustomModal";

import Styles from "./Table.module.css";
import { _PATCH, _POST } from "../../../common/commonFunc";
import DetailsPage from "../../ServiceProvioder/Order/detailPage";
import PopupForm from "./PopUpForm";
import UserCard from "../../ServiceProvioder/Order/MyCards/UserCard";
import { useDispatch } from "react-redux";
import {
  getFreelancerChatData,
  getFreelancerProfile,
} from "../../../redux/actions/FreelancerProfile";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useHistory } from "react-router";
import _ from "lodash";
// import QuoteChatModal from "./QuoteChatModal";
import EyeIcon from "../../../assests/customIcons/Eye";
import { EyeFilled } from "@ant-design/icons";
import QuoteViewDrawer from "./QuoteViewDrawer";
import ChatWindow from "./ChatWindow";

const CustomTable = ({ headers, data, status, fetchData, activeTab }: any) => {
  const [page, setPage] = useState(0);
  const [reason, setReason] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openRescheduleDrawer, setOpenRescheduleDrawer] = useState(false);
  const [selectedService, setSelectedService] = useState<any>([]);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [refundServiceModal, setRefundServiceModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [reasonError, setReasonError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chatModalVisible, setChatModlaVisible] = useState(false);
  const [recieverId, setRecieverId] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverPicture, setReceiverPicture] = useState("");
  const [openQuoteView, setOpenQuoteView] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [quoteStatus, setQuoteStatus] = useState("");
  const [openDrawer, setopenDrawer] = useState<any>(false);

  const dispatch = useDispatch<any>();
  const history = useHistory();

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#30263E",
      boxShadow: "0px 8px 24px 0px #422F9B1A",
      fontSize: 12,
      maxWidth: "271.44px",
    },
  }));

  useEffect(() => {
    fetchData();
  }, []);

  const handleSendMessage = async (row: any) => {
    await dispatch(getFreelancerChatData(row.freelancer_id, () => {}));
    const receiverID = _.get(row, "freelancer_id", "");
    history.push(`${APPLICATION_ROUTES.CHAT_PAGE}/${receiverID}`);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const confirmCancelService = (row: any, event: any) => {
    console.log("🚀 ~ confirmCancelService ~ row:", row.id);
    event.preventDefault();
    setSelectedService(row);
    setIsModalOpen(true);
  };

  const openModal = (item: any) => {
    setSelectedDrawerService(item);
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
  };

  const handleCancelService = async (serviceId: any, reason: any) => {
    const payload = {
      cancellation_reason: reason,
    };
    try {
      const cancelBookingResponse = await _POST(
        `services/cancelServiceBooking/${serviceId}`,
        payload,
        true
      );
      if (cancelBookingResponse.data === true) {
        showSuccessCustomModal({
          title: "Booking canceled successfully",
          smallText: "Your service has been cancelled successfully.",
          buttonText: "Continue",
        });
        fetchData();
        activeTab("Cancelled");
      } else {
        console.error(
          cancelBookingResponse.error
            ? cancelBookingResponse.error
            : "Booking cancellation failed"
        );
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  };

  const handdleRequestRefund = (row: any) => {
    setRefundServiceModal(true);
    setSelectedDrawerService(row);
  };

  const requestServiceRefund = async (
    reason: any,
    selectedDrawerService: any
  ) => {
    if (reason === "") {
      message.error("Please enter reason for refund");
      setReasonError(true);
      return;
    }
    setReasonError(false);

    const Payload = {
      bookingId: selectedDrawerService.id,
      reason_for_refund: reason,
    };

    try {
      const response = await _POST(
        `services/request/refund/service`,
        Payload,
        true
      );
      if (response.status === true) {
        message.success("Refund requested successfully");
        setRefundServiceModal(false);
        fetchData();
        setReason("");
      } else {
        message.error("Refund request failed");
      }
    } catch (error) {
      console.log(
        "➡️   ~ file: Order.tsx:180 ~ requestServiceRefund ~ error:",
        error
      );
    }
  };

  const toggleDrawer = (serviceData: any) => {
    setSelectedService(serviceData);
    setOpenRescheduleDrawer(!openRescheduleDrawer);
  };

  const handleRescheduleClick = (serviceData: any) => {
    toggleDrawer(serviceData);
  };

  const closeChatModal = () => {
    setChatModlaVisible(false);
    fetchData();
  };
  const openChatModal = async (row: any) => {
    setRecieverId(row.freelancer_id);
    const fullName = `${row.service_provider_first_nam} ${" "} ${
      row.service_provider_last_name
    }`;
    setReceiverName(fullName);
    setReceiverPicture(row.service_provider_profile_pic);
    if (row?.quote_req_status === "accepted") {
      try {
        const response = await _PATCH(
          `services/updateQuotedRequest/${row?.quote_request_id}`,
          {
            quote_req_status: "negotiating",
            freelancer_id: row?.freelancer_id,
          },
          true
        );
        if (response && response.statusCode === 200) {
          message.success("Quote in negotiation");
          closeChatModal();
          fetchData();
        }
      } catch (error) {
        console.error("Failed to send the quote in negotiation: ", error);
        // message.error("An error occurred while sending the quote  in negotiation");
      } finally {
        closeChatModal();
      }
    }
    setChatModlaVisible(true);
  };

  const handleEyeClick = (row: any, status: string) => {
    setSelectedQuote(row);
    setQuoteStatus(status);
    setOpenQuoteView(true);
  };

  const closeEyeClick = () => {
    setOpenQuoteView(false);
    setQuoteStatus("");
    setSelectedQuote({});
    setChatModlaVisible(false);
  };

  const formatDuration = (duration: any) => {
    const days = String(duration.days).padStart(2, "0");
    const hours = String(duration.hours).padStart(2, "0");
    const minutes = String(duration.minutes).padStart(2, "0");

    return `${days}d:${hours}h:${minutes}m`;
  };

  const convertSecondsToFormattedDuration = (quotedDuration: any) => {
    const days = Math.floor(quotedDuration / 86400);
    quotedDuration %= 86400;

    const hours = Math.floor(quotedDuration / 3600);
    quotedDuration %= 3600;

    const minutes = Math.floor(quotedDuration / 60);

    return formatDuration({ days, hours, minutes });
  };

  const acceptQuote = async (row: any) => {
    const payload = {
      quoted_price: row?.quoted_price,
      quoted_duration: row?.quoted_duration,
      quote_req_status: "approved",
      freelancer_id: row?.freelancer_id,
      is_user_approved: true,
      user_action_at: Math.floor(Date.now() / 1000),
    };
    console.log("payload is : ", payload);
    try {
      const response = await _PATCH(
        `services/updateQuotedRequest/${row?.quote_request_id}`,
        payload,
        true
      );
      if (response && response.statusCode === 200) {
        message.success("Quote accepted successfully!");
        bookQuote(row);
      }
    } catch (error) {
      console.error("Failed to accept the quote: ", error);
      message.error("An error occurred while accepting the quote");
    } finally {
      fetchData();
    }
  };

  const bookQuote = async (row: any) => {
    console.log("checking quote row", row)
    history.push(
      `${APPLICATION_ROUTES.SERVICE_QUOTE_BOOKING}/${row.primary_service_id}/${row?.quote_request_id}`,
      {}
    );
  };

  const onFreelancerProfileClickHandler = async (row: any) => {
    await dispatch(
      getFreelancerProfile(row.freelancer_id, (value: any) => {
        setopenDrawer(value);
      })
    );
  };

  const renderProfilePic = (img: string) => {
    const defaultImages = ["https://picsum.photos/200?random=1"];

    return (
      <>
        {!img ? (
          defaultImages.map((url, index) => (
            <img
              key={index}
              src={url}
              alt={`Static Profile ${index + 1}`}
              className={Styles.profilePictureCard}
            />
          ))
        ) : (
          <img
            src={img}
            alt={`Static Profile`}
            className={Styles.profilePictureCard}
          />
        )}
      </>
    );
  };

  const renderQuoteAction = (row: any) => {
    switch (row?.quote_req_status) {
      case "approved":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={Styles.reschedule}
              onClick={() => bookQuote(row)}
            >
              Book Now
            </Button>
          </div>
        );
      case "pending":
        return (
          <IconButton onClick={() => handleEyeClick(row, "pending")}>
            <EyeFilled className={Styles.eye1} />
          </IconButton>
        );
      case "accepted":
        return (
          <div className={Styles.actionButtons_quote}>
            {row?.quoted_duration && row?.quoted_price && (
              <Popconfirm
                title="Are you sure you want to accept this quote?"
                onConfirm={() => acceptQuote(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.reschedule}>Accept</Button>
              </Popconfirm>
            )}
            {/* <LightTooltip title="view freelancer info" placement="bottom">
              <Button
                className={Styles.chatIcon}
                onClick={() => onFreelancerProfileClickHandler(row)}
              >

                {renderProfilePic(row?.service_provider_profile_pic)}

              </Button>
            </LightTooltip> */}
            <IconButton onClick={() => handleEyeClick(row, "accepted")}>
              <EyeFilled className={Styles.eye1} />
            </IconButton>
          </div>
        );
      case "negotiating":
        return (
          <div className={Styles.actionButtons_quote}>
            {row?.quoted_duration && row?.quoted_price > 0 && (
              <Popconfirm
                title="Are you sure you want to accept this quote?"
                onConfirm={() => acceptQuote(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button className={Styles.reschedule}>Accept</Button>
              </Popconfirm>
            )}

            <IconButton onClick={() => handleEyeClick(row, "negotiating")}>
              <EyeFilled className={Styles.eye1} />
            </IconButton>
          </div>
        );

      default:
        return null;
    }
  };

  const renderQuoteStatus = (quoteStatus: any) => {
    switch (quoteStatus) {
      case "accepted":
        return (
          <div
            className={Styles.statusDiv}
            style={{ background: "#EFFAF3", color: "#3A9C5C" }}
          >
            Accepted
          </div>
        );
      case "approved":
        return (
          <div
            className={Styles.statusDiv}
            style={{ background: "#EFFAF3", color: "#3A9C5C" }}
          >
            Approved
          </div>
        );
      case "negotiating":
        return (
          <div
            className={Styles.statusDiv}
            style={{ background: "#FFF5EA", color: "#FD7E58" }}
          >
            Negotiating
          </div>
        );
      case "rejected":
        return (
          <div
            className={Styles.statusDiv}
            style={{ background: "#FADADD", color: "#B56576" }}
          >
            Rejected
          </div>
        );
      case "completed":
        return (
          <div
            className={Styles.statusDiv}
            style={{ background: "#DAD6F9", color: "#46474A" }}
          >
            Completed
          </div>
        );
      default:
        return (
          <div
            className={Styles.statusDiv}
            style={{ background: "#ECF5FF", color: "#4D9FFF" }}
          >
            Pending
          </div>
        );
    }
  };

  const renderActionButtons = (row: any) => {
    switch (status) {
      case "Upcoming":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={Styles.reschedule}
              onClick={() => handleRescheduleClick(row)}
            >
              Reschedule
            </Button>
            <Button
              className={Styles.cancel}
              onClick={(event) => confirmCancelService(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "inProgress":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={Styles.cancel}
              onClick={(event) => confirmCancelService(row, event)}
            >
              Cancel
            </Button>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Completed":
        return (
          <div className={Styles.actionButtons_quote}>
            <Button
              className={`${
                row.is_refund_requested ? Styles.rescheduled : Styles.reschedule
              }`}
              disabled={row.is_refund_requested ? true : false}
              onClick={() => handdleRequestRefund(row)}
            >
              {row.is_refund_requested
                ? "Refund Initiated"
                : "Request a Refund"}
            </Button>
            <Button className={Styles.eye} onClick={() => openModal(row)}>
              <Visibility />
            </Button>
          </div>
        );
      case "Cancelled":
        return (
          <Button className={Styles.eye} onClick={() => openModal(row)}>
            <Visibility />
          </Button>
        );
      default:
        return null;
    }
  };
  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatAddress = (location: any) => {
    let addressParts = [
      location?.address_name,
      location?.building_no,
      location?.landmark,
      location?.city,
      location?.state,
      location?.country,
      location?.pincode,
    ];

    let formattedAddress = addressParts
      .filter((part) => part)
      .slice(0, 2)
      .join(", ");

    formattedAddress =
      formattedAddress.length > 30
        ? formattedAddress.substring(0, 30) + "..."
        : formattedAddress;

    return formattedAddress;
  };

  const formatDate = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const date = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const time = `${startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })} - ${endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
    return (
      <div>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    );
  };

  const formatPrice = (currency: any, amount: any) => {
    return (
      <span>
        <span style={{ color: "#aaa" }}>{currency} </span>
        <span style={{ fontWeight: "bold" }}>{amount}</span>
      </span>
    );
  };

  const formatQuoteDate = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatId = (id: any) => {
    const prefix = id?.substring(0, 2);
    const start = id?.substring(8, 10);
    const end = id?.substring(id.length - 2);
    return `${prefix}${" : "}${start}..${end}`;
  };

  const formatDesc = (desc: any) => {
    const prefix = desc?.substring(0, 30);
    if (desc?.length > 30) {
      return `${prefix}${" ... "}`;
    }
    return `${prefix}`;
  };

  const copyToClipboard = async (text: any, event: any) => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(text);
      notification.success({ message: "Request ID copied to clipboard" });
    } catch (err) {
      notification.error({ message: "Failed to copy Request ID" });
    }
    event.stopPropagation();
  };

  const formatServiceBookingDateTime = (startTime: any, endTime: any) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);

    const startDateString = startDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    const endDateString = endDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    const startTimeString = startDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const endTimeString = endDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (startDateString === endDateString) {
      return [`${startDateString}, ${startTimeString} - ${endTimeString}`];
    } else {
      return [
        `${startDateString}, ${startTimeString}`,
        `${endDateString}, ${endTimeString}`,
      ];
    }
  };

  return (
    <>
      <PopupForm
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(reason) => handleCancelService(selectedService.id, reason)}
        id={selectedService.id}
        text="booking"
      />

      {isMobile ? (
        data.map((row: any) => (
          <ServiceCard
            key={row.id}
            service={row}
            handleReschedule={handleRescheduleClick}
            handleCancel={(event: any) => confirmCancelService(row, event)}
            handleView={openModal}
          />
        ))
      ) : (
        <TableContainer component={Paper} className={Styles.tableContainer}>
          <div className={Styles.customHeader}>
            <Table className={Styles.table}>
              <TableHead className={Styles.tableHeader}>
                <TableRow>
                  {headers.map((header: any) => (
                    <TableCell
                      key={header.id}
                      style={{
                        width: header.width,
                        textAlign: "center",
                      }}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            </Table>
          </div>

          <div className={Styles.customHeader}>
            <Table className={Styles.table}>
              <TableBody className={Styles.tableBody}>
                {data.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={headers.length}
                      style={{ textAlign: "center" }}
                    >
                      <h1> No data available</h1>
                    </TableCell>
                  </TableRow>
                ) : (
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: any) => (
                      <TableRow key={index}>
                        {headers.map((header: any) => (
                          <TableCell
                            key={`${header.id}-${index}`}
                            style={{
                              width: header.width,
                              textAlign: "center",
                            }}
                          >
                            {header.id === "booking_date_time" ? (
                              formatServiceBookingDateTime(
                                row.service_start_time,
                                row.service_end_time
                              ).map((line, lineIndex) => (
                                <div key={lineIndex}>{line}</div>
                              ))
                            ) : header.id === "pay" ? (
                              formatPrice(
                                row.service_tot_amount_ccy,
                                row.service_tot_amount
                              )
                            ) : header.id === "service_location" ? (
                              formatAddress(row.service_location)
                            ) : header.id === "req_id" ? (
                              <LightTooltip
                                title={
                                  <>
                                    <div style={{ fontWeight: "bold" }}>
                                      Click to Copy :
                                    </div>
                                    <br />
                                    <div>{row.quote_request_id}</div>
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <span
                                  onClick={(event) =>
                                    copyToClipboard(row.quote_request_id, event)
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  {formatId(row.quote_request_id)}
                                </span>
                              </LightTooltip>
                            ) : header.id === "service_name" ? (
                              row.service_name ? (
                                row.service_name
                              ) : (
                                "-"
                              )
                            ) : header.id === "request_date" ? (
                              formatQuoteDate(row.created_at)
                            ) : header.id === "req_quote_text" ? (
                              <LightTooltip
                                title={
                                  <>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: row.req_quote_text,
                                      }}
                                    />
                                  </>
                                }
                                placement="bottom"
                                arrow
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: row.req_quote_text
                                      ? formatDesc(row.req_quote_text)
                                      : "-",
                                  }}
                                />
                              </LightTooltip>
                            ) : header.id === "quoted_price" ? (
                              row.quoted_price ? (
                                row.quoted_price
                              ) : (
                                "-"
                              )
                            ) : header.id === "quote_req_status" ? (
                              renderQuoteStatus(row.quote_req_status)
                            ) : header.id === "service_provider" ? (
                              <LightTooltip
                                title="View freelancer info"
                                placement="bottom"
                              >
                                <Button
                                  className={Styles.chatIcon}
                                  onClick={() =>
                                    onFreelancerProfileClickHandler(row)
                                  }
                                >
                                  {renderProfilePic(
                                    row?.service_provider_profile_pic
                                  )}
                                </Button>
                              </LightTooltip>
                            ) : header.id === "quote_action" ? (
                              renderQuoteAction(row)
                            ) : header.id === "action" ? (
                              renderActionButtons(row)
                            ) : (
                              row[header.id]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
            <div className={Styles.tableFooter}>
              <div className={Styles.viewControls}>
                {data.length > 10 && rowsPerPage > 10 && (
                  <button onClick={handleShowLess} className={Styles.showLess}>
                    Show Less
                  </button>
                )}
                {data.length > page * rowsPerPage + rowsPerPage && (
                  <button onClick={handleViewMore} className={Styles.viewMore}>
                    View More
                  </button>
                )}
              </div>
              <TablePagination
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
                className={Styles.pagination}
              />
            </div>
          </div>
        </TableContainer>
      )}

      <ReScheduleDrawer
        open={openRescheduleDrawer}
        handleOpen={toggleDrawer}
        data={selectedService}
      />
      {visibleModal && (
        <DetailsPage
          visible={visibleModal}
          onCancel={closeModal}
          data={selectedDrawerService}
        />
      )}
      <Dialog
        onClose={() => setRefundServiceModal(false)}
        aria-labelledby="customized-dialog-title"
        open={refundServiceModal}
        fullWidth
        maxWidth="sm"
        className="refundModal"
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setRefundServiceModal(false)}
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              backgroundColor: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {/* "reason_for_refund": "reason_for_refund" */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              flexDirection: "column",
              height: "400px",
              alignItems: "center",
            }}
          >
            <h1>
              Are you sure you want to request a refund on this service booking?
            </h1>
            <p style={{ marginBottom: "0" }}>
              ID :{" "}
              <span style={{ fontWeight: "bolder" }}>
                {selectedDrawerService.id}
              </span>
            </p>
            <p style={{ marginTop: "1" }}>
              Service Name :{" "}
              <span style={{ fontWeight: "bolder" }}>
                {" "}
                {selectedDrawerService.service_name}
              </span>
            </p>
            <input
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "35px",
                padding: "0 10px",
              }}
              className={Styles.refundInput}
              placeholder="Reason for refund"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            ></input>
            {reasonError && (
              <p style={{ color: "red", marginBottom: "0" }}>
                Please enter reason for refund
              </p>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
                margin: "auto",
                marginTop: "50px",
              }}
            >
              <Button
                style={{
                  color: "white",
                  width: "40%",
                }}
                className={Styles.refundButton}
                onClick={() =>
                  requestServiceRefund(reason, selectedDrawerService)
                }
              >
                Yes
              </Button>

              <Button
                style={{
                  color: "white",
                  width: "40%",
                }}
                className={Styles.refundButtonNo}
                onClick={() => setRefundServiceModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <ChatWindow
        isVisible={chatModalVisible}
        onClose={closeChatModal}
        receiverID={recieverId}
      />
      <QuoteViewDrawer
        isVisible={openQuoteView}
        onClose={closeEyeClick}
        status={quoteStatus}
        data={selectedQuote}
        fetchData={fetchData}
        bookQuote={bookQuote}
      />
    </>
  );
};

export default CustomTable;
