import React, { useEffect, useState } from "react";
import { Button, Divider, Input, Breadcrumb } from "antd";
import "./AdvancePay.css";
import tickPop from "../../../assests/tickPop.png";
import confettiIMG from "../../../assests/confettiIMG.png";
import FailedTick from "../../../assests/customDeleteMark.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useHistory, useParams } from "react-router";
import { _GET, _MGET, _POST } from "../../../common/commonFunc";
import { useLocation } from "react-router";
import Styles from "./AdvancePayment.module.css";
import _ from "lodash";
import { API_BASE_URL, BASE_URL } from "../../../constant/ApplicationRoutes";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import grdCirculeIcon from "../../../assests/circulegrd.svg";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import { Box, CardContent, Typography } from "@mui/material";
import axios from "axios";
import PrivacyPolicy from "../../TermsAndConditions/PrivacypolicyModal";

const { TextArea } = Input;

const AdvancePaymentSupplier = (ids: any) => {
  const [cards, setCards] = useState<any>([]);
  const [isTCChecked, setisTCChecked] = useState(false);
  const [isPPChecked, setisPPChecked] = useState(false);
  const [selectedPayType, setSelectedPayType] = useState("Card");
  const [selectedCard, setSelectedCard] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");
  const [isPaidSucess, setIsPaidSucess] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] =
    useState<boolean>(false);
  const location = useLocation();
  const [ppModalVisible, ppSetModalVisible] = useState(false);
  const [visibleCardsCount, setVisibleCardsCount] = useState(4);
  const [collabDetails, setCollabDetails] = useState<any>();
  const [contractDetails, setContractDetails] = useState<any>();

  const { id } = useParams<{ id: string }>();

  const queryParams = new URLSearchParams(location.search);
  // console.log("query params : ", queryParams)
  const contract_id = queryParams.get("contract_id");
  const contract_mode = queryParams.get("contract_mode");
  // console.log("query params : ", queryParams, contract_id, contract_mode, location.search, location)
  // console.log("selectedAddressValue", selectedAddressValue);

  const ppShowModal = () => {
    ppSetModalVisible(true);
  };

  const ppCloseModal = () => {
    ppSetModalVisible(false);
  };

  const handleSuccessModalClose = () => {
    setIsSuccessModalVisible(false);
  };
  const handleFailureModalClose = () => {
    setIsFailureModalVisible(false);
  };

  function getPlainText(text: any) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    return doc.body.textContent || "";
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const payTypes = ["Card", "Benefit Pay"];

  const myCards = async () => {
    try {
      const res = await _GET(`payment/getUserAllCard`, true);

      if (res.status === true) {
        const uniqueCards = _.uniqBy(res.data, "id");
        setCards(uniqueCards);
      } else {
        console.error("Error fetching cards: ", res.message);
      }
    } catch (error) {
      console.error("Error fetching cards: ", error);
    }
  };

  const formatCollabDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const getContractDetails = () => {
    const contract = collabDetails?.contracts?.filter(
      (c: any) => c.contract_id === contract_id
    );
    // console.log("contract : ", contract)
    setContractDetails(contract);
  };

  const getCollabdetails = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}supplier/getContractsDetails?request_id=${id}&contract_id=${contract_id}&contract_mode=${contract_mode}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );

      if (res.data && res.data.data) {
        // console.log("Response from collabDetails :", res.data.data)
        setCollabDetails(res.data.data);
      } else {
        console.error("Error fetching Collaboration Details");
      }
    } catch (error) {
      console.error("Error fetching Collaboration Details: ", error);
    }
  };

  const handleAdvancePay = async () => {
    try {
      const paymentPayload = {
        collaboration_id: id,
        contract_id: contract_id,
        contract_mode: contract_mode,
      };
      const data = await _POST(
        `payment/createAdvanceCharge`,
        paymentPayload,
        true
      );

      window.location.href = data.data.redirect_url;
    } catch (e) {
      console.error("Error in payment", e);
    }
  };

  useEffect(() => {
    myCards();
    getCollabdetails();
  }, []);

  useEffect(() => {
    getContractDetails();
  }, [collabDetails]);

  const CardComponent = ({ card, onSelect, isSelected }: any) => {
    if (!card) {
      return null;
    }

    const formattedCardNumber = `${card?.first_eight.substring(
      0,
      8
    )} **** ** ${card?.last_four.substring(2)}`;

    return (
      <div
        className={Styles.cardContainer}
        onClick={() => onSelect(isSelected ? null : card?.id)}
      >
        <div
          className={`${Styles.radioCircle} ${
            isSelected ? Styles.selectedCard : ""
          }`}
          dangerouslySetInnerHTML={{
            __html: isSelected
              ? `<img src="${grdCirculeIcon}" alt="Circle Icon" />`
              : "",
          }}
        />
        <div className={Styles.cardDetails}>
          <div className={Styles.cardName}>{card?.name}</div>
          <div className={Styles.cardInfo}>
            <Typography variant="caption" mr={1}>
              {card?.brand}{" "}
            </Typography>
            |{" "}
            <Typography variant="caption" ml={1}>
              {formattedCardNumber}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  const history = useHistory();

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  return (
    <>
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <div style={{ width: "95vw" }}>
          <Breadcrumb
            // style={{ marginLeft: "10px" }}
            separator={<img src={breadcrumbIcon} alt=">" />}
          >
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/supplier/home")}
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/supplier/collabration")}
            >
              Collaborations
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="breadcrumbItem"
              onClick={() => handleBreadcrumbClick("/supplier/advance-payment")}
            >
              Advance Payment
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className={`${Styles.serviceMainContainer} bookingPageContainer`}
            style={{ marginTop: "0 !important" }}
          >
            <div className="infoContainer">
              <img
                src={collabDetails?.freelancer_profile_pic}
                className="smallimg"
                alt=""
              />

              <div className="serviceBookingInfo">
                <h3 style={{ margin: "0px" }}>
                  {" "}
                  {collabDetails?.freelancer_first_name}{" "}
                  {collabDetails?.freelancer_last_name}
                </h3>
                <p className="largeSize">
                  <span className="currency">BHD</span>{" "}
                  <span className="titlePrice">
                    {" "}
                    {contract_mode === "non_product_based" &&
                      collabDetails?.non_product_fixed_amount}
                    {contract_mode === "product_based" &&
                      contractDetails &&
                      contractDetails[0]?.fixed_amount}
                  </span>{" "}
                </p>
              </div>
            </div>

            <div className="bookingMainContainer">
              <div className="date-picker-containerPar">
                <div className="date-picker-container">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography
                        component="div"
                        variant="h5"
                        fontWeight={700}
                        className={Styles.details}
                        style={{ marginBottom: "30px" }}
                      >
                        Collaboration Details
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ display: "flex", flexDirection: "row" }}
                        className={Styles.details}
                      >
                        Request ID:{" "}
                        <div style={{ color: "grey", marginLeft: "3px" }}>
                          {collabDetails?.request_id}
                        </div>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        sx={{ display: "flex", flexDirection: "row" }}
                        className={Styles.details}
                      >
                        Collaboration Duration:{" "}
                        <div style={{ color: "grey", marginLeft: "3px" }}>
                          {formatCollabDate(
                            collabDetails?.collaboration_start_date
                          )}
                          {" to"}{" "}
                          {formatCollabDate(
                            collabDetails?.collaboration_end_date
                          )}
                        </div>
                      </Typography>

                      <Box
                        component="div"
                        sx={{ display: "flex", flexDirection: "column" }}
                        marginTop={3}
                      >
                        <Typography
                          variant="subtitle1"
                          fontWeight={500}
                          className={Styles.details}
                        >
                          Detailed Information on Promotional Products
                        </Typography>
                        <Typography
                          variant="caption"
                          color={"text.secondary"}
                          className={Styles.details}
                        >
                          <p>
                            {getPlainText(
                              collabDetails?.collaboration_detailed_info ?? " "
                            )}
                          </p>
                        </Typography>
                      </Box>
                      <Box
                        component="div"
                        sx={{ display: "flex", flexDirection: "column" }}
                        marginTop={3}
                      >
                        <Typography
                          variant="subtitle1"
                          fontWeight={500}
                          className={Styles.details}
                        >
                          Collaboration Interest & Expectations
                        </Typography>
                        <Typography
                          variant="caption"
                          color={"text.secondary"}
                          className={Styles.details}
                        >
                          <p>
                            {getPlainText(
                              collabDetails?.collaboration_interest_expectation ??
                                " "
                            )}
                          </p>
                        </Typography>
                      </Box>
                    </CardContent>
                  </Box>
                  <div></div>
                </div>
              </div>

              {contractDetails && (
                <div className={Styles.paymentContainer}>
                  <div className={Styles.paymentTop}>
                    <section className="paySelection">
                      <h3 className="bold xlSize">Payment method</h3>
                      <div className="payTypeSelection">
                        {payTypes.map((type) => (
                          <button
                            key={type}
                            className={`payTypeBtn ${
                              type === selectedPayType ? "payActiveBtn" : ""
                            }`}
                            onClick={() => setSelectedPayType(type)}
                            style={{ marginBottom: "1rem" }}
                          >
                            {type}
                          </button>
                        ))}
                        {selectedPayType === "Card" &&
                          cards.slice(0, visibleCardsCount).map((card: any) => {
                            return (
                              <CardComponent
                                key={card?.id}
                                card={card}
                                onSelect={setSelectedCard}
                                isSelected={selectedCard === card?.id}
                              />
                            );
                          })}
                        {cards.length === 0 ? (
                          <p>Please Proceed to pay to add a new card</p>
                        ) : (
                          ""
                        )}
                        {selectedPayType === "Card" &&
                          cards.length > visibleCardsCount && (
                            <button
                              onClick={() => setVisibleCardsCount(cards.length)}
                              className={Styles.cardLoadMore}
                            >
                              Load All
                            </button>
                          )}
                        {selectedPayType === "Card" &&
                          cards.length === visibleCardsCount && (
                            <button
                              onClick={() => setVisibleCardsCount(3)}
                              className={Styles.cardLoadMore}
                            >
                              Show Less
                            </button>
                          )}
                      </div>
                      <Button
                        onClick={() => {}}
                        className="blackOutline addCardBtn"
                        style={{ marginTop: "20px" }}
                        disabled={true}
                      >
                        <p className="bold m-0">{`+ Add ${selectedPayType}`}</p>
                      </Button>
                    </section>
                  </div>

                  <div className="paymentBottom">
                    <section>
                      <div className="costHeader">
                        <h3 className="bold xlSize">Collaboration Fee</h3>
                        <h3 className="bold xlSize">
                          <span className="grayText">BHD</span>{" "}
                          <span>{contractDetails[0]?.fixed_amount}</span>
                        </h3>
                      </div>

                      <div className="costHeader">
                        <p>VAT %</p>
                        <p className="semibold">
                          <span className="grayText">BHD</span>{" "}
                          <span>{contractDetails[0]?.vat_amount_to_pay}</span>
                        </p>
                      </div>
                    </section>
                    <Divider />
                    <section>
                      <div className="costHeader">
                        <h3 className="bold xlSize">Total Amount</h3>
                        <h3 className="bold xlSize">
                          <span className="grayText">BHD</span>{" "}
                          <span>
                            {contractDetails[0]?.total_amount_including_vat}
                          </span>
                        </h3>
                      </div>

                      <>
                        <div className="costHeader">
                          <p className="grayText">
                            Advance pay{" "}
                            {contractDetails[0]?.advance_amount_percentage}
                            {"%"}
                          </p>
                          <p>
                            <span className="grayText">BHD</span>
                            {contractDetails[0]?.advance_amount_to_pay}
                          </p>
                        </div>
                        <Divider />
                        <div className="costHeader">
                          <h3>Total Remaining Payment</h3>
                          <h3>
                            <span className="grayText">BHD</span>{" "}
                            <span>
                              {Number(contractDetails[0]?.remaining_amount)}
                            </span>
                          </h3>
                        </div>
                        <div className="costHeader">
                          <p className="grayText">
                            {" "}
                            Continue to accept our{" "}
                            <span
                              className="readMore"
                              onClick={() => ppSetModalVisible(true)}
                            >
                              {" "}
                              privacy policy
                            </span>{" "}
                            and{" "}
                            <span
                              className="readMore"
                              onClick={() => setModalVisible(true)}
                            >
                              terms & conditions.{" "}
                            </span>
                          </p>
                          <Button
                            shape="round"
                            className="buttonBG paySubmitBtn"
                            type="primary"
                            htmlType="button"
                            onClick={handleAdvancePay}
                          >
                            Pay BHD {contractDetails[0]?.advance_amount_to_pay}
                          </Button>
                        </div>
                      </>
                    </section>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <PrivacyPolicy
        open={ppModalVisible}
        setOpen={ppSetModalVisible}
        setisTCChecked={setisPPChecked}
      />
      <TermsAndConditions
        open={modalVisible}
        setOpen={setModalVisible}
        documentUrl="https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Supplier).pdf"
        setisTCChecked={setisTCChecked}
      />
      {failedMessage == "" && (
        <Dialog
          open={isSuccessModalVisible}
          onClose={handleSuccessModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={Styles.successModalContent}
            style={{
              background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={tickPop} alt="" />
            <h2>Thank You!</h2>
            <p>Your booking & order is sent to service provider</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                onClick={handleSuccessModalClose}
                style={{ marginTop: "24px" }}
              >
                Great!
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      {failedMessage !== "" && (
        <Dialog
          open={isFailureModalVisible}
          onClose={handleFailureModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent
            className={Styles.failedModalContent}
            style={{
              // background: `url(${confettiIMG})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backdropFilter: "blur(2px)",
            }}
          >
            <img src={FailedTick} alt="" />
            <h2>Payment Failed</h2>
            <p>{failedMessage}</p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <Button
                type="primary"
                onClick={handleFailureModalClose}
                style={{ marginTop: "24px" }}
              >
                Go to Home
              </Button> */}
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AdvancePaymentSupplier;
