import React, { useEffect, useState } from "react";
import { Drawer, Button, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import OrderCart from "../../../pages/ServiceProvioder/Order/OrderCart";
import Eventcartcontainer from "../../../pages/ServiceProvioder/Order/EventCardContainer";
import Cardcontainer from "../../../pages/ServiceProvioder/Order/Cardcontainer";
import { RootState } from "../../../redux/store";
import Styles from "./CartDrawer.module.css";
import { _GET, getUserId } from "../../commonFunc";
import {
  setCartEventItems,
  setCartItems,
} from "../../../pages/ServiceProvioder/redux/actions/bookingActions";
import Cross from "../../../assests/customIcons/Cross";
import OrderCartDrawer from "../../../pages/ServiceProvioder/Order/OrderCarddrawer";

const CartDrawer = ({ visible, onClose }: any) => {
  const [productCart, setProductCart] = useState<any>([]);
  const [activeTab, setActiveTab] = useState("services");
  const dispatch = useDispatch();
  const userId = getUserId();

  const cartItems: any = useSelector(
    (state: RootState) => state.cart.cartItems
  );
  const cartEventItems: any = useSelector(
    (state: RootState) => state.cart.eventCartItems
  );

  const fetchCartItems = async () => {
    try {
      const res = await _GET(`cart/getItem/${userId}`, true, false);
      if (res?.status) {
        const itemsArray: any[] = Object.values(res.data)
          .map((item: any) => JSON.parse(item))
          .flat();

        const serviceItems = itemsArray.filter(
          (item) => item.itemType === "service"
        );
        const eventItems = itemsArray.filter(
          (item) => item.itemType === "event"
        );
        const productItems = itemsArray.filter(
          (item) => item.itemType === "product"
        );

        setProductCart(productItems);
        dispatch(setCartItems(serviceItems));
        dispatch(setCartEventItems(eventItems));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchCartItems();
    }
  }, [visible]);

  return (
    <Drawer
      title={
        <>
          <div className={Styles.tabButtons}>
            <button
              onClick={() => setActiveTab("orders")}
              className={`${Styles.tabButton} ${
                activeTab === "orders" ? Styles.activeTab : ""
              }`}
            >
              Orders{" "}
              <div className={Styles.countOnIcon}>{productCart.length}</div>
            </button>
            <button
              onClick={() => setActiveTab("services")}
              className={`${Styles.tabButton} ${
                activeTab === "services" ? Styles.activeTab : ""
              }`}
            >
              Services{" "}
              <div className={Styles.countOnIcon}>{cartItems.length}</div>
            </button>
            <button
              onClick={() => setActiveTab("events")}
              className={`${Styles.tabButton} ${
                activeTab === "events" ? Styles.activeTab : ""
              }`}
            >
              Events{" "}
              <div className={Styles.countOnIcon}>{cartEventItems.length}</div>
            </button>
          </div>
        </>
      }
      placement="right"
      onClose={onClose}
      open={visible}
      closable={false}
      width={500}
      style={{ marginTop: "60px !important" }}
      className={Styles.cartDrawer}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      {/* <div className={Styles.tabButtons}>
        <button
          onClick={() => setActiveTab("orders")}
          className={`${Styles.tabButton} ${
            activeTab === "orders" ? Styles.activeTab : ""
          }`}
        >
          Orders <div className={Styles.countOnIcon}>{productCart.length}</div>
        </button>
        <button
          onClick={() => setActiveTab("services")}
          className={`${Styles.tabButton} ${
            activeTab === "services" ? Styles.activeTab : ""
          }`}
        >
          Services <div className={Styles.countOnIcon}>{cartItems.length}</div>
        </button>
        <button
          onClick={() => setActiveTab("events")}
          className={`${Styles.tabButton} ${
            activeTab === "events" ? Styles.activeTab : ""
          }`}
        >
          Events{" "}
          <div className={Styles.countOnIcon}>{cartEventItems.length}</div>
        </button>
      </div> */}

      {activeTab === "orders" && (
        // <OrderCart data={productCart} fetchCartItem={fetchCartItems} />
        <OrderCartDrawer data={productCart} fetchCartItem={fetchCartItems} />
      )}
      {activeTab === "services" && (
        <div className={Styles.Cartitem}>
          {cartItems.length > 0 ? (
            cartItems.map((item: any, index: any) => (
              <div style={{ display: "flex", marginLeft: "15px" }} key={index}>
                <Cardcontainer item={item} />
              </div>
            ))
          ) : (
            <p>No services in the cart</p>
          )}
        </div>
      )}
      {activeTab === "events" && (
        <div className={Styles.Cartitem}>
          {cartEventItems.length > 0 ? (
            cartEventItems.map((item: any, index: any) => (
              <div style={{ display: "flex", marginLeft: "15px" }} key={index}>
                <Eventcartcontainer item={item} />
              </div>
            ))
          ) : (
            <p>No events in the cart</p>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default CartDrawer;
