import React, { createContext, useContext, useState, useEffect } from "react";
import { onMessageListener } from "../firebase";

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    let isMounted = true;
    const setupForegroundNotificationListener = () => {
      onMessageListener()
        .then((payload) => {
          const { notification } = payload;

          if (notification) {
            const newNotification = {
              id: new Date().toISOString(),
              title: notification.title,
              body: notification.body,
              is_viewed: false,
              created_at: new Date().toISOString(),
            };

            setNotifications((prev) => [newNotification, ...prev]);
          }
        })
        .catch((error) => {
          console.error("Error in notification listener:", error);
        });
    };

    setupForegroundNotificationListener();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setUnreadCount(notifications.filter((n) => !n.is_viewed).length);
  }, [notifications]);

  return (
    <NotificationsContext.Provider
      value={{ notifications, setNotifications, unreadCount }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationsContext);
