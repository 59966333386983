import {
  Button,
  Col,
  Divider,
  Drawer,
  List,
  Result,
  Row,
  Space,
  message,
  notification,
} from "antd";
import React from "react";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH } from "../../../common/commonFunc";
import user from "../../../assests/RandomUser.jpeg";
import Styles from "../Profile.module.css";

export const FollowingDrawer = ({
  open,
  onClose,
  followersData,
  userRole,
}: any) => {
  console.log("FOLlOwERS DATA INSIDE DRAWER IS", followersData);
  console.log("ROLE DATA INSIDE DRAWER IS", userRole);
  const unfollowUser = async (userId: any) => {
    if (userRole == "freelancer") {
      const payload = {};
      try {
        const response = await _PATCH(
          `userProfile/unfollowFollowers/${userId}`,
          payload,
          true
        );
        if (response.status === true && response.statusCode === 200) {
          message.success(" user unfollowed successfully successfully");
        } else {
          message.error(
            response.data && response.message
              ? response.message
              : "user unfollowed failed"
          );
        }
      } catch (error) {
        console.error("Error in unfollowing user:", error);
      }
    } else {
      const payload = {};
      try {
        const response = await _PATCH(
          `userProfile/unfollowFollowings/${userId}`,
          payload,
          true
        );
        if (response.status === true && response.statusCode === 200) {
          message.success(" user unfollowed successfully");
        } else {
          message.error(
            response.data && response.message
              ? response.message
              : "user unfollowed failed"
          );
        }
      } catch (error) {
        console.error("Error in unfollowing user:", error);
      }
    }
  };
  return (
    <Drawer
      title="Followers"
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      key="right"
      width={"550px"}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <Col span={24}>
        <Row>
          <h2>Followers ({followersData.length})</h2>
        </Row>

        <br></br>
        {followersData && followersData.length > 0 ? (
          followersData.map((follower: any, index: number) => (
            <div key={index}>
              <Row align="middle">
                <Col span={4}>
                  {" "}
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      marginLeft: "10px",
                      marginTop: "20px",
                    }}
                    src={follower.profile_pic}
                    alt=""
                  ></img>
                </Col>
                <Col span={16}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <span style={{ fontSize: "16px", fontWeight: "800" }}>
                      {follower.first_name} {follower.last_name}
                    </span>
                    {/* {userRole == "freelancer" &&
                      follower?.user_categories &&
                      JSON.parse(follower?.user_categories)[0] && (
                        <span style={{ fontSize: "14px" }}>
                          {JSON.parse(follower?.user_categories)[0]}
                        </span>
                      )} */}
                    {userRole == "freelancer" &&
                      follower?.user_categories &&
                      (() => {
                        try {
                          const categories = JSON.parse(
                            follower.user_categories
                          );
                          return (
                            categories[0] && (
                              <span style={{ fontSize: "14px" }}>
                                {categories[0]}
                              </span>
                            )
                          );
                        } catch (error) {
                          console.error(
                            "Invalid JSON format in user categories:",
                            error
                          );
                          return null;
                        }
                      })()}
                  </div>
                </Col>
                <Col span={2}>
                  <div
                    onClick={() => unfollowUser(follower.id)}
                    style={{
                      backgroundColor: "#f2f0f3",
                      borderRadius: "15px",
                      border: "none",
                      fontWeight: "800",
                      width: "fit-content",
                      padding: "7px",
                      cursor: "pointer",
                    }}
                  >
                    Unfollow
                  </div>
                </Col>
                <Divider />
              </Row>
            </div>
          ))
        ) : (
          <div className={Styles.NoDataFound}>
            <Result
              title="No Followers Found !!"
              extra={
                <Button type="primary" key="console">
                  No followers found in your account.
                </Button>
              }
            />
          </div>
        )}
      </Col>
    </Drawer>
  );
};
