import { useState, useEffect, useRef } from "react";
import { Button, Divider, Layout, Popconfirm, notification, theme } from "antd";
import Styles from "./CartDrawer.module.css";
import { _DELETE, _GET, _POST } from "../../../common/commonFunc";
import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteRed from "../../../assests/customIcons/DeleteRed";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Percentage from "../../../assests/customIcons/Percentage";
import OCPoint from "../../../assests/customIcons/OCPoint";
import MinusIcon from "../../../assests/MinusCustom.svg";
import PlusIcon from "../../../assests/PlusCustom.svg";
import { useHistory } from "react-router";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import {
  deleteCartItem,
  deleteCartEventItem,
} from "../redux/actions/bookingActions";
import { useDispatch } from "react-redux";
import {
  fetchCartItems,
  resetCartFetched,
} from "../../../redux/actions/cartActions";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

const { Content } = Layout;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  borderRadius: "10px",
}));

const ItemGrey = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#fff" : "rgba(48, 38, 62, 0.05)",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  boxShadow: "none",
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiCheckbox-root": {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiIconButton-root": {
      padding: 0,
      "& .MuiSvgIcon-root": {
        fontSize: "0.5rem",
      },
    },
  },
  "& .Mui-checked": {
    backgroundImage:
      "linear-gradient(106.87deg, rgba(136, 0, 255, 0.08) 0.56%, rgba(0, 179, 255, 0.08) 75.66%)",
    border: "1px solid transparent",
    borderRadius: "5px",
  },
}));

const OrderCartDrawer = ({ data, fetchCartItem }: any) => {
  //console.log("data in order cart is ---> ", data);
  const [service, setService] = useState<any>([]);
  const history = useHistory();
  const [productDetails, setProductDetails] = useState<any>({});
  const [selectedItems, setSelectedItems] = useState<any | undefined>();
  const [cartItems, setCartItems] = useState<any>([]);
  const [billingSummary, setBillingSummary] = useState<any>({});
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  //console.log("🚀 ~ ProductDetails ~ productDetails:", productDetails);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const dispatch = useDispatch();

  const userdata: any = localStorage.getItem("userInfo");
  const parsedUserData = JSON.parse(userdata);
  const userId = parsedUserData?.id;

  const handleDelete = async (itemId: any) => {
    try {
      const url = `cart/removeItem/product/${userId}/${itemId}`;
      const response = await _DELETE(url, true);
      fetchCartItem();
      dispatch(deleteCartItem(itemId));
      notification.success({
        message: "Item Deleted",
        description: "The item has been successfully removed from the cart.",
      });
      dispatch(resetCartFetched());

      dispatch(fetchCartItems());
    } catch (error) {
      console.error("Error in deleting item:", error);
    }
  };

  useEffect(() => {
    //console.log("data changed from order cart - ", data);

    setCartItems(data);
    setSelectedItems(
      data?.reduce((acc: any, item: any) => {
        acc[item.itemId] = item;
        return acc;
      }, {})
    );
  }, [data]);

  const handleSelectedOrder = (itemId: any, checked: any) => {
    setSelectedItems((prevSelectedItems: any) => {
      if (checked) {
        const selectedItem = cartItems.find(
          (item: any) => item.itemId === itemId
        );
        if (selectedItem) {
          return {
            ...prevSelectedItems,
            [itemId]: selectedItem,
          };
        } else {
          return prevSelectedItems;
        }
      } else {
        const { [itemId]: _, ...updatedItems } = prevSelectedItems;
        return updatedItems;
      }
    });
  };

  const getBillingSummary = async () => {
    const orderDetails = Object.values(selectedItems)
      .filter((item: any) => item?.itemId)
      .map((item: any) => ({
        productId: item?.productItemId,
        quantity: item?.quantity,
      }));

    const requestBody = {
      orderDetails: orderDetails,
    };

    //console.log("orderdetails is : ", orderDetails);
    //console.log("RequestBody is : ", requestBody);

    try {
      const response = await _POST(
        `supplier/multipleOrderBillingSummary`,
        requestBody,
        true
      );

      if (response.statusCode === 200 && response.data) {
        //console.log("API Response:", response.data);
        setBillingSummary(response.data);
      } else {
        //console.log("API request failed");
      }
    } catch (error) {
      console.error("Error while making API request:", error);
    }
  };

  useEffect(() => {
    if (selectedItems) getBillingSummary();
  }, [selectedItems]);

  const incrementCount = (id: any) => {
    setCartItems((prevItems: any) =>
      prevItems.map((item: any) => {
        if (item.itemId === id) {
          const updatedQuantity = item.quantity + 1;

          const updatedOrderDetails = {
            ...selectedItems,
            [id]: { ...item, quantity: updatedQuantity },
          };
          setSelectedItems(updatedOrderDetails);
          return { ...item, quantity: updatedQuantity };
        }
        return item;
      })
    );
  };

  const decrementCount = (id: any) => {
    setCartItems((prevItems: any) =>
      prevItems.map((item: any) => {
        if (item.itemId === id && item.quantity > 1) {
          const updatedQuantity = item.quantity - 1;

          const updatedOrderDetails = {
            ...selectedItems,
            [id]: { ...item, quantity: updatedQuantity },
          };
          setSelectedItems(updatedOrderDetails);
          return { ...item, quantity: updatedQuantity };
        }
        return item;
      })
    );
  };

  useEffect(() => {
    //console.log("billing summary is : ", billingSummary);
  }, [billingSummary]);

  const handleCheckout = (product: any) => {
    history.push(APPLICATION_ROUTES.MULTI_CHECKOUT, {
      billingSummary: billingSummary,
      selectedProducts: selectedItems || {},
    });
  };

  const handleScroll = () => {
    if (!contentRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

    if (scrollTop + clientHeight < scrollHeight - 10) {
      setShowScrollToBottom(true);
    } else {
      setShowScrollToBottom(false);
    }

    if (scrollTop > 10) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: contentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Layout
      className={Styles.customLayoutBackground}
      style={{
        borderRadius: "10px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Content
        ref={contentRef}
        onScroll={handleScroll}
        className={Styles.hiddenScrollbar}
        style={{
          flex: 1,
          overflowY: "auto",
          padding: "10px",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Item>
              {cartItems.length === 0 || !cartItems ? (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <h1> No items in cart</h1>
                </div>
              ) : (
                <Box sx={{ padding: 0 }}>
                  <TableContainer
                    component={Paper}
                    sx={{
                      overflowX: "auto",
                      boxShadow: "none !important",
                    }}
                    className={Styles.tableContainer}
                  >
                    <Table stickyHeader className={Styles.table}>
                      <TableBody className={Styles.tableBody}>
                        {cartItems?.map((item: any, index: any) => (
                          <TableRow
                            key={item?.itemId}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              padding: "10px",
                              borderBottom: "1px solid #e0e0e0",
                            }}
                          >
                            <TableCell
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: "10px",
                              }}
                            >
                              <CustomCheckbox
                                aria-label={`select row ${index}`}
                                className={Styles.customCheckbox}
                                disabled={!item.itemId}
                                checked={
                                  item.itemId && !!selectedItems?.[item.itemId]
                                }
                                onChange={(e) =>
                                  handleSelectedOrder(
                                    item.itemId,
                                    e.target.checked
                                  )
                                }
                              />
                              <Box
                                sx={{
                                  marginLeft: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {item?.itemPic?.length > 0 ? (
                                  <Avatar
                                    alt={`${item?.itemName}`}
                                    src={item?.itemPic[0]}
                                    sx={{
                                      width: 50,
                                      height: 50,
                                      border: "2px solid white",
                                      marginRight: "10px",
                                    }}
                                  />
                                ) : (
                                  <div className={Styles.avatarBG}>
                                    {item?.itemName[0] +
                                      item?.itemName[1]?.toUpperCase()}
                                  </div>
                                )}
                                <Box>
                                  <Typography variant="subtitle1">
                                    {item?.itemName}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {item?.itemCategory}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        background:
                                          item?.inventory?.[0]?.colorCode ||
                                          "transparent",
                                        height: "10px",
                                        width: "10px",
                                        borderRadius: "50%",
                                        border: "1px solid grey",
                                        marginRight: "5px",
                                      }}
                                    ></Box>
                                    <Typography variant="caption">
                                      {item?.inventory?.[0]?.color || "NA"} |
                                      Size: {item?.inventory?.[0]?.size}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "grey",
                                    fontWeight: "200",
                                    marginRight: "5px",
                                  }}
                                >
                                  BHD
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "600" }}
                                >
                                  {item?.itemPrice}
                                </Typography>
                              </Box>
                              <Box className={Styles.cartCount}>
                                <button
                                  onClick={() => decrementCount(item?.itemId)}
                                  className={Styles.CartButton}
                                >
                                  <img src={MinusIcon} alt="Decrease" />
                                </button>
                                <span className={Styles.countDisplay}>
                                  {item?.quantity}
                                </span>
                                <button
                                  onClick={() => incrementCount(item?.itemId)}
                                  className={Styles.CartButton2}
                                >
                                  <img src={PlusIcon} alt="Increase" />
                                </button>
                              </Box>
                              <IconButton
                                onClick={() => handleDelete(item?.itemId)}
                                className="elevatedElement iconBtn"
                              >
                                <DeleteRed />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Item>
          </Grid>
        </Grid>
      </Content>
      <Box
        sx={{
          borderTop: "1px solid #e0e0e0",
          background: "#fff !important",
          position: "sticky",
          bottom: 0,
          borderRadius: "10px 10px 0px 0px",
          boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        <ItemGrey>
          <div>
            <section>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "20px",
                }}
              >
                <Typography variant="h6" color={"black"}>
                  Subtotal{" "}
                  {Object.keys(selectedItems || {}).length === 1
                    ? "(1 item)"
                    : Object.keys(selectedItems || {}).length > 1
                    ? `(${Object.keys(selectedItems || {}).length} items)`
                    : ""}
                </Typography>
                <h3 className="bold xlSize">
                  <span className="grayText">
                    {billingSummary?.totalAmountCurrency}
                  </span>{" "}
                  <span style={{ color: "black" }}>
                    {billingSummary?.totalProductAmountAfterDiscount}
                  </span>
                </h3>
              </div>
            </section>
            <Divider />
            <div
              style={{
                display: "flex",
                alignContent: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                className={Styles.checkoutButton}
                onClick={handleCheckout}
                disabled={
                  cartItems.length === 0 ||
                  Object.keys(selectedItems || {}).length === 0
                }
              >
                Continue To Checkout
              </Button>
            </div>
          </div>
        </ItemGrey>
      </Box>
      {showScrollToBottom && (
        <IconButton
          onClick={scrollToBottom}
          sx={{
            position: "fixed",
            bottom: "70px",
            right: "20px",
            background: "#fff",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1002,
          }}
        >
          <ArrowDownOutlined />
        </IconButton>
      )}
      {showScrollToTop && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "120px",
            right: "20px",
            background: "#fff",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 1002,
          }}
        >
          <ArrowUpOutlined />
        </IconButton>
      )}
    </Layout>
  );
};

export default OrderCartDrawer;
