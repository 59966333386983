import { Box, CardContent, Typography } from "@mui/material";
import {
  Button,
  Drawer,
  Space,
  notification,
} from "antd";
import User from "../../../assests/RandomUser.jpeg";
import Styles from "./AffiliateProducts.module.css";
import Cross from "../../../assests/customIcons/Cross";
import { _PATCH } from "../../../common/commonFunc";

export const ProductContractDrawer = ({
  contractDetails,
  onClose,
  open,
  getProductsData,
}: any) => {
  const handleApproveProduct = async () => {
    const payload = {};
    try {
      const response = await _PATCH(
        `supplier/upload-sign-contract/${contractDetails?.contract_id}`,
        payload,
        true
      );
       if (response) {
        if (response?.status) {
          notification.open({
            type: "success",
            message: "Contract signed successfully",
            description: "Your contract has been signed successfully",
          });
          
        } else {
          notification.open({
            type: "error",
            message: "Error in accepting contract",
            description: "Contract not accepted",
          });
        }
        getProductsData();
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formatDate = (unixTimestamp: any) => {
    const date = new Date(parseInt(unixTimestamp) * 1000);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  return (
    <Drawer
      title="Contract Details"
      placement="right"
      onClose={onClose}
      open={open}
      key="right"
      width={"550px"}
      closable={false}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <div style={{ display: "flex" }}>
        <img
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginLeft: "10px",
          }}
          src={contractDetails?.supplier_profile_pic}
          alt={User}
        />
        <h3>
          <b>
            &nbsp; &nbsp; &nbsp; By {contractDetails?.supplier_first_name}{" "}
            {contractDetails?.supplier_last_name}
          </b>
        </h3>
      </div>
      <br />
      <br />
      <div>
        {contractDetails?.assigned_status === "assigned" ? (
          <Button style={{ cursor: "not-allowed" }} className={Styles.assigned}>
            Assigned Product
          </Button>
        ) : contractDetails?.assigned_status === "pending" ? (
          <Button className={Styles.pending}>Pending Product</Button>
        ) : contractDetails?.assigned_status === "returned" ? (
          <Button className={Styles.rejected}>Returned Product</Button>
        ) : (
          <></>
        )}
      </div>
      <Box sx={{ display: "flex", flexDirection: "row" }} marginTop={3} gap={2}>
        {contractDetails?.product_images &&
          contractDetails.product_images.map((image: string, index: number) => (
            <img
              key={index}
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                marginLeft: "10px",
                marginTop: "20px",
              }}
              src={image}
              alt={"Product Image"}
            />
          ))}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography
            component="div"
            variant="h5"
            fontWeight={700}
            gutterBottom
          >
            {contractDetails?.product_name}{" "}
          </Typography>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}
            sx={{ display: "flex", flexDirection: "row" }}
            gutterBottom
          >
            Service provider name:{" "}
            <div style={{ color: "black", marginLeft: "3px", display: "flex" }}>
              {contractDetails?.supplier_first_name}{" "}
              {contractDetails?.supplier_last_name}
            </div>
          </Typography>
          <Typography
            variant="subtitle2"
            color={"text.secondary"}
            sx={{ display: "flex", flexDirection: "row" }}
            gutterBottom
          >
            Contract ID:{" "}
            <div style={{ color: "black", marginLeft: "3px" }}>
              {contractDetails?.contract_id}
            </div>
          </Typography>

          <Typography
            variant="subtitle2"
            color={"text.secondary"}
            sx={{ display: "flex", flexDirection: "row" }}
            gutterBottom
          >
            Collaboration Duration:{" "}
            <div style={{ color: "black", marginLeft: "3px" }}>
              {formatDate(contractDetails.collaboration_start_date)}
              {" to"} {formatDate(contractDetails.collaboration_end_date)}
            </div>
          </Typography>
          <Box component="div" marginTop={3}>
            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
              Commission Rate
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "100px",
              overflowY: "scroll",
            }}
          >
            <Typography variant="caption" color={"text.secondary"} gutterBottom>
              {contractDetails?.commission_rate} % Commission Rate
            </Typography>
          </Box>
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
            marginTop={3}
          >
            <Typography variant="subtitle1" fontWeight={500} gutterBottom>
              Detailed Information on Promotional Products
            </Typography>
            <Typography variant="caption" color={"text.secondary"} gutterBottom>
              <li>
                {" "}
                Assigned Quantity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{contractDetails.assigned_quantity}</b> Units
              </li>
              <li>
                {" "}
                Available Quantity &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{contractDetails.available_quantity}</b> Units
              </li>
              <li>
                {" "}
                Sold Quantity
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>{contractDetails.sold_quantity}</b> Units
              </li>
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "row" }}
            marginTop={3}
            gap={2}
          >
            {contractDetails?.assigned_status === "pending" ? (
              <>
                <Button className={Styles.buttonDark}>Return Product</Button>
                <Button
                  className={Styles.buttonFilled}
                  onClick={() => handleApproveProduct()}
                >
                  Accept product
                </Button>
              </>
            ) : contractDetails?.assigned_status === "assigned" ? (
              <Button className={Styles.buttonDark}>Return Product</Button>
            ) : (
              <></>
            )}
          </Box>
        </CardContent>
      </Box>
    </Drawer>
  );
};
