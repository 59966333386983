import React, { useState, useEffect } from "react";
import { Drawer, Button, Input, Form, notification, Space } from "antd";
import Styles from "./InstaDrawerUser.module.css";
import { _GET, _POST } from "../../../common/commonFunc";
import { InstagramOutlined } from "@ant-design/icons";
import Cross from "../../../assests/customIcons/Cross";

const InstaDrawerUser: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const [username, setUsername] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (open) {
      fetchUserProfile();
    }
  }, [open]);

  const fetchUserProfile = async () => {
    try {
      const res = await _GET("/userProfile/profile", true);
      if (res.status && res.data.socialMediaData) {
        const instagramData = res.data.socialMediaData.find(
          (data: any) => data.platform === "instagram"
        );
        if (instagramData) {
          const profileLink = instagramData.profile_link;
          const url = new URL(profileLink);
          const existingUsername = url.pathname.split("/")[1];
          setUsername(existingUsername);
          setIsUpdating(true);
        } else {
          setUsername("");
          setIsUpdating(false);
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      notification.error({
        message: "Error",
        description: "An error occurred while fetching profile data.",
      });
    }
  };

  const handleSave = async () => {
    if (!username) {
      notification.error({
        message: "Error",
        description: "Please enter your Instagram username.",
      });
      return;
    }
    try {
      const profileLink = `https://instagram.com/${username}`;
      const payload = [
        {
          platform: "instagram",
          profile_link: profileLink,
        },
      ];
      const response = await _POST("userProfile/social-media", payload, true);
      if (response) {
        notification.success({
          message: "Instagram Link Saved",
          description:
            "Your Instagram profile link has been saved successfully.",
        });
        onClose();
      } else {
        notification.error({
          message: "Failed to Save Instagram Link",
          description: "Could not save your Instagram link.",
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "An error occurred while saving your Instagram link.",
      });
    }
  };

  return (
    <Drawer
      title="Instagram Profile"
      placement="right"
      onClose={onClose}
      open={open}
      className={Styles.drawer}
      width={500}
      closable={false}
      extra={
        <Space style={{ zIndex: 100 }}>
          <Button onClick={onClose} className="elevatedElement iconBtn">
            <Cross />
          </Button>
        </Space>
      }
    >
      <p className={Styles.encouragementText}>
        Connect your Instagram profile to enhance your visibility and engage
        with others!
      </p>
      <Form layout="vertical" className={Styles.form} onFinish={handleSave}>
        <Form.Item
          label=""
          className={Styles.formItem}
          rules={[
            { required: true, message: "Please enter your Instagram username" },
          ]}
        >
          <Input
            addonBefore={
              <span>
                <InstagramOutlined style={{ marginRight: 8 }} />
                https://instagram.com/
              </span>
            }
            placeholder="Enter Instagram Username"
            className={Styles.inputItem}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Item>

        <Form.Item className={Styles.formItem}>
          <Button type="primary" htmlType="submit" className={Styles.button}>
            {isUpdating ? "Update" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default InstaDrawerUser;
