import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Layout, Popconfirm, message } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { _GET, _POST } from "../../../common/commonFunc";
import Styles from "./Inventory.module.css";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import CustomDropdown from "./CustomDropdown";
import CustomCard from "./CustomCard";
import axios from "axios";
import CollaborationDetailsTable from "./CollaborationDetailsTable";
import { API_BASE_URL } from "../../../constant/ApplicationRoutes";

const CollabDetailsNonProduct = (ids: any) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [collabDetails, setCollabDetails] = useState<any>();

  const getCollabDetails = async () => {
    try {
      const res = await axios.get(
        `${API_BASE_URL}supplier/getNonProductContractsDetails?request_id=${id}`,
        {
          headers: {
            "x-access-token": localStorage.getItem("jwtToken"),
          },
        }
      );

      if (res.data && res.data.data) {
        console.log("Response from collabDetails :", res.data.data);
        setCollabDetails(res.data.data);
      } else {
        console.error("Error fetching Collaboration Details");
      }
    } catch (error) {
      console.error("Error fetching Collaboration Details: ", error);
    }
  };

  useEffect(() => {
    getCollabDetails();
  }, []);

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const extendCollab = async (id: any) => {
    console.log("🚀 ~ extenddCollab ~ id:", id);
    const payload = {};
    try {
      const response = await _POST(
        `supplier/extend-collaboration-request/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Collaboration extended successfully");
      } else {
        message.error(
          response.data && response.statusCode
            ? response.message
            : "Extend collaboration failed"
        );
      }
    } catch (error) {
      console.log("🚀 ~ extendCollab ~ error:", error);
    }
  };

  const confirmExtendCollab = (request_id: any) => {
    extendCollab(request_id);
  };

  const headers = [
    { id: "contract_id", label: "CONTRACT ID", width: "10%" },
    { id: "detailed_info", label: "DETAILED INFORMATION", width: "30%" },
    {
      id: "collab_interest",
      label: " COLLABORATION INTEREST & EXPECTATIONS",
      width: "31%",
    },
    { id: "fixed_amount", label: "FIXED AMOUNT", width: "10%" },
    { id: "action", label: "ACTION", width: "20%" },
  ];

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA",
      }}
      className={Styles.customLayoutBG}
    >
      <div className={Styles.header}>
        <h1>
          <span className={Styles.countingSpanParent}>
            <span
              className={Styles.gradientText}
              style={{
                WebkitBackgroundClip: "text",
              }}
            >
              Collaborator
            </span>{" "}
            Details
          </span>
        </h1>
        <div className={Styles.serviceHeader}>
          <div className={Styles.actionsContainerMain}>
            {/* <CustomDropdown
              freelancers={freelancer_info}
              onFreelancerSelect={handleFreelancerSelect}
            /> */}
            <Popconfirm
              title="Are you sure you want to extend this collaboration?"
              onConfirm={() =>
                confirmExtendCollab(collabDetails?.collaborationRequestId)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button className={Styles.buttonFilled}>
                Extend Collaboration
              </Button>
            </Popconfirm>
            <Button className={Styles.buttonTransparent}>Send Message</Button>
          </div>
        </div>
      </div>

      <Breadcrumb separator={<img src={breadcrumbIcon} alt=">" />}>
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/collabration")}
        >
          Collaborations
        </Breadcrumb.Item>
        <Breadcrumb.Item>Collaborations detail</Breadcrumb.Item>
      </Breadcrumb>

      <div
        style={{
          height: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className={Styles.parentContentdiv}
      >
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          {collabDetails && <CustomCard data={collabDetails} />}
          <h3>Freelancer contract information</h3>
        </div>
        <CollaborationDetailsTable headers={headers} data={collabDetails} />
      </div>
    </Layout>
  );
};

export default CollabDetailsNonProduct;
