import {
  EditOutlined,
  SaveOutlined,
  BankOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Card,
  Form,
  Input,
  Select,
  message,
  Radio,
} from "antd";
import React, { useState, useEffect } from "react";
import { _PATCH, _POST } from "../../../../../common/commonFunc";
import styles from "./EditSupplier.module.css";
import FileUpload from "../../../../../components/InputComponents/FileUpload";
import countries from "../../../Data/countries.json";

import { RemoveCircle } from "@mui/icons-material";
//   @ts-ignore
import * as iban from "iban";
import { noSpecialCharactersValidator } from "../../../../../utils/helpers";

const { Option } = Select;

const PaymentInfoCard = ({ userInfo, onSave, back }: any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const [form] = Form.useForm();
  const [documentImages, setDocumentImages] = useState<any>({});
  const [documentImagesTax, setDocumentImagesTax] = useState<any>({});

  const [existingPreviews, setExistingPreviews] = useState<any>([]);
  const [existingPreviewsTax, setExistingPreviewsTax] = useState<any>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<any>([]);
  const [taxRegistered, setTaxRegistered] = useState(
    userInfo?.payment_info?.tax_registered === "Yes"
  );

  useEffect(() => {
    if (userInfo) {
      const beneficiaryPhoneNumber = JSON.parse(
        userInfo?.payment_info?.beneficiaryPhoneNumber || "{}"
      );

      setPhoneNumbers([beneficiaryPhoneNumber]);

      const initialFormValues = {
        beneficiaryIBAN: userInfo?.payment_info?.beneficiaryIBAN,
        beneficiaryType: userInfo?.payment_info?.beneficiaryType,
        beneficiaryEmail: userInfo?.payment_info?.beneficiaryEmail,
        beneficiaryFullName: userInfo?.payment_info?.beneficiaryFullName,
        ibanCertificate: userInfo?.payment_info?.iban_certificate
          ? JSON.parse(userInfo?.payment_info?.iban_certificate).map(
              (doc: any, index: number) => ({
                uid: index.toString(),
                name: `IBAN Certificate ${index + 1}`,
                status: "done",
                url: doc,
              })
            )
          : [],
        tax_document_link: userInfo?.payment_info?.tax_document_link
          ? JSON.parse(userInfo?.payment_info?.tax_document_link).map(
              (doc: any, index: number) => ({
                uid: index.toString(),
                name: `Tax Document ${index + 1}`,
                status: "done",
                url: doc,
              })
            )
          : [],
        tax_registered: userInfo?.payment_info?.tax_registered,
        tax_registration_number:
          userInfo?.payment_info?.tax_registration_number,
      };

      setInitialValues(initialFormValues);
      form.setFieldsValue(initialFormValues);
      setExistingPreviews(
        initialFormValues.ibanCertificate.map((doc: any) => doc.url)
      );

      setExistingPreviewsTax(
        initialFormValues?.tax_document_link?.map((doc: any) => doc.url)
      );
    }
  }, [userInfo, form]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    form.setFieldsValue(initialValues);
    setIsEditing(false);
  };

  const handlePhoneNumberChange = (index: any, field: any, value: any) => {
    const updatedPhoneNumbers: any = [...phoneNumbers];
    updatedPhoneNumbers[index][field] = value;
    setPhoneNumbers(updatedPhoneNumbers);
  };

  const uploadImages = async () => {
    if (documentImages.ibanCertificate) {
      console.log("➡️   ~ uploadImages ~ documentImages:", documentImages);
      const formData = new FormData();
      formData.append("images", documentImages.ibanCertificate);

      try {
        const response: any = await _POST(
          `uploadImagesToS3/supplier_document`,
          formData,
          true
        );

        if (response?.data?.length > 0) {
          const uploadedImages = response.data;
          return uploadedImages[0];
        }
      } catch (error) {
        console.error("Error uploading images:", error);
        message.error("Failed to upload image.");
      }
    }
    return null;
  };

  const uploadImagesTax = async () => {
    if (documentImagesTax.tax_document_link) {
      const formData = new FormData();
      formData.append("images", documentImagesTax.tax_document_link);

      try {
        const response: any = await _POST(
          `uploadImagesToS3/supplier_document`,
          formData,
          true
        );

        if (response?.data?.length > 0) {
          const uploadedImages = response.data;
          return uploadedImages[0];
        }
      } catch (error) {
        console.error("Error uploading images:", error);
        message.error("Failed to upload image.");
      }
    }
    return null;
  };

  const handleSave = async (values: any) => {
    try {
      const formattedPhoneNumber = JSON.stringify(phoneNumbers[0]);

      const ibanLink = await uploadImages();

      const taxDoc = await uploadImagesTax();

      const formattedIbanCertificate = JSON.stringify(
        ibanLink ? [ibanLink] : existingPreviews
      );

      const formattedTaxDocumentLink = JSON.stringify(
        taxDoc ? [taxDoc] : existingPreviewsTax
      );

      const paymentInfo = {
        beneficiaryIBAN: values.beneficiaryIBAN,
        beneficiaryType: values.beneficiaryType,
        beneficiaryEmail: values.beneficiaryEmail,
        beneficiaryFullName: values.beneficiaryFullName,
        beneficiaryPhoneNumber: formattedPhoneNumber,
        iban_certificate: formattedIbanCertificate,
        tax_registration_number: values.tax_registration_number,
        tax_document_link: formattedTaxDocumentLink,

        tax_registered: values.tax_registered ? "Yes" : "No",
      };

      const response = await _PATCH(
        `userProfile/supplier/details`,
        { payment_info: paymentInfo },
        true
      );

      if (response?.status === true && response?.statusCode === 200) {
        message.success("Payment Info updated successfully");
        onSave();
        setIsEditing(false);
      } else {
        message.error("Failed to update Payment Info");
      }
    } catch (error) {
      console.error("Error updating Payment Info:", error);
      message.error("An error occurred while updating Payment Info");
    }
  };

  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { number: "", countryCode: "" }]);
  };

  const removePhoneNumber = (index: any) => {
    const updatedPhoneNumbers = phoneNumbers.filter(
      (_: any, i: any) => i !== index
    );
    setPhoneNumbers(updatedPhoneNumbers);
  };

  return (
    <Card
      title={
        userInfo.payment_info ? (
          "Payment Information"
        ) : (
          <p style={{ color: "#ff4d4f" }}>
            No Payment Information. You can edit and add.
          </p>
        )
      }
      extra={
        <div style={{ display: "flex", gap: "10px" }}>
          {isEditing && (
            <Button
              icon={<CloseOutlined />}
              onClick={handleCancel}
              className={styles.saveBtn}
            >
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            icon={isEditing ? <SaveOutlined /> : <EditOutlined />}
            onClick={() => {
              if (isEditing) {
                form.validateFields().then((values) => {
                  handleSave(values);
                });
              } else {
                handleEditClick();
              }
            }}
            className={`${styles.saveBtn} ${
              isEditing ? styles.saveSmallBtn : ""
            }`}
          >
            {isEditing ? "Save" : "Edit"}
          </Button>
        </div>
      }
      className={styles.paymentCard}
    >
      <Form
        form={form}
        style={{ marginTop: "0 !important" }}
        layout="vertical"
        initialValues={initialValues}
        className={styles.form}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="beneficiaryIBAN"
              label="Beneficiary IBAN"
              rules={[
                { required: true, message: "Please enter beneficiary IBAN" },
                {
                  //    validate using iban
                  validator: (_, value) => {
                    if (value && !iban.isValid(value)) {
                      return Promise.reject("Invalid IBAN");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                //={<BankOutlined />}
                placeholder="Enter IBAN"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="beneficiaryType"
              label="Beneficiary Type"
              rules={[
                { required: true, message: "Please select beneficiary type" },
              ]}
            >
              <Select
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Select type"
              >
                <Option value="Local">Local</Option>
                {/* <Option value="International">International</Option> */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="beneficiaryEmail"
              label="Beneficiary Email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Enter email"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="beneficiaryFullName"
              label="Beneficiary Full Name"
              rules={[
                { required: true, message: "Please enter full name" },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters are allowed!"
                  ),
                },
              ]}
            >
              <Input
                disabled={!isEditing}
                className={
                  isEditing ? styles.loginInput : styles.loginInputDisable
                }
                placeholder="Enter full name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="beneficiaryPhoneNumber"
              label="Beneficiary Phone Number"
              className={styles.FormInputNumberItem}
            >
              {phoneNumbers.map((phone: any, index: any) => (
                <Input.Group
                  compact
                  key={index}
                  className={styles.FormNumberInputEdit}
                  style={{ marginBottom: 8 }}
                >
                  <Select
                    defaultValue={phone.countryCode}
                    style={{ width: "20%" }}
                    onChange={(value) =>
                      handlePhoneNumberChange(index, "countryCode", value)
                    }
                    showSearch
                    optionFilterProp="children"
                    className={styles.FormInputNumber}
                    disabled={!isEditing}
                  >
                    {countries.map((country) => (
                      <Option key={country.code} value={country.dial_code}>
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ marginRight: 8, width: 20 }}
                        />
                        {country.dial_code}
                      </Option>
                    ))}
                  </Select>
                  <Input
                    style={{ width: "70%" }}
                    value={phone.number}
                    onChange={(e) =>
                      handlePhoneNumberChange(index, "number", e.target.value)
                    }
                    placeholder="Enter phone number"
                    className={styles.FormInputNumber}
                    disabled={!isEditing}
                  />
                </Input.Group>
              ))}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Are you tax-registered?" name="tax_registered">
              <Radio.Group
                onChange={(e) => {
                  setTaxRegistered(e.target.value === "Yes");
                }}
                defaultValue={taxRegistered ? "Yes" : "No"}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {(taxRegistered ||
            userInfo?.payment_info?.tax_registered === "Yes") && (
            <Col span={12}>
              <Form.Item
                name="tax_registration_number"
                label="Tax Registration Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter tax registration number",
                  },
                  {
                    validator: noSpecialCharactersValidator(
                      "No special characters are allowed!"
                    ),
                  },
                ]}
              >
                <Input
                  disabled={!isEditing}
                  className={
                    isEditing ? styles.loginInput : styles.loginInputDisable
                  }
                  placeholder="Enter tax registration number"
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="ibanCertificate" label="IBAN Certificate">
              <FileUpload
                title1="Upload IBAN Certificate"
                description="PDF, JPG, PNG file with max size of 10mb."
                setData={setDocumentImages}
                name="ibanCertificate"
                existingPreviews={existingPreviews}
                setExistingPreviews={setExistingPreviews}
                disabled={!isEditing}
                alllowAll
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="tax_document_link" label="Tax Document">
              <FileUpload
                title1="Upload Tax Document"
                description="PDF, JPG, PNG file with max size of 10mb."
                setData={setDocumentImagesTax}
                name="tax_document_link"
                existingPreviews={existingPreviewsTax}
                setExistingPreviews={setExistingPreviewsTax}
                disabled={!isEditing}
                alllowAll
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default PaymentInfoCard;
