import React, { useEffect, useState } from "react";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import _ from "lodash";
import Styles from "./BankInfo.module.css";
import { _GET } from "../../../common/commonFunc";
import { Button, Col, Divider, Row } from "antd";
import { BankInfoDrawer } from "./BankInfoDrawer";
import Edit from "../../../assests/customIcons/Edit.js";
// @ts-ignore
import Tour from "reactour";
import { CreateOutlined, EditAttributes } from "@mui/icons-material";

export const BankInfo = () => {
  const [bankData, setBankData] = React.useState<any>({});
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [open, setOpen] = React.useState(false);

  // useEffect(() => {
  //   if (bankData?.is_tax_registered === false) {
  //     setIsTourOpen(true);
  //   }
  // }, [bankData]);

  const fetchData = async () => {
    try {
      const data = await _GET("userProfile/profile", true);
      const mainData = _.get(data, "data", {});

      setBankData(mainData);
    } catch (error) {
      console.error(error);
    }
  };

  const steps = [
    {
      selector: "#updateTaxInfoButton",
      content: "Click here to add or update your tax information.",
      position: "top",
    },
  ];

  React.useEffect(() => {
    console.log(bankData);
    fetchData();
  }, []);

  const onOpenDrawer = () => {
    setOpen(true);
    setIsTourOpen(false);
  };
  const onCloseDrawer = () => {
    setOpen(false);
  };

  const combinedBankAndTaxInfo = {
    bankDetails: {
      beneficiaryType: bankData?.bank_info?.beneficiaryType,
      beneficiaryFullName: bankData?.bank_info?.beneficiaryFullName,
      beneficiaryIBAN: bankData?.bank_info?.beneficiaryIBAN,
      beneficiaryPhoneNumber: bankData?.bank_info?.beneficiaryPhoneNumber,
      beneficiaryEmail: bankData?.bank_info?.beneficiaryEmail,
    },
    role: bankData?.role || "Not Available",
    zoho_user_id: bankData?.zoho_user_id || null,
    tax_registration_number: bankData?.tax_registered_number || null,
    tax_document_link: bankData?.tax_document_referance || null,
    is_tax_registered: bankData?.is_tax_registered || false,
  };

  return (
    <CustomProfileLayout>
      {/* <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        prevButton={<></>}
        nextButton={<></>}
        badgeContent={() => <CreateOutlined />}
        roundedCorners
        showDots={false}
      /> */}
      <Row className={Styles.personalInfoOuterCotainer}>
        <Col span={22}>
          <Row className={Styles.topHeading}>
            <Col span={18}>
              <h1>Bank Information</h1>
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              {bankData?.bank_info && (
                <div className={Styles.bankType}>
                  {bankData?.bank_info?.beneficiaryType}
                </div>
              )}
            </Col>
            <Col span={7}>
              <div className={Styles.bankInfoEditBtnContainer}>
                <Edit onClick={onOpenDrawer} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <div className={Styles.bankName}>Bank Name</div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row className={Styles.labelContainer}>BENEFICIARY NAME</Row>
              {bankData?.bank_info && (
                <Row className={Styles.bankInfoAnswerContainer}>
                  {bankData?.bank_info?.beneficiaryFullName}
                </Row>
              )}
            </Col>
            <Col span={12}>
              <div className={Styles.borderDiv}>
                <Row className={Styles.labelContainer}>BENEFICIARY COUNTRY</Row>
                <Row className={Styles.bankInfoAnswerContainer}>
                  {_.get(
                    bankData,
                    "bank_info.correspondentIFSC",
                    "Not Available"
                  )}
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row className={Styles.labelContainer}>IBAN</Row>
              {bankData?.bank_info && (
                <Row className={Styles.bankInfoAnswerContainer}>
                  {bankData?.bank_info?.beneficiaryIBAN}
                </Row>
              )}
            </Col>
            <Col span={12}>
              <div className={Styles.borderDiv}>
                <Row className={Styles.labelContainer}>ROUTING NUMBER</Row>
                <Row className={Styles.bankInfoAnswerContainer}>
                  {_.get(bankData, "bank_info.routingNumber", "Not Available")}
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row className={Styles.labelContainer}>SWIFT/BIC CODE</Row>
              <Row className={Styles.bankInfoAnswerContainer}>
                {_.get(
                  bankData,
                  "bank_info.beneficiarySwiftBicCode",
                  "Not Available"
                )}
              </Row>
            </Col>
          </Row>
          <Divider />

          {/* END OF BANK SECTION AND STARTING OF CORESPONDENT SECTION */}
          {_.get(bankData, "bank_info.beneficiaryType", false) ==
            "International Beneficiary" && (
            <>
              <Row>
                <Col span={18}>
                  <div className={Styles.bankName}>Correspondent Details</div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row className={Styles.labelContainer}>BANK NAME</Row>
                  <Row className={Styles.bankInfoAnswerContainer}>
                    {_.get(
                      bankData,
                      "bank_info.correspondentBank",
                      "Not Available"
                    )}
                  </Row>
                </Col>
                <Col span={12}>
                  <div className={Styles.borderDiv}>
                    <Row className={Styles.labelContainer}>SWIFT/BIC CODE</Row>
                    <Row className={Styles.bankInfoAnswerContainer}>
                      {_.get(
                        bankData,
                        "bank_info.correspondentSwiftBicCode",
                        "Not Available"
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Row className={Styles.labelContainer}>COUNTRY</Row>
                  {bankData && (
                    <Row className={Styles.bankInfoAnswerContainer}>
                      {_.get(bankData, "country", "Not Available")}
                    </Row>
                  )}
                </Col>
                <Col span={12}>
                  <div className={Styles.borderDiv}>
                    <Row className={Styles.labelContainer}>IFSE</Row>

                    <Row className={Styles.bankInfoAnswerContainer}>
                      {_.get(
                        bankData,
                        "bank_info.correspondentIFSC",
                        "Not Available"
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
              <Divider />
            </>
          )}

          <Row>
            <Col span={18}>
              <div className={Styles.bankName}>Contact Information</div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row className={Styles.labelContainer}>PHONE NUMBER</Row>
              {bankData?.bank_info && (
                <Row className={Styles.bankInfoAnswerContainer}>
                  {bankData?.bank_info.beneficiaryPhoneNumber}
                </Row>
              )}
            </Col>
            <Col span={12}>
              <div className={Styles.borderDiv}>
                <Row className={Styles.labelContainer}>EMAIL ADDRESS</Row>
                {bankData?.bank_info && (
                  <Row className={Styles.bankInfoAnswerContainer}>
                    {bankData?.bank_info.beneficiaryEmail}
                  </Row>
                )}
              </div>
            </Col>
          </Row>
          {bankData?.is_tax_registered && (
            <>
              <Divider />
              <Row>
                <Col span={18}>
                  <div className={Styles.bankName}>Tax Information</div>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Row className={Styles.labelContainer}>
                    TAX REGISTERED NUMBER
                  </Row>
                  <Row className={Styles.bankInfoAnswerContainer}>
                    {bankData?.tax_registered_number}
                  </Row>
                </Col>
                <Col span={12}>
                  <div className={Styles.borderDiv}>
                    <Row className={Styles.labelContainer}>
                      TAX DOCUMENT REFERENCE
                    </Row>
                    <Row className={Styles.bankInfoAnswerContainer}>
                      <a
                        href={bankData?.tax_document_referance}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </a>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {!bankData?.is_tax_registered && (
            <>
              <Divider />
              <div className={Styles.updateInfoContainer}>
                <p className={Styles.bankName}>
                  Please add or update your tax information to complete your
                  profile.
                </p>

                <div
                  className={`${Styles.bankInfoEditBtnContainerTax}`}
                  id="updateTaxInfoButton"
                  onClick={onOpenDrawer}
                >
                  <CreateOutlined onClick={onOpenDrawer} /> Update Tax
                  Information
                </div>
              </div>
            </>
          )}
        </Col>
      </Row>
      <BankInfoDrawer
        fetchData={fetchData}
        open={open}
        onClose={onCloseDrawer}
        bankInfoData={combinedBankAndTaxInfo}
      />
    </CustomProfileLayout>
  );
};
