import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import Styles from "./NotificationBanner.module.css";

const NotificationBanner = ({ onPermissionGranted, onDismiss }: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const showModal = (message: string) => {
    setModalMessage(message);
    setIsModalVisible(true);
  };

  const handleRequestPermission = async () => {
    if (Notification.permission === "default") {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("🎉 Notifications enabled! We'll keep you updated. 🚀");
        onPermissionGranted();
      } else {
        alert(
          "🙁 Permission denied. You can enable notifications later in your browser settings."
        );
        onDismiss();
      }
    } else if (Notification.permission === "denied") {
      if (
        navigator.userAgent.includes("Chrome") ||
        navigator.userAgent.includes("Brave")
      ) {
        showModal(
          "🚫 You've denied notifications! Please go to your Chrome settings and enable notifications. Then reload the page."
        );
      } else if (navigator.userAgent.includes("Firefox")) {
        showModal(
          "🚫 You've denied notifications! Please go to your Firefox settings and enable notifications. Then reload the page."
        );
      } else {
        showModal(
          "🛑 Notifications are disabled. Please go to your browser settings to enable them. 💡 Then reload the page."
        );
      }
    } else if (Notification.permission === "granted") {
      onPermissionGranted();
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    onDismiss();
  };

  return (
    <>
      <div className={Styles.banner}>
        <p className={Styles.message}>
          📢 Stay in the loop! Enable notifications to receive important updates
          and offers.
        </p>
        <div className={Styles.actions}>
          <button
            className={Styles.allowButton}
            onClick={handleRequestPermission}
          >
            Allow Notifications
          </button>
          <button className={Styles.closeButton} onClick={onDismiss}>
            &times;
          </button>
        </div>
      </div>

      <Modal open={isModalVisible} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            animation: "fadeIn 0.3s ease-in-out",
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Notification Settings
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
            {modalMessage}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default NotificationBanner;
