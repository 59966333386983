import react, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Switch,
  Upload,
  message,
  notification,
} from "antd";
import countries from "../Data/countries.json";
import bahrainCities from "../Data/cities.json";
import Styles from "./BusinessInfo.module.css";
import moment from "moment";
import { CalendarOutlined, UploadOutlined } from "@ant-design/icons";
import { _POST, getZohoUserId } from "../../../common/commonFunc";
import { BASE_URL } from "../../../constant/ApplicationRoutes";
import FileUpload from "../../../components/InputComponents/FileUpload";
import axios from "axios";
import dayjs from "dayjs";
import { DeleteOutline } from "@mui/icons-material";
import CustomRadioButton from "../components/CustomRadiobutton";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import { values } from "lodash";
import {
  addressValidator,
  noSpecialCharactersValidator,
} from "../../../utils/helpers";
import DocumentPreview from "./DocumentPreview";

const BusinessInformation = ({ step }: any) => {
  const [form] = Form.useForm();

  const [businessDoc, setBusinessDocImg] = useState<any>([]);
  console.log("🚀 ~ BusinessInformation ~ businessDoc:", businessDoc);
  const [isTCChecked, setisTCChecked] = useState(false);
  const [docsError, setDocsError] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { number: "", countryCode: "+973", isPrimary: true, type: "business" },
  ]);
  const [representativeNumber, setRepresentativeNumber] = useState({
    number: "",
    countryCode: "+973",
    isPrimary: false,
    type: "representative",
  });

  const [
    agreetooctopusbusinessagreementandprivacypolicy,
    setAgreetooctopusbusinessagreementandprivacypolicy,
  ] = useState(false);
  const [phoneType, setPhoneType] = useState("mobile");

  const handlePhoneTypeChange = (checked: any) => {
    setPhoneType(checked ? "landline" : "mobile");
  };

  const addPhoneNumber = () => {
    setPhoneNumbers([
      ...phoneNumbers,
      { number: "", countryCode: "+973", isPrimary: false, type: "business" },
    ]);
  };

  const handleNumberChange = useCallback(
    (index: any, value: any, type: any) => {
      if (type === "business") {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers[index] = { ...newPhoneNumbers[index], ...value };
        setPhoneNumbers(newPhoneNumbers);
      } else {
        setRepresentativeNumber({ ...representativeNumber, ...value });
      }
    },
    [phoneNumbers, representativeNumber]
  );

  const [landlineNumber, setLandlineNumber] = useState("");

  const handleRegisteredBusinessNumberChange = (e: any) => {
    setLandlineNumber(e.target.value);
  };

  const handleRadioChange = (index: any, type: any) => {
    if (type === "business") {
      setPhoneNumbers(
        phoneNumbers.map((pn, i) => ({
          ...pn,
          isPrimary: i === index,
        }))
      );
      setRepresentativeNumber({ ...representativeNumber, isPrimary: false });
    } else {
      setPhoneNumbers(phoneNumbers.map((pn) => ({ ...pn, isPrimary: false })));
      setRepresentativeNumber({ ...representativeNumber, isPrimary: true });
    }
  };

  const removePhoneNumber = (index: any) => {
    setPhoneNumbers(phoneNumbers.filter((_, i) => i !== index));
  };

  const zoho_user_id = getZohoUserId();
  const mail = localStorage.getItem("supplier_id");

  const email = mail;

  const { Option } = Select;

  const PhoneNumberInput = ({
    index,
    initialNumber,
    initialCountryCode,
    onNumberChange,
    type,
  }: any) => {
    const [number, setNumber] = useState(initialNumber || "");
    const [countryCode, setCountryCode] = useState(
      initialCountryCode || "+973"
    );

    const handleBlur = () => {
      onNumberChange(index, { number, countryCode }, type);
    };

    const handleNumberChange = (e: any) => {
      const newNumber = e.target.value.replace(/\D/g, "");
      setNumber(newNumber);
    };

    const handleCountryCodeChange = (newCountryCode: any) => {
      setCountryCode(newCountryCode);
    };

    return (
      <div className={Styles.NormalInput}>
        <Input.Group compact className={`${Styles.phoneNumberInput}`}>
          <Select
            value={countryCode}
            onChange={handleCountryCodeChange}
            className={Styles.selectCountryCode}
            showSearch
            optionFilterProp="children"
          >
            {countries
              .filter((country) => country.name === "Bahrain")
              .map((country) => (
                <Option key={country.code} value={country.dial_code}>
                  <img
                    src={country.flag}
                    alt={country.name}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  {country.dial_code}
                </Option>
              ))}
          </Select>
          <div className={Styles.dividerInput} />
          <Input
            style={{ width: "calc(100% - 100px)" }}
            value={number}
            onChange={handleNumberChange}
            onBlur={handleBlur}
            placeholder="Enter Number"
            className={Styles.FormInput}
          />
        </Input.Group>
      </div>
    );
  };

  // const updateBusinessDocs = (data: any) => {
  //   const index: any = Object.keys(data)[0];

  //   const value = data[index];
  //   const updatedImages = [...businessDoc];

  //   if (value) {
  //     updatedImages.push(value);
  //   } else {
  //     updatedImages.splice(index, 1);
  //   }

  //   setBusinessDocImg(updatedImages[updatedImages.length - 1]);

  //   form.setFieldsValue({
  //     commercial_Document: updatedImages[updatedImages.length - 1],
  //   });
  //   setDocsError(false);
  // };

  const updateBusinessDocs = (data: any) => {
    const index: any = Object.keys(data)[0];
    const value = data[index];
    let updatedImages = [...businessDoc];

    if (value) {
      updatedImages.push(value); // Push the new file
      updatedImages = [updatedImages[updatedImages.length - 1]]; // Keep only the last file
    } else {
      updatedImages.splice(index, 1); // Handle the case where a file might be removed
    }

    setBusinessDocImg(updatedImages); // Set the updated images array which contains only the last file

    form.setFieldsValue({
      commercial_Document: updatedImages,
    });
    setDocsError(false);
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();

    for (const key in data) {
      if (key === "commercial_Document") {
        if (Array.isArray(data[key])) {
          data[key].forEach((file: any) => {
            if (file instanceof File) {
              formData.append("commercial_Document", file);
            }
          });
        } else {
          if (data[key] instanceof File) {
            formData.append("commercial_Document", data[key]);
          }
        }
      } else if (key === "registeredBusinessNumber") {
        formData.append(key, JSON.stringify(phoneNumbers));
      } else if (key === "authorizeReprentativeNumber") {
        const fullNumber =
          representativeNumber.countryCode.replace("+", "") +
          representativeNumber.number;
        formData.append(key, fullNumber);
      } else {
        formData.append(key, data[key]);
      }
    }

    return formData;
  };

  const scroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    step(0);
  };

  const handleSubmit = async () => {
    if (businessDoc.length === 0) {
      message.error("Please upload business documents");
      setDocsError(true);
      return;
    }

    const hasEmptyNumber = phoneNumbers.some(
      (phone) => phone.number.trim() === ""
    );
    if (hasEmptyNumber) {
      message.error("Please enter all phone numbers.");
      return;
    }

    // if (representativeNumber.number === "") {
    //   message.error("Please add your representative phone number");ßßs
    //   return;
    // }
    if (agreetooctopusbusinessagreementandprivacypolicy === false) {
      message.error("Please accept terms and conditions");
      return;
    }
    try {
      const allValues = form.getFieldsValue();
      if (!allValues.ownershipStatus) {
        allValues.ownershipStatus = "not present";
      }
      const formData = await convertDataToFormData(allValues);

      let keysToRemove: any = [];
      formData.forEach((value, key) => {
        if (/^registeredBusinessNumber\d+/.test(key)) {
          keysToRemove.push(key);
        }
      });

      keysToRemove.forEach((key: any) => formData.delete(key));

      const response: any = await axios.post(
        `${BASE_URL}uploadBusinessInfo/${email}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        step(1);
        localStorage.setItem(
          "supplier_form_step",
          JSON.stringify(response?.data?.data?.supplier_form_step)
        );
        localStorage.setItem(
          "business_information",
          JSON.stringify(response?.data?.data?.business_info)
        );
      } else {
        message.error(response?.data?.message || response?.message);
      }
    } catch (error) {
      console.error("API error:", error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={() => {
        handleSubmit();
      }}
      initialValues={{
        businessLocation: countries[0].name,
        country: countries[0].name,
        commercial_Document: "",
      }}
      className={Styles.form}
    >
      <h1>Business Information</h1>
      <Col xs={24} xl={12} xxl={12} span={12}>
        <Form.Item
          name="businessLocation"
          label="Business Location"
          rules={[{ required: true }]}
        >
          <div className={Styles.selectInput}>
            <Select
              placeholder="Select your business location"
              className={Styles.select}
              defaultValue={countries[0].name}
              showSearch
              optionFilterProp="value"
              filterOption={(input, option: any) =>
                option!.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) =>
                form.setFieldsValue({ businessLocation: value })
              }
            >
              {countries
                .filter((country) => country.name === "Bahrain")
                .map((country) => (
                  <Option
                    key={country.code}
                    value={country.name}
                    className={Styles.option}
                  >
                    <img
                      src={country.flag}
                      alt={country.name}
                      style={{ width: "20px", marginRight: "10px" }}
                      className={Styles.flagIcon}
                    />
                    {country.name}
                  </Option>
                ))}
            </Select>
          </div>
          <p></p>
        </Form.Item>
      </Col>
      <Divider className={Styles.divider} />
      <h2>Commercial details</h2>

      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Commercial Registration No"
              name="commercialRegistrationNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your registration number!",
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <Input
                  placeholder="Enter registration number"
                  className={Styles.FormInput}
                />
              </div>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Expiry Date"
              name="expiryDate"
              hasFeedback
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please Enter your registration Expiery Date!",
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <CalendarOutlined className={Styles.mailIcon} />
                <DatePicker
                  showToday={false}
                  onChange={(date, dateString) => {
                    form.setFieldsValue({ expiryDate: dateString });
                  }}
                  placeholder="Select date"
                  className={Styles.FormInput}
                  disabledDate={(date) => {
                    const currentDate = dayjs(new Date());
                    return date && date.isBefore(currentDate);
                  }}
                />
              </div>
            </Form.Item>
          </Col>
        </div>
        <div className={Styles.innerDivlayout}>
          <Form.Item
            name="commercial_Document"
            label={
              <span>
                <span style={{ color: "red" }}>*</span> Upload your Commercial
                Document
              </span>
            }
          >
            <FileUpload
              title1="Upload your Commercial Document"
              description="PDF, JPG, PNG file with max size of 10mb."
              setData={updateBusinessDocs}
              name="commercial_Document"
              uploadMultiple={false}
              alllowAll
              checkSensitiveContent={false}
            />
            {docsError && (
              <p style={{ color: "red" }}>* Please upload business documents</p>
            )}
          </Form.Item>
        </div>
      </div>
      <Divider />
      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Business Name"
              name="businessName"
              rules={[
                {
                  required: true,
                  message: "Please enter your business name!",
                },
                {
                  validator: noSpecialCharactersValidator(
                    "No special characters are allowed!"
                  ),
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <Input
                  placeholder="Enter your business name"
                  className={Styles.FormInput}
                />
              </div>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Ownership Status"
              name="ownershipStatus"
              hasFeedback
            >
              <div className={Styles.selectInput}>
                <Select
                  className={Styles.select}
                  defaultActiveFirstOption={true}
                  placeholder="Select Ownership Type!"
                  onChange={(value) =>
                    form.setFieldValue("ownershipStatus", value)
                  }
                  allowClear
                >
                  <Select.Option
                    key="sole_proprietorship"
                    value="sole_proprietorship"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Sole Proprietorship"}
                  </Select.Option>
                  <Select.Option
                    key="general_partnership"
                    value="general_partnership"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"General Partnership"}
                  </Select.Option>
                  <Select.Option
                    key="limited_partnership"
                    value="limited_partnership"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Limited Partnership"}
                  </Select.Option>
                  <Select.Option
                    key="wll"
                    value="wll"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Limited Liability Company (WLL)"}
                  </Select.Option>
                  <Select.Option
                    key="public_shareholding"
                    value="public_shareholding"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Public Shareholding Company"}
                  </Select.Option>
                  <Select.Option
                    key="closed_shareholding"
                    value="closed_shareholding"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Closed Shareholding Company"}
                  </Select.Option>
                  <Select.Option
                    key="single_person"
                    value="single_person"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Single Person Company"}
                  </Select.Option>
                  <Select.Option
                    key="foreign_branch"
                    value="foreign_branch"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Branch of a Foreign Company"}
                  </Select.Option>
                  <Select.Option
                    key="representative_office"
                    value="representative_office"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Representative Office"}
                  </Select.Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
        </div>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Business Type"
              name="businessType"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please select your Business Type!",
                },
              ]}
            >
              <div className={Styles.selectInput}>
                <Select
                  className={Styles.select}
                  defaultActiveFirstOption={true}
                  placeholder="Select Business Type"
                  onChange={(value) => {
                    form.setFieldValue("businessType", value);
                    if (
                      value === "virtual_establishment" ||
                      value === "individual_establishment"
                    ) {
                      localStorage.setItem(
                        "businessType",
                        "virtual_establishment"
                      );
                    } else {
                      localStorage.removeItem("businessType");
                    }
                  }}
                >
                  <Select.Option
                    key="bsc_public"
                    value="bsc_public"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Public Shareholding Company (BSC)"}
                  </Select.Option>
                  <Select.Option
                    key="bsc_closed"
                    value="bsc_closed"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Closed Shareholding Company (BSC Closed)"}
                  </Select.Option>
                  <Select.Option
                    key="wll"
                    value="wll"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Limited Liability Company (W.L.L)"}
                  </Select.Option>
                  <Select.Option
                    key="non_profit"
                    value="non_profit"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Non Profit Company (N.P.C W.L.L)"}
                  </Select.Option>
                  <Select.Option
                    key="partnership"
                    value="partnership"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Partnership Company"}
                  </Select.Option>
                  <Select.Option
                    key="simple_commandite"
                    value="simple_commandite"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Simple Commandite Company"}
                  </Select.Option>
                  <Select.Option
                    key="commandite_shares"
                    value="commandite_shares"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Commandite by Shares Company"}
                  </Select.Option>
                  <Select.Option
                    key="foreign_branch"
                    value="foreign_branch"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Foreign Branch/Representative Office"}
                  </Select.Option>
                  <Select.Option
                    key="individual_establishment"
                    value="individual_establishment"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Individual Establishment (Traditional)"}
                  </Select.Option>
                  <Select.Option
                    key="virtual_establishment"
                    value="virtual_establishment"
                    optionFilterProp="children"
                    className={Styles.option}
                  >
                    &nbsp;&nbsp; {"Individual Establishment (Virtual)"}
                  </Select.Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
        </div>
      </div>

      <h2>Registered business address</h2>

      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Address Line 01"
              name="businessAddressLine1"
              rules={[
                {
                  required: true,
                  message: "Please enter your Address Line 01!",
                },
                {
                  validator: addressValidator("Invalid characters in address!"),
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <Input
                  placeholder="Enter Address Line 01"
                  className={Styles.FormInput}
                />
              </div>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please select your City!",
                },
              ]}
            >
              <div className={Styles.selectInput}>
                <Select
                  placeholder="Select City"
                  className={Styles.select}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => form.setFieldsValue({ city: value })}
                >
                  {bahrainCities.map((city) => (
                    <Option
                      key={city.id}
                      value={city.name}
                      className={Styles.option}
                    >
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </div>
        <div className={Styles.innerDivlayout}>
          <Col>
            <Form.Item
              label="Address Line 02"
              name="businessAddressLine2"
              rules={[
                {
                  required: true,
                  message: "Please enter your Address Line 02!",
                },
                {
                  validator: addressValidator("Invalid characters in address!"),
                },
              ]}
            >
              <div className={Styles.NormalInput}>
                <Input
                  placeholder="Enter Address Line 02"
                  className={Styles.FormInput}
                />
              </div>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true }]}
            >
              <div className={Styles.selectInput}>
                <Select
                  placeholder="Select your business country"
                  className={Styles.select}
                  defaultValue={countries[0].name}
                  showSearch
                  optionFilterProp="value"
                  filterOption={(input, option: any) =>
                    option!.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(value) => form.setFieldsValue({ country: value })}
                >
                  {countries
                    .filter((country) => country.name === "Bahrain")
                    .map((country) => (
                      <Option
                        key={country.code}
                        value={country.name}
                        className={Styles.option}
                      >
                        <img
                          src={country.flag}
                          alt={country.name}
                          style={{ width: "20px", marginRight: "10px" }}
                          className={Styles.flagIcon}
                        />
                        {country.name}
                      </Option>
                    ))}
                </Select>
              </div>
              <p></p>
            </Form.Item>
          </Col>
        </div>
      </div>

      <h2>(Mobile/Landline)</h2>

      <div className={Styles.innerDiv}>
        <div className={Styles.phoneNumbersContainer}>
          <div className={Styles.phoneNumberBlock}>
            <Col>
              <Form.Item
                label={
                  <div className={Styles.labelContainer}>
                    <p>
                      <span style={{ color: "red" }}>*</span>{" "}
                      {phoneType === "landline"
                        ? "Registered Landline Number"
                        : "Registered Mobile Number"}
                    </p>
                    <Switch
                      checked={phoneType === "landline"}
                      onChange={handlePhoneTypeChange}
                      size="default"
                      unCheckedChildren="Landline"
                    />
                  </div>
                }
                name="registeredBusinessNumber"
              >
                <PhoneNumberInput
                  index={0}
                  initialNumber={phoneNumbers[0].number}
                  initialCountryCode={phoneNumbers[0].countryCode}
                  onNumberChange={handleNumberChange}
                  type="business"
                />

                <CustomRadioButton
                  checked={phoneNumbers[0].isPrimary}
                  onChange={() => handleRadioChange(0, "business")}
                  label="Primary"
                />
              </Form.Item>
            </Col>
          </div>

          <div className={Styles.representativeNumberBlock}>
            <Col>
              <Form.Item
                label="Authorize Representative Number"
                name="authorizeReprentativeNumber"
              >
                <PhoneNumberInput
                  index={-1}
                  initialNumber={representativeNumber.number}
                  initialCountryCode={representativeNumber.countryCode}
                  onNumberChange={handleNumberChange}
                  type="representative"
                />
                <CustomRadioButton
                  checked={representativeNumber.isPrimary}
                  onChange={() => handleRadioChange(-1, "representative")}
                  label="Primary"
                />
              </Form.Item>
            </Col>
          </div>

          {phoneNumbers.slice(1).map((phoneNumber, index) => (
            <div
              key={`additional-${index}`}
              className={Styles.phoneNumberBlock}
            >
              <Col>
                <Form.Item
                  label={`Additional Number ${index + 1}`}
                  name={`registeredBusinessNumber${index + 1}`}
                  required={false}
                >
                  <PhoneNumberInput
                    index={index + 1}
                    initialNumber={phoneNumber.number}
                    initialCountryCode={phoneNumber.countryCode}
                    onNumberChange={handleNumberChange}
                    type="business"
                  />
                  <div className={Styles.numberActions}>
                    <CustomRadioButton
                      checked={phoneNumber.isPrimary}
                      onChange={() => handleRadioChange(index + 1, "business")}
                      label="Primary"
                    />
                    <Button
                      onClick={() => removePhoneNumber(index + 1)}
                      className={Styles.numberRemove}
                    >
                      <DeleteOutline />
                    </Button>
                  </div>
                </Form.Item>
              </Col>
            </div>
          ))}
        </div>
      </div>

      <div
        className={Styles.innerDiv}
        style={{
          marginTop: "10px",
        }}
      >
        <div className={Styles.innerDivlayout}>
          <Button
            disabled={phoneNumbers.length >= 3}
            className={Styles.numberAdd}
            onClick={addPhoneNumber}
          >
            {phoneNumbers.length >= 3
              ? "Maximum 2 Additional Numbers"
              : "+ Add Phone Number"}
          </Button>
        </div>
      </div>

      <div className={Styles.innerDiv}>
        <div className={Styles.innerTermsDiv}>
          <CustomRadioButton
            checked={agreetooctopusbusinessagreementandprivacypolicy}
            onChange={() => {
              setAgreetooctopusbusinessagreementandprivacypolicy(true);
              setisTCChecked(true);
            }}
            label="I confirm my business location and type are correct, and I understand that this information can not be changed letter."
            secondaryLabel="By clicking on ‘Agree and continue, you agree to the Octopus business agreement and privacy notice."
          />
          {/* {agreetooctopusbusinessagreementandprivacypolicy && (
            <Modal
              centered
              open={isTCChecked}
              onOk={() => {
                setAgreetooctopusbusinessagreementandprivacypolicy(true);
                setisTCChecked(false);
              }}
              onCancel={() => {
                setAgreetooctopusbusinessagreementandprivacypolicy(false);
                setisTCChecked(false);
              }}
              width={1000}
              className={Styles.modalTermsConditionsContainerMain}
              okText="Ok"
            >
              <iframe
                src="https://drive.google.com/file/d/1L6crMNu3ah4u6EDnHJD2_ySCFV9dHsHR/preview"
                width="100%"
                height="500px"
              ></iframe>
            </Modal>
          )} */}
        </div>
      </div>
      <div className={Styles.innerDiv}>
        <div className={Styles.innerDivlayout}>
          <Button className={Styles.continue} type="primary" htmlType="submit">
            Continue
          </Button>
        </div>
      </div>
      <DocumentPreview document={businessDoc[0] || null} />
    </Form>
  );
};

export default BusinessInformation;
