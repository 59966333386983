import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, notification, message } from "antd";
import styles from "../components/PopupForm.module.css";
import Cross from "../../../assests/customIcons/Cross";
import Caution from "../../../assests/delete_icon.png"
import { _GET, _POST } from "../../../common/commonFunc";
import { showSuccessCustomModal } from "../../../common/CustomModal/CustomModal";
import { Typography } from "@mui/material";

type PopupProps = {
  isVisible: boolean;
  onClose: () => void;
  id?: string;
  quantity: number;
};

const ReturnAcceptedProduct: React.FC<PopupProps> = ({
  isVisible,
  onClose,
  id,
  quantity
}) => {
  const [form] = Form.useForm();

  const onSubmit = async () => {
    try {
      await form.validateFields().then(async (values) => {
        console.log("Quantity and entered quantity : ", quantity, values.return_qty)

        if (values.return_qty > quantity && quantity !== undefined) {
          notification.error({
            message: "Error",
            description: "Please add valid quantity.",
          });
          return;

        }

        const payload = {
          returned_quantity: values.return_qty,
          assigned_inventory_id: id
        };
        console.log("Payload for return is : ", payload)
        const returnProductResponse = await _POST(
          "supplier/returnAssignedInventory",
          payload,
          true
        );

        if (returnProductResponse.data === true && returnProductResponse.statusCode === 200) {
          showSuccessCustomModal({
            title: "Product Returned Successfully",
            smallText: "Your product has been returned successfully.",
            buttonText: "Continue",
          });
          form.resetFields();
          closeModal();
        } else {
          notification.error({
            message: "Return Product Failed",
            description: "An error occurred while returning the product.",
          });
        }
      }).catch((error) => {
        console.error("Form validation error:", error);
        notification.error({
          message: "Validation Error",
          description: "Please fill in the required fields.",
        })
      });
    } catch (error) {
      console.error("Error during onSubmit:", error);
      notification.error({
        message: "Submission Error",
        description: "An error occurred during submission.",
      });
      form.resetFields();
      closeModal();
    }
  };

  const closeModal = () => {
    console.log("Before reset:", form.getFieldsValue());
    form.resetFields();
    console.log("After reset:", form.getFieldsValue());
    onClose();
  }

  return (
    <Modal
      open={isVisible}
      onCancel={closeModal}
      footer={null}
      className={styles.modal}
      centered
    >
      <Button
        className={`${styles.closeIcon} elevatedElement iconBtn`}
        onClick={onClose}
      >
        <Cross />
      </Button>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <img src={Caution} alt="!" style={{ height: "75px", width: "75px" }} />
        <h2 style={{ marginBottom: 0 }}>
          Are you sure you want </h2>
        <h2 style={{ marginTop: 0 }}>
          to return the products?
        </h2>
        <Typography variant="caption">Assigned Quantity : {quantity}</Typography>
      </div>
      <Form
        form={form}
        layout="vertical"
        name="reasonForm"
        className={styles.form}
      >

        <Form.Item
          name="return_qty"
          rules={[{ required: true, message: "Please enter the Quantity!" }]}
        >
          <Input
            placeholder="Enter the quantity to be returned..."
            className={styles.input}

          />
        </Form.Item>
        <div className={styles.footerDiv}>


          <Button
            key="submit"
            type="primary"
            onClick={onSubmit}
            className={styles.submitButton}
          >
            Return
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReturnAcceptedProduct;
