import React, { useState } from "react";
import { getRole } from "../../commonFunc";
import moment from "moment";
import styles from "./BreakCard.module.css";

const BreakCard = React.memo(({ breakData }: any) => {
  const userRole = getRole();
  const [isProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );

  const duration = Math.abs(breakData.end_time - breakData.start_time) * 1000;
  const tempTime = moment.duration(duration);
  const durationText = tempTime.hours() + "h " + tempTime.minutes() + "m";

  return (
    <div className={styles.breakCard}>
      <div className={styles.breakDuration}>
        <p>{durationText}</p>
      </div>
      <p className={styles.breakTitle}>It's break time</p>
    </div>
  );
});

export default BreakCard;
