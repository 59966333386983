import { FETCH_CART_ITEMS, ADD_TO_CART, REMOVE_FROM_CART, CartItem ,RESET_CART_FETCHED} from "../actions/cartActions";

interface CartState {
  items: CartItem[];
  isCartFetched: boolean;

}

const initialState: CartState = {
  items: [],
  isCartFetched: false,
};

const cartReducer = (state = initialState, action: any): CartState => {
  switch (action.type) {
    case FETCH_CART_ITEMS:
      return { ...state, items: action.payload, isCartFetched: true };
    case ADD_TO_CART:
      return { ...state, items: [...state.items, action.payload], isCartFetched: false };
    case REMOVE_FROM_CART:
      return { ...state, items: state.items.filter(item => item.id !== action.payload), isCartFetched: false };
    case RESET_CART_FETCHED:
      return { ...state, isCartFetched: false };
    default:
      return state;
  }
};


export default cartReducer;
