import React from "react";
import Styles from "../Profile.module.css";
// import ProfileBox from "./ProfileBox";
import { useState } from "react";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import backImg from "../../../assests/Back.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImgIcon from "../../../assests/RandomUser.jpeg";
import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Row,
  Space,
  notification,
} from "antd";
import { useHistory } from "react-router";
import { ShareAltOutlined } from "@ant-design/icons";
import _ from "lodash";
import { _GET, _POST } from "../../../common/commonFunc";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import Cross from "../../../assests/customIcons/Cross";
import { FollowingDrawer } from "./FollowingDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { Box, IconButton, Typography, styled } from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import SensorsIcon from "@mui/icons-material/Sensors";

const OctopusSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundImage:
          "linear-gradient(106.87deg, #8800FF 0.56%, #00B3FF 75.66%);",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CustomProfileLayout: any = ({ children }: any) => {
  const history = useHistory<any>();
  const [userDetails, setUserDetails] = useState<any>([]);
  const inputFileRef = React.useRef<any>();
  const [lastBreadItem, setLastBreadItem] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [profilePic, setProfilePic] = useState<any>(false);
  const [followersData, setFollowersData] = useState<any>([]);
  const [showFullText, setShowFullText] = useState(false);
  if (userDetails && userDetails?.user_bio) {
    var text = _.get(userDetails, "user_bio", "") || "";
    var words = text.split(" ");
    var truncatedText = words.slice(0, 15).join(" ");
  }
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onProfilePicChangeHandler = async (event: any) => {
    setProfilePic(event.target.files[0]);
    const profilePicSem = event.target.files[0];

    if (profilePicSem) {
      let formData = new FormData();
      formData.append("profile_picture", profilePicSem);
      const data = JSON.parse(localStorage.getItem("userInfo") || "");

      const res = await _POST(
        `userProfile/addProfilePicture/${data.id}`,
        formData,
        true
      );
      console.log("TYPE", typeof res);
      if (typeof res === "string") {
        notification.open({
          type: "error",
          message: "Invalid File type of Profile image",
          description:
            "Only images  are allowed for profile pic (PNG and JPG Only)",
        });
      }
      console.log("response is ", res);
      setProfilePic(false);
    } else {
      alert("Profile pic not found ");
    }
    await onPageHandler();
  };
  const changePicHandler = async (files: any) => {
    inputFileRef.current.click();
  };

  const onPageHandler = async () => {
    try {
      const res = await _GET("userProfile/profile", true);
      const mainData = _.get(res, "data", []);
      setUserDetails(mainData);
    } catch (err: any) {
      console.log("err", err);
    }
  };

  // const userDetails = JSON.parse(localStorage.getItem("userInfo") || "");
  React.useEffect(() => {
    onPageHandler();
  }, []);

  const getLastBreadcrumbHandler = () => {
    switch (window.location.pathname) {
      case APPLICATION_ROUTES.PERSONAL_INFO:
        setLastBreadItem(
          <Breadcrumb.Item>Personal Information</Breadcrumb.Item>
        );
        break;
      case APPLICATION_ROUTES.PREFERENCES:
        setLastBreadItem(<Breadcrumb.Item>Preferences</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.PRICAY_AND_POLICY:
        setLastBreadItem(<Breadcrumb.Item>Privacy Policy</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.BANK_INFORMATION:
        setLastBreadItem(<Breadcrumb.Item>Bank Info</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.MY_DOCUMENTS:
        setLastBreadItem(<Breadcrumb.Item>My Documents</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.MY_SCHEDULE:
        setLastBreadItem(<Breadcrumb.Item>My Schedule</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.PROFILE_ADDRESS:
        setLastBreadItem(<Breadcrumb.Item>Address</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.PAYMENT_METHODS:
        setLastBreadItem(<Breadcrumb.Item>Payment Methods</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.MANAGE_SOCIAL_MEDIA:
        setLastBreadItem(
          <Breadcrumb.Item>Manage Social Media</Breadcrumb.Item>
        );
        break;
      case APPLICATION_ROUTES.AFFILIATE_PRODUCTS:
        setLastBreadItem(<Breadcrumb.Item>Affiliate Products</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.HELP_AND_SUPPORT:
        setLastBreadItem(<Breadcrumb.Item>Help and Support</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.AFFILIATE_PRODUCTS:
        setLastBreadItem(<Breadcrumb.Item>Affiliate Products</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.COUPONS:
        setLastBreadItem(<Breadcrumb.Item>Coupons</Breadcrumb.Item>);
        break;
      case APPLICATION_ROUTES.MANAGE_NOTIFICATIONS:
        setLastBreadItem(
          <Breadcrumb.Item>Manage Notifications</Breadcrumb.Item>
        );
        break;
      default:
        setLastBreadItem(<></>);
        break;
    }
  };

  const getStringify = (obj: any, replacer = null, space = 2) => {
    const cache = new Set();
    const result = JSON.stringify(
      obj,
      (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (cache.has(value)) {
            return; // Circular reference found, discard key
          }
          cache.add(value);
        }
        return value;
      },
      space
    );
    cache.clear();
    return result;
  };

  const onCollaboration = async (event: any) => {
    console.log("Event is : ", event.target.checked);
    const payload = {
      open_for_collaboration: event.target.checked,
    };
    try {
      const postData = await _POST("userProfile/profile", payload, true);
      const userDataToStore = { ...postData.data };
      delete userDataToStore.password;
      localStorage.setItem("userInfo", getStringify(userDataToStore));
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const onContinueReadingHandler = () => {
    setShowFullText(false);
  };

  const getFollowersData = async () => {
    if (UserRole == "freelancer") {
      const response = await _GET("userProfile/followers", true, false);
      console.log("RESPONSE DATA IS", response);
      if (response && response?.data) {
        setFollowersData(response.data);
      }
    } else {
      // const response = await _GET("userProfile/followings", true, false);
      // console.log("RESPONSE DATA IS", response);
      // if (response && response?.data) {
      //   setFollowersData(response?.data);
      // }
    }
  };

  React.useEffect(() => {
    console.log("FOLLOWERS DATA IS", followersData);
    getFollowersData(); // Corrected: Call the function by adding parentheses
  }, []);

  React.useEffect(() => {
    getLastBreadcrumbHandler();
  }, [history]);
  const UserRole = _.get(userDetails, "role", "");
  console.log("USERROLE IS ", UserRole);
  return (
    <>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.breadcrumbContainer}>
            <Breadcrumb separator=">>">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={APPLICATION_ROUTES.PROFILE}>Profile</Link>
              </Breadcrumb.Item>
              {lastBreadItem}
            </Breadcrumb>
          </div>

          <div>
            <IconButton
              className={Styles.iconButtonArrow}
              onClick={() => history.goBack()}
            >
              <ArrowBackIcon sx={{ color: "black" }} />
            </IconButton>
          </div>
          <Row gutter={2} className={Styles.container}>
            <Col span={16}>{children}</Col>
            <Col span={6}>
              <div className={`${Styles.profileContent} `}>
                <div className={Styles.profileHeader}>
                  <div className={Styles.profileImageWrapper}>
                    <img
                      src={_.get(userDetails, "profile_pic", ImgIcon)}
                      // src={userDetails.profile_pic}
                      alt="Profile"
                      className={Styles.profileImage}
                    />
                  </div>
                  <div
                    className={Styles.editIconWrapper}
                    onClick={changePicHandler}
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      className={Styles.editIcon}
                    />
                  </div>
                  <input
                    ref={inputFileRef}
                    style={{ display: "none" }}
                    type="file"
                    onChange={onProfilePicChangeHandler}
                  />

                  <div>
                    <h1 className={Styles.profileName}>
                      {userDetails && userDetails?.display_name
                        ? userDetails?.display_name
                        : _.get(userDetails, "first_name", "")}
                    </h1>
                    <p className={Styles.profileDescription}>
                      <div>
                        {words?.length > 14 ? (
                          <div>
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: truncatedText,
                                }}
                              />
                              <span
                                onClick={() => setShowFullText(true)}
                                style={{ marginTop: "5px" }}
                              >
                                <b
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "15px",
                                    textDecoration: "underline",
                                    color: "#30263e",
                                  }}
                                >
                                  <br />
                                  Continue reading
                                </b>
                              </span>
                            </>
                          </div>
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: text }} />
                        )}
                      </div>
                    </p>
                  </div>
                  <br />
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "15px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Button
                    shape="round"
                    type="primary"
                    style={{ background: "#4E4DFF", width: "140px" }}
                    onClick={() => setOpen(true)}
                  >
                    {UserRole === "freelancer"
                      ? `${followersData.length} Follower`
                      : `${followersData.length} Following`}
                  </Button>
                </div>
                {/* @ts-ignore */}
                <br />

                {/* @ts-ignore */}

                {/* <ShareAltOutlined style={{ fontSize: "40px" }} /> */}
              </div>

              {_.get(userDetails, "role", "") === "freelancer" && (
                <Box className={Styles.collaboration}>
                  <Box className={Styles.innerBox}>
                    <SensorsIcon
                      color="disabled"
                      style={{ marginRight: "3px" }}
                    />
                    <span>Open For Collaboration</span>
                  </Box>

                  <Box>
                    <OctopusSwitch
                      defaultChecked={_.get(
                        userDetails,
                        "open_for_collaboration",
                        false
                      )}
                      onChange={onCollaboration}
                    />
                  </Box>
                </Box>
              )}
            </Col>
          </Row>
        </div>
        <Drawer
          title="User full bio"
          placement="right"
          closable={false}
          key="right"
          width={"550px"}
          extra={
            <Space style={{ zIndex: 100 }}>
              <Button
                onClick={onContinueReadingHandler}
                className="elevatedElement iconBtn"
              >
                <Cross />
              </Button>
            </Space>
          }
          open={showFullText}
          onClose={onContinueReadingHandler}
        >
          {" "}
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </Drawer>
        <FollowingDrawer
          onClose={onClose}
          open={open}
          followersData={followersData || []}
          userRole={UserRole}
        />
      </div>
    </>
  );
};

export default CustomProfileLayout;
