import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import EventCard from "../../common/Cards/EventCard/EventCard";
import BreakCard from "../../common/Cards/EventCard/BreakCard";
import "./EventCalendar.css";

const EventCalendar = ({
  isProvider,
  slotsData,
  selectedDate,
  getUpdatedCalendarItems,
}: any) => {
  const [events, setEvents] = useState<any[]>([]);
  const calendarRef = useRef<any>(null);

  const convertForCalendar = () => {
    const eventsData: any[] = [];

    if (isProvider && slotsData) {
      Object.keys(slotsData).forEach((key: any) => {
        if (
          (slotsData[key]?.start_time || slotsData[key]?.length > 0) &&
          key !== "working_hrs"
        ) {
          if (key === "break_hrs") {
            eventsData.push({
              title: "Break",
              start: new Date(slotsData[key].start_time * 1000),
              end: new Date(slotsData[key].end_time * 1000),
              backgroundColor: "#ddd",
              extendedProps: { index: eventsData.length, type: "break_hrs" },
            });
          } else if (key === "slots_booked") {
            slotsData[key].forEach((bookedSlot: any) => {
              eventsData.push({
                title: bookedSlot.service_name || "Booked Slot",
                start: new Date(bookedSlot.start_time * 1000),
                end: new Date(bookedSlot.end_time * 1000),
                backgroundColor:
                  bookedSlot.type === "SERVICE_SLOT" ? "#fafafa" : "#eee",
                extendedProps: { index: bookedSlot.id, type: "slots_booked" },
              });
            });
          }
        }
      });
    } else if (Array.isArray(slotsData) && slotsData.length > 0) {
      slotsData.forEach((booking: any) => {
        const startTime =
          booking.service_start_time || booking.event_start_time;
        const endTime = booking.service_end_time || booking.event_end_time;

        eventsData.push({
          title: booking.service_name || booking.event_name || "Unknown Slot",
          start: new Date(startTime * 1000),
          end: new Date(endTime * 1000),
          backgroundColor: "#fafafa",
          extendedProps: { index: booking.id, type: booking.type || "unknown" },
        });
      });
    }

    setEvents(eventsData);
  };

  const renderEventContent = (data: any) => {
    console.log("➡️   ~ renderEventContent ~ data:", data);
    const { index, type } = data.event.extendedProps;

    if (type === "break_hrs") {
      return <BreakCard breakData={slotsData.break_hrs} />;
    } else if (type === "slots_booked") {
      let booking = null;
      if (Array.isArray(slotsData.slots_booked)) {
        booking = slotsData.slots_booked.find((slot: any) => slot.id === index);
      }

      if (booking) {
        return <EventCard booking={booking} isReserved={false} />;
      }
    } else if (type === "EVENT_SLOT") {
      let eventBooking = null;
      if (Array.isArray(slotsData)) {
        eventBooking = slotsData.find((event: any) => event.id === index);
      }

      if (eventBooking) {
        return <EventCard booking={eventBooking} isReserved={false} />;
      }
    } else {
      let bookingOrEvent = null;
      if (Array.isArray(slotsData)) {
        bookingOrEvent = slotsData.find((item: any) => item.id === index);
      }

      if (bookingOrEvent) {
        return <EventCard booking={bookingOrEvent} isReserved={false} />;
      }
    }

    return <div>No booking data available</div>;
  };

  useEffect(() => {
    convertForCalendar();
  }, [slotsData]);

  useEffect(() => {
    if (selectedDate && calendarRef.current) {
      const current: any = calendarRef.current;
      current.calendar.gotoDate(
        new Date(parseInt(selectedDate.format("X")) * 1000)
      );
      getUpdatedCalendarItems(current.calendar.getDate());
    }
  }, [selectedDate]);

  return (
    <FullCalendar
      nowIndicator
      editable={false}
      views={{
        dayGrid: { selectable: false },
        timeGrid: { selectable: true },
        dayGridMonth: { selectable: false },
      }}
      ref={calendarRef}
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
      initialView="timeGridDay"
      events={events}
      locale="en"
      titleFormat={{ year: "numeric", month: "short", day: "numeric" }}
      allDaySlot={false}
      themeSystem="Cosmo"
      eventContent={renderEventContent}
      headerToolbar={{ left: "", center: "", right: "" }}
    />
  );
};

export default EventCalendar;
