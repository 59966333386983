import React, { useEffect, useState } from "react";
import { Row, Col, Button, notification } from "antd";
import {
  EditOutlined,
  SaveOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import CustomProfileLayout from "../CustomProfileLayout/CustomProfileLayout";
import Card from "../../../components/Card/Card";
import { _GET, _POST } from "../../../common/commonFunc";
import { API_BASE_URL } from "../../../constant/ApplicationRoutes";

const Preferences = () => {
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [visibleCategories, setVisibleCategories] = useState(5);

  const fetchCategoriesAndPreferences = async () => {
    try {
      const categoriesResponse = await fetch(
        `${API_BASE_URL}getAllCategories`
      );
      const categoriesResult = await categoriesResponse.json();
      if (categoriesResult.status) {
        const filteredCategories = categoriesResult.data.filter(
          (category: any) => category.category_logo_image !== "NULL"
        );
        setCategoriesData(filteredCategories);
      } else {
        notification.error({ message: "Failed to fetch categories" });
      }

      const preferencesResponse = await _GET("userProfile/preferences", true);
      const mainData = preferencesResponse?.data?.[0]?.user_categories || "[]";
      const categories = JSON.parse(
        mainData.replace("{", "[").replace("}", "]")
      );
      setSelectedCategories(categories);
    } catch {
      notification.error({
        message: "Error",
        description: "Failed to load categories or preferences.",
      });
    }
  };

  const updatePreferences = async () => {
    try {
      const response = await _POST(
        "userProfile/preferences",
        { categories: selectedCategories },
        true
      );
      if (response.status) {
        notification.success({
          message: "Preferences updated successfully!",
        });
        setIsEdited(false);
        setIsEditMode(false);
      } else {
        throw new Error("Failed to update preferences");
      }
    } catch {
      notification.error({
        message: "Error",
        description: "Failed to update preferences. Please try again.",
      });
    }
  };

  const onCategoryChange = (category: string) => {
    if (!isEditMode) return;

    const updatedCategories = [...selectedCategories];
    const index = updatedCategories.indexOf(category);

    setIsEdited(true);
    if (index !== -1) {
      updatedCategories.splice(index, 1);
    } else {
      updatedCategories.push(category);
    }
    setSelectedCategories(updatedCategories);
  };

  const loadMoreCategories = () => {
    setVisibleCategories((prev) => Math.min(prev + 5, categoriesData.length));
  };

  const loadLessCategories = () => {
    setVisibleCategories((prev) => Math.max(prev - 5, 5));
  };

  useEffect(() => {
    fetchCategoriesAndPreferences();
  }, []);

  return (
    <CustomProfileLayout>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <h1>Preferences</h1>
        <div>
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => setIsEditMode(!isEditMode)}
            style={{
              marginRight: "10px",
              borderRadius: "30px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              color: "#31263e",
              fontWeight: "bold",
            }}
          >
            {isEditMode ? "Cancel" : "Edit"}
          </Button>
          {isEditMode && (
            <Button
              type="primary"
              icon={<SaveOutlined />}
              onClick={updatePreferences}
              style={{
                borderRadius: "30px",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#31263e",
                fontWeight: "bold",
              }}
            >
              Save
            </Button>
          )}
        </div>
      </Row>
      <div className="preferences-container">
        <Row
          gutter={[24, 24]}
          justify="center"
          align="middle"
          style={{
            gap: "20px",
          }}
        >
          {categoriesData
            .slice(0, visibleCategories)
            .map((item: any, i: any) => (
              <Col
                key={i}
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  logo={item.category_logo_image}
                  title={item.category_name}
                  defaultChecked={selectedCategories.includes(
                    item.category_name
                  )}
                  onSelect={onCategoryChange}
                  isEditable={isEditMode}
                />
              </Col>
            ))}
        </Row>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {visibleCategories < categoriesData.length && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={loadMoreCategories}
              style={{
                marginRight: "10px",
                borderRadius: "30px",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#31263e",
                fontWeight: "bold",
              }}
            >
              Load More
            </Button>
          )}
          {visibleCategories > 5 && (
            <Button
              type="primary"
              icon={<MinusOutlined />}
              onClick={loadLessCategories}
              style={{
                borderRadius: "30px",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#31263e",
                fontWeight: "bold",
              }}
            >
              Load Less
            </Button>
          )}
        </div>
      </div>
    </CustomProfileLayout>
  );
};

export default Preferences;
