import React, { useState, useEffect } from "react";
import { notification } from "antd";
import Styles from "./Service.module.css";
import { getShortenText } from "../../../utils/helpers";
import { _GET, getRole } from "../../../common/commonFunc";
import DashboardNavBar from "../../../common/DashboardNavBar/DashboardNavBar";
import CustomButton from "../../../components/Button/Button";
import ServiceCard from "../../../common/Cards/ServiceCard/ServiceCard";
import servicesList from "../../../data/servicesList";
import AddServiceDrawer from "../../../common/AddServiceDrawer/AddServiceDrawer";
import AddEventDrawer from "../../../common/AddEventDrawer/AddEventDrawer";
import AddLocationDrawer from "../../../common/AddLocationDrawer/AddLocationDrawer";
import EventListingCard from "../../../common/Cards/EventListingCards/EventListingCard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import MobileTopDashboardNavBar from "../../../common/DashboardNavBar/mobileTopBar";
import EventListingCardSkeleton from "../../../common/Cards/EventListingCards/EventListingCardSkeleton";
import { useSearch } from "../../../common/DashboardNavBar/Search/SearchContext";
import { Helmet } from "react-helmet-async";

const Events: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLocDrawer, setOpenLocDrawer] = useState(false);
  const [services, setServices] = useState([]);
  const [addedLocations, setAddedLocations] = useState([]);
  const userRole = getRole();
  const { searchQuery, filters } = useSearch();
  const storedUserInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [updateList, setUpdateList] = useState(false);
  const [isProvider, setIsProvider] = useState(
    userRole === "supplier" || userRole === "freelancer"
  );
  const [filterCategory, setFilterCategory] = useState("All");

  const [categoriesData, setCategoriesData] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [coords, setCoords] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [drawerKey, setDrawerKey] = useState(0);

  const handleServiceCreated = () => {
    setDrawerKey((prevKey) => prevKey + 1);
  };

  const dispatch = useDispatch();

  const { events } = useSelector((state: RootState) => state);

  const openToAdd = () => {
    setOpenDrawer(true);
  };

  const closeToAdd = () => {
    setOpenDrawer(false);
  };

  const openToAddLoc = () => {
    setOpenLocDrawer(true);
  };

  const closeToAddLoc = () => {
    setOpenLocDrawer(false);
  };

  const updateEventsList = (updatedEvents: any) => {
    setServices(updatedEvents);
    setFilteredServices(updatedEvents);
  };

  const handleFilterByCategory = (category: any) => {
    if (filterCategory === category || category === "All") {
      setFilterCategory("All");
      setFilteredServices(services);
    } else {
      setFilterCategory(category);
      setFilteredServices(
        services.filter(
          (service: any) => service.service_category_main === category
        )
      );
    }
  };

  const filterTypes: any = {
    name: {
      label: "Name",
      data: [...new Set(services.map((event: any) => event.event_name))],
    },
    audience: {
      label: "Audience",
      data: ["male", "female"],
    },
    date: {
      label: "Date",
      type: "date",
    },
  };

  const filterTypesArray = Object.keys(filterTypes).map((key) => ({
    type: key,
    label: filterTypes[key].label,
    options: filterTypes[key].data,
  }));

  useEffect(() => {
    const filtered = services.filter((event: any) => {
      const matchesSearch = event.event_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      return (
        matchesSearch &&
        filters.every((filter) => {
          switch (filter.type) {
            case "name":
              return event.event_name === filter.value;
            case "audience":
              return event.event_targeted_audience === filter.value;
            case "date":
              const selectedDate = new Date(filter.value);
              const startOfDay = selectedDate.setHours(0, 0, 0, 0) / 1000;
              const endOfDay = selectedDate.setHours(23, 59, 59, 999) / 1000;
              const eventDate = parseInt(event.event_date);
              return eventDate >= startOfDay && eventDate <= endOfDay;
            default:
              return true;
          }
        })
      );
    });

    setFilteredServices(filtered);
  }, [searchQuery, filters, services]);

  const getAllServices = async () => {
    setIsLoading(true);
    try {
      let response;
      if (isProvider) {
        response = await _GET(
          "services/getFreelancerAllEvents/" + storedUserInfo.id,
          false,
          false
        );
      } else {
        response = await _GET("services/getAllEvents", false, false);
      }

      if (response && response.data) {
        setServices(response.data);
        setFilteredServices(response.data);
        setUpdateList(false);
      }
      // else {
      //   notification.open({
      //     type: "error",
      //     message: "Something Went Wrong",
      //     description: "Please reload and try again to get services.",
      //   });
      // }
    } catch (error) {
      console.error("Error fetching services:", error);
      notification.open({
        type: "error",
        message: "Error",
        description: "An error occurred while fetching services.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllServices();
  }, [dispatch]);

  const getAllCategoriesData = async () => {
    let response;

    response = await _GET("services/getAllCategoriesData", false, false);

    if (response) {
      if (response.data) {
        setCategoriesData(response.data);
        const categories = response.data.map(
          (dataObj: any) => dataObj.service_category_main
        );
        const uniqueCategories: any = [...new Set(categories)];
        uniqueCategories.unshift("All");
        setAllCategories(uniqueCategories);
      }
      //  else {
      //   return notification.open({
      //     type: "error",
      //     message: "Something Went Wrong",
      //     description: "Please reload and try again for better experience.",
      //   });
      // }
    }
  };

  const userData = localStorage.getItem("userInfo");
  let userId = "";

  try {
    if (userData) {
      const parsedData = JSON.parse(userData);
      userId = parsedData?.id || "";
    }
  } catch (error) {
    console.error("Error parsing userInfo from localStorage:", error);
  }

  const fetchAddressData = async () => {
    if (userRole !== "guest") {
      try {
        const response = await _GET(
          `userProfile/getUserAllAddress/${userId}`,
          true,
          false
        );
        const apiAddresses = response.data[0].user_addresses || [];

        setAddedLocations(apiAddresses);
      } catch (error: any) {
        console.error("Error while fetching address data", error);
        notification.open({
          type: "error",
          message: "Error while fetching address data",
          description: "Please try again later.",
        });
      }
    }
  };

  useEffect(() => {
    fetchAddressData();
  }, [userId]);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoords({ lat: latitude, lng: longitude });
      }
    );
  };
  useEffect(() => {
    getAllServices();
    getAllCategoriesData();
    if (isProvider) getCurrentLocation();
  }, []);

  useEffect(() => {
    if (updateList) getAllServices();
  }, [updateList]);
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const pageTitle = "Events";
  const pageDescription = "Events";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <MobileTopDashboardNavBar />
      <div className={`customLayoutSideContainer ${Styles.servicesContainer}`}>
        <DashboardNavBar
          filterTypes={filterTypesArray}
          showFilters={showFilters}
          toggleFilters={toggleFilters}
        />
        <AddEventDrawer
          openDrawer={openDrawer}
          closeToAdd={closeToAdd}
          openToAddLoc={openToAddLoc}
          setUpdateList={setUpdateList}
          categoriesData={categoriesData}
          addedLocations={addedLocations}
          getAllCategoriesData={getAllCategoriesData}
          coords={coords}
          updateEventsList={updateEventsList}
          onServiceCreated={handleServiceCreated}
          key={drawerKey}
        />
        <AddLocationDrawer
          openLocDrawer={openLocDrawer}
          closeToAddLoc={closeToAddLoc}
          setAddedLocations={setAddedLocations}
          coords={coords}
          setCoords={setCoords}
        />
        <div className={Styles.serviceMainContainer}>
          <div className={Styles.serviceHeader}>
            <h2>
              {isProvider
                ? filteredServices.length > 0
                  ? "My Events"
                  : "Add Events for your customers"
                : filteredServices.length > 0
                ? "Events for you"
                : services.length === 0
                ? "No Events available"
                : "No Events of this category"}
            </h2>
            {isProvider && (
              <div className={Styles.actionsContainer}>
                <CustomButton text="Add Event" onFinish={openToAdd} />
              </div>
            )}
          </div>

          {isLoading ? (
            <div className={Styles.serviceListing}>
              {Array.from({ length: 10 }).map((_, index) => (
                <EventListingCardSkeleton key={index} />
              ))}
            </div>
          ) : (
            filteredServices.length > 0 && (
              <div className={Styles.serviceListing}>
                {filteredServices.map((service: any) => (
                  <EventListingCard
                    service={service}
                    key={service.id}
                    setUpdateList={setUpdateList}
                    categoriesData={categoriesData}
                  />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Events;
