// import React, { useContext, useEffect, useMemo, useState } from "react";
// import {
//   CometChatThemeContext,
//   CometChatPalette,
//   CometChatTheme,
//   CometChatMessages,
//   UIKitSettingsBuilder,
//   CometChatUIKit,
// } from "@cometchat/chat-uikit-react";
// import Styles from "./PopupForm.module.css";
// import { CometChat } from "@cometchat/chat-sdk-javascript";
// import { Button, notification } from "antd";
// import { IconButton, Typography } from "@mui/material";
// import Cross from "../../../assests/customIcons/Cross";

// interface ChatWindowProps {
//   isVisible: boolean;
//   receiverID: string;
//   onClose: () => void;
// }

// const ChatWindow: React.FC<ChatWindowProps> = ({
//   isVisible,
//   receiverID,
//   onClose,
// }) => {
//   const [cometChatUser, setCometChatUser] = useState<any>(null);
//   const [isError, setIsError] = useState(false);
//   const userInfo: any = localStorage.getItem("userInfo");
//   const UserDetails = JSON.parse(userInfo || "{}");

// const COMETCHAT_CONSTANTS = {
//   APP_ID: "258835f9d7b5c927",
//   REGION: "EU",
//   AUTH_KEY: "8835837b4af0af1335a8277f37a9c38ab381a008",
// };

//   // useEffect(() => {
//   //   if (isVisible) {
//   //     handleLogin();
//   //     handleReceiver(receiverID);
//   //   }
//   // }, [receiverID]);

//   useEffect(() => {
//     if (isVisible) {
//       handleLogin().then(() => {
//         handleReceiver(receiverID);
//       });
//     }
//   }, [receiverID]);

//   const handleLogin = (): Promise<void> => {
//     CometChatUIKit.getLoggedinUser().then((user) => {
//       if (!user) {
//         // Login user
//         CometChatUIKit.login(UserDetails.id)
//           .then((user: CometChat.User) => {
//             console.log("Login Successful:", { user });
//             // handleReceiver(receiverID);
//           })
//           .catch(console.log);
//       } else {
//         return Promise.resolve();
//       }
//     });
//   };

//   const handleReceiver = (rId : string) => {
//     CometChat.getUser(rId)
//       .then((receiver) => {
//         setCometChatUser(receiver);
//       })
//       .catch((e) =>{
//       setIsError(true);
//     }
//       );
//   };

//   const { theme } = useContext(CometChatThemeContext);

//   const themeContext = useMemo(() => {
//     const customTheme = new CometChatTheme({
//       palette: new CometChatPalette({
//         mode: "light",
//         primary: {
//           light: "#0ba1f8",
//           dark: "#D422C2",
//         },
//         background: {
//           light: "#FFFFFF",
//           dark: "#141414",
//         },
//         accent: {
//           light: "Gray",
//           dark: "#B6F0D3",
//         },
//         accent50: {
//           light: "#ffffff",
//           dark: "#141414",
//         },
//         accent900: {
//           light: "white",
//           dark: "black",
//         },
//       }),
//     });
//     return { theme: customTheme };
//   }, [theme]);

//   if(!isVisible){
//     return null;
//   }

//   const handleChatClose = () => {
//     setIsError(false)
//     onClose()
//   }

//   return (
//     isVisible && (
//       <div className={Styles.servicesContainer}>
//         <div className={Styles.closeButton}>
//          <Button  onClick={handleChatClose} className={Styles.closeChatBtn}>
//             Close Chat
//           </Button>
//           </div>
//        {isError ?
//        (<div  className={Styles.chatContainer_error}>
//         <Typography variant="subtitle2" color={"red"}>You cannot chat with the user at this moment. Please try again later!</Typography>
//         </div>)
//        :
//         (<div
//           className={Styles.chatContainer}
//         >

//           {cometChatUser && (
//             <CometChatThemeContext.Provider value={themeContext}>
//               <CometChatMessages user={cometChatUser}/>
//             </CometChatThemeContext.Provider>
//           )}

//         </div>)}
//       </div>
//     )
//   );
// };

// export default ChatWindow;

import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CometChatThemeContext,
  CometChatPalette,
  CometChatTheme,
  CometChatMessages,
  UIKitSettingsBuilder,
  CometChatUIKit,
} from "@cometchat/chat-uikit-react";
import Styles from "./PopupForm.module.css";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { Button, notification } from "antd";
import { IconButton, Typography } from "@mui/material";
import Cross from "../../../assests/customIcons/Cross";

interface ChatWindowProps {
  isVisible: boolean;
  receiverID: string;
  onClose: () => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  isVisible,
  receiverID,
  onClose,
}) => {
  const [cometChatUser, setCometChatUser] = useState<any>(null);
  const [isError, setIsError] = useState(false);
  const userInfo: any = localStorage.getItem("userInfo");
  const UserDetails = JSON.parse(userInfo || "{}");

  const COMETCHAT_CONSTANTS = {
    APP_ID: "258835f9d7b5c927",
    REGION: "EU",
    AUTH_KEY: "8835837b4af0af1335a8277f37a9c38ab381a008",
  };

  useEffect(() => {
    if (isVisible) {
      handleLogin().then(() => {
        handleReceiver(receiverID);
      });
    }
  }, [receiverID]);

  const handleLogin = (): Promise<void> => {
    return CometChatUIKit.getLoggedinUser().then((user) => {
      if (!user) {
        // Login user
        return CometChatUIKit.login(UserDetails.id)
          .then((user: CometChat.User) => {
            console.log("Login Successful:", { user });
          })
          .catch((error) => {
            console.error("Login failed with error:", error);
          });
      } else {
        return Promise.resolve();
      }
    });
  };

  const handleReceiver = (rId: string) => {
    CometChat.getUser(rId)
      .then((receiver) => {
        setCometChatUser(receiver);
      })
      .catch((e) => {
        setIsError(true);
      });
  };

  const { theme } = useContext(CometChatThemeContext);

  const themeContext = useMemo(() => {
    const customTheme = new CometChatTheme({
      palette: new CometChatPalette({
        mode: "light",
        primary: {
          light: "#0ba1f8",
          dark: "#D422C2",
        },
        background: {
          light: "#FFFFFF",
          dark: "#141414",
        },
        accent: {
          light: "Gray",
          dark: "#B6F0D3",
        },
        accent50: {
          light: "#ffffff",
          dark: "#141414",
        },
        accent900: {
          light: "white",
          dark: "black",
        },
      }),
    });
    return { theme: customTheme };
  }, [theme]);

  if (!isVisible) {
    return null;
  }

  const handleChatClose = () => {
    setIsError(false);
    onClose();
  };

  return (
    isVisible && (
      <div className={Styles.servicesContainer}>
        <div className={Styles.closeButton}>
          <Button onClick={handleChatClose} className={Styles.closeChatBtn}>
            Close Chat
          </Button>
        </div>
        {isError ? (
          <div className={Styles.chatContainer_error}>
            <Typography variant="subtitle2" color={"red"}>
              You cannot chat with the user at this moment. Please try again
              later!
            </Typography>
          </div>
        ) : (
          <div className={Styles.chatContainer}>
            {cometChatUser && (
              <CometChatThemeContext.Provider value={themeContext}>
                <CometChatMessages user={cometChatUser} />
              </CometChatThemeContext.Provider>
            )}
          </div>
        )}
      </div>
    )
  );
};

export default ChatWindow;
