import axios from "axios";
import { APPLICATION_ROUTES, BASE_URL } from "../constant/ApplicationRoutes";

const TOKEN_EXPIRY_TIME = 14 * 60 * 1000;

export const setCookie = (name: string, value: string, hours: number) => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=-99999999; path=/`;
};

export const logoutUser = () => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("loginTime");
  deleteCookie("refreshToken");
  window.location.href = APPLICATION_ROUTES.LOGIN;
};

const isTokenExpiredByTime = () => {
  const loginTime = localStorage.getItem("loginTime");
  if (!loginTime) {
    console.log("Login time not found in localStorage.");
    return true;
  }

  const currentTime = Date.now();
  const timeElapsed = currentTime - parseInt(loginTime, 10);

  if (timeElapsed < 0) {
    console.warn(
      "Login time is in the future. Resetting login time to current time."
    );
    localStorage.setItem("loginTime", currentTime.toString());
    return false;
  }

  const isExpired = timeElapsed >= TOKEN_EXPIRY_TIME;
  console.log("Is token expired based on time?", isExpired);

  return isExpired;
};

let refreshingTokenPromise: Promise<string> | null = null;

export const refreshAccessToken = async () => {
  if (refreshingTokenPromise) {
    console.log(
      "Token is currently being refreshed. Waiting for the existing promise."
    );
    return refreshingTokenPromise;
  }

  refreshingTokenPromise = new Promise(async (resolve, reject) => {
    try {
      localStorage.removeItem("jwtToken");
      axios.defaults.headers.common["x-access-token"] = null;
      axios.defaults.headers.common["Authorization"] = null;

      const refreshToken = getCookie("refreshToken");
      if (!refreshToken) throw new Error("No refresh token found");

      const response = await axios.post(`${BASE_URL}refreshToken`, {
        refreshToken,
      });

      const { token } = response.data.data;

      localStorage.setItem("jwtToken", token);
      localStorage.setItem("loginTime", Date.now().toString());
      axios.defaults.headers.common["x-access-token"] = token;
      axios.defaults.headers.common["Authorization"] = token;
      axios.defaults.headers.common["x-refresh-token"] = refreshToken;

      resolve(token);
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logoutUser();
      reject(error);
    } finally {
      refreshingTokenPromise = null;
    }
  });

  return refreshingTokenPromise;
};

export const getValidAccessToken = async () => {
  let token = localStorage.getItem("jwtToken");

  if (!token || isTokenExpiredByTime()) {
    console.log(
      "Token is either not present or expired based on time. Refreshing token..."
    );
    token = await refreshAccessToken();
  } else {
    console.log("Token is valid, no need to refresh.");
  }

  return token;
};
