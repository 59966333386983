import React, { useRef, useState, useEffect, DragEvent } from "react";
import { notification } from "antd";
import UploadIconCustom from "../../assests/Upload.svg";
import { Button, Box, Typography } from "@mui/material";
import Cross from "../../assests/customIcons/Cross";
import PreviewImages from "../../common/EditServiceDrawer/PreviewImages";

interface FileUploadProps {
  title1: string;
  title2?: string;
  uploadMultiple?: boolean;
  description: string;
  setData: (data: { [key: string]: any }) => void;
  name: string;
  existingPreviews?: any;
  setExistingPreviews?: any;
  reset?: any;
  alllowAll?: boolean;
  disabled?: boolean;
  editShow?: boolean;
}

const FileUpload: React.FC<any> = ({
  title1,
  title2,
  uploadMultiple,
  description,
  setData,
  name,
  existingPreviews,
  setExistingPreviews,
  reset,
  alllowAll,
  disabled = false,
  editShow = true,
  onRemovePreviewImage,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [filePreview, setFilePreview] = useState<string | any>(null);
  const [filePreviews, setFilePreviews] = useState<any>([]);
  const [existingNames, setExistingNames] = useState<any>([]);

  useEffect(() => {
    setFilePreview("");
    setFilePreviews([]);
  }, [reset]);

  const handleFileChange = (file: File | null) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use setFilePreview for single file uploads to update the filePreview state
        setFilePreview(reader.result as string);
        setData({ [name]: file });
        setMessage("File uploaded successfully.");
      };
      reader.readAsDataURL(file);
    } else {
      setMessage("Failed to upload file.");
    }
  };

  const handleFilesChange = (files: any) => {
    const totalFilesCount =
      filePreviews.length + (existingPreviews?.length || 0);

    if (totalFilesCount + files.length > 10) {
      notification.open({
        type: "info",
        message: "Upload Limit Exceeded",
        description: "You can only upload a maximum of 10 images.",
      });
      return;
    }

    setMessage("Uploading files...");

    Array.from(files).forEach((file: any, index: number) => {
      if (!existingNames.includes(file.name)) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const fileContent = e.target.result;
          setFilePreviews((prev: any) => [...prev, fileContent]);
          setExistingNames((prev: any) => [...prev, file.name]);
          setData({ [name + index]: file });
        };
        reader.readAsDataURL(file);
      } else {
        notification.open({
          type: "error",
          message: "Duplicate File",
          description: `There exist an image with this name already - ${file.name}, Please use another name`,
        });
      }
    });

    setMessage("Files uploaded successfully.");
  };

  const handleDeleteSpecificFile = (index: number) => {
    console.log('Handle specific delete file is called', index)
    const existingLength =
      existingPreviews && existingPreviews.length > 0
        ? existingPreviews.length
        : 0;
    const actualIndex = index - existingLength;

    const newFilePreviews = [...filePreviews];
    newFilePreviews.splice(actualIndex, 1);
    setFilePreviews(newFilePreviews);

    const newExistingNames = [...existingNames];
    newExistingNames.splice(index, 1);
    setExistingNames(newExistingNames);

    setData((prevData: { [key: string]: any }) => {
      const newData = { ...prevData };
      delete newData[name + index];
      return newData;
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      const validatedFiles = Array.from(files).filter((file) =>
        validateFile(file)
      );
      if (uploadMultiple) {
        handleFilesChange(validatedFiles);
      } else {
        // For single file uploads, pick the first validated file, if any
        if (validatedFiles.length > 0) {
          handleFileChange(validatedFiles[0]);
        }
      }
    }
  };

  const handleDeleteFile = () => {
    console.log('Handle delete file is called')
    setMessage(null);
    setFilePreview(null);
    setData({ [name]: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  useEffect(() => {
    if (existingPreviews) {
      setExistingNames(
        existingPreviews.map((imgUrl: string) =>
          imgUrl.substring(imgUrl.lastIndexOf("/") + 1)
        )
      );
    }
  }, [existingPreviews]);

  const allowedFileTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/gif",
    "application/msword",
  ]; // Example file types
  const maxFileSize = 5 * 1024 * 1024; // Example max file size (5MB)

  const validateFile = (file: any) => {
    if (!allowedFileTypes.includes(file.type)) {
      notification.open({
        type: "error",
        message: "Invalid File Type",
        description: "Only JPEG, PNG, and PDF files are allowed.",
      });
      return false;
    }
    if (file.size > maxFileSize) {
      notification.open({
        type: "error",
        message: "File Too Large",
        description: "File size must be less than 5MB.",
      });
      return false;
    }
    return true;
  };

  // Update your handleFileChange and handleFilesChange to call validateFile

  return (
    <>
      <Box
        onDragOver={(e) => e.preventDefault()}
        onDrop={onDrop}
        sx={{
          width: "auto",
          backgroundColor: "white",
          p: 2,

          borderRadius: "20px",
          display: "flex",
          position: "relative",

          boxShadow: "0px 10px 30px rgba(48, 38, 62, 0.06)",
          border: "1.5px dashed rgba(48, 38, 62, 0.25)",
          padding: "20px",
          // alignItems: "center",
          // flexDirection: "column",
          // justifyContent: "center",
        }}
      >
        {editShow && (
          <div
            style={{
              width: "50px",
              height: "50px",
              marginRight: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              boxShadow: "rgba(47, 0, 255, 0.35) 0px 5px 15px",
              borderRadius: "15px",
            }}
          >
            <img
              src={UploadIconCustom}
              alt="UploadIcon"
              style={{ width: "45px", height: "45px" }}
            />
          </div>
        )}

        <div style={{ width: "80%" }}>
          {editShow && (
            <>
              {title1 && <h3 style={{ margin: 0 }}>{title1}</h3>}
              {title2 && (
                <h4 style={{ margin: 0, marginTop: "3px" }}>{title2}</h4>
              )}
              <p style={{ margin: 0, marginTop: "3px" }}>{description}</p>
              <input
                type="file"
                multiple={uploadMultiple}
                ref={fileInputRef}
                style={{ display: "none" }}
                disabled={disabled}
                accept={
                  alllowAll
                    ? "image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    : "image/jpeg,image/png"
                }
                onChange={(e) => {
                  const files = e.target.files;
                  if (!files) return;

                  const validatedFiles = [];
                  for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    if (validateFile(file)) {
                      // Assuming validateFile is implemented as suggested previously
                      validatedFiles.push(file);
                    } else {
                      // Optionally clear the input if any file is invalid
                      // This depends on your desired user experience
                      e.target.value = "";
                      // Possibly break here to stop processing further files since one is invalid
                      // break;
                    }
                  }

                  if (uploadMultiple) {
                    handleFilesChange(validatedFiles);
                  } else if (validatedFiles.length > 0) {
                    // Ensure at least one file is valid
                    handleFileChange(validatedFiles[0]);
                  }
                }}
              />
              <Button
                variant="outlined"
                onClick={() => fileInputRef.current?.click()}
                style={{
                  borderRadius: "40px",
                  backgroundColor: "#4e4dff",
                  color: "white",
                  fontWeight: "bold",
                  marginTop: "8px",
                }}
                disabled={disabled}
              >
                Upload File
              </Button>
              {uploadMultiple ? (
                <div
                  style={{
                    display: filePreviews.length ? "flex" : "none",
                    flexDirection: "row",
                    marginTop: "15px",
                    padding: "5px",
                    backgroundColor: "white",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    overflow: "auto",
                    gap: "10px",
                  }}
                  className="uploadMultipleContainer"
                >
                  {filePreviews.map((preview: any, index: any) => (
                    <div
                      key={(existingPreviews || []).length + index}
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={preview}
                        alt="File Preview"
                        style={{
                          height: "100px",
                          width: "auto",
                          padding: "5px",
                          marginTop: "5px",
                          borderRadius: "15px",
                        }}
                      />
                      <Button
                        onClick={() =>
                          handleDeleteSpecificFile(
                            (existingPreviews || []).length + index
                          )
                        }
                        className="elevatedElement iconBtn"
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          minWidth: "30px",
                        }}
                      >
                        <Cross size={"16"} />
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "15px",
                    padding: "5px",
                    backgroundColor: "white",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    overflow: "auto",
                    gap: "10px",
                  }}
                  className="uploadMultipleContainer"
                >
                  {filePreview && (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      {/* Chjeck isf filePreview is image or pdf and show */}
                      <>
                        {filePreview.includes("image") ? (
                          <img
                            src={filePreview}
                            alt="File Preview"
                            style={{
                              height: "100px",
                              width: "auto",
                              padding: "5px",
                              marginTop: "5px",
                              borderRadius: "15px",
                            }}
                          />
                        ) : (
                          <iframe
                            src={filePreview}
                            style={{
                              height: "200px",
                              width: "auto",
                              padding: "5px",
                              marginTop: "5px",
                              borderRadius: "15px",
                            }}
                          />
                        )}
                      </>
                      <Button
                        onClick={handleDeleteFile}
                        className="elevatedElement iconBtn"
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          minWidth: "30px",
                        }}
                      >
                        <Cross size={"16"} />
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {existingPreviews && existingPreviews.length > 0 && (
            <PreviewImages
              previews={existingPreviews}
              setPreviews={setExistingPreviews}
              disabled={disabled}
              // onRemovePreviewImage={onRemovePreviewImage}
            />
          )}
        </div>
      </Box>
    </>
  );
};

export default FileUpload;
