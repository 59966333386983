import React, { useState } from "react";
import { Drawer, Button } from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import Styles from "./ChatSidebar.module.css";

interface ChatSidebarProps {
  renderChat: () => React.ReactNode;
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({ renderChat }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggleDrawer = () => setVisible(!visible);

  return (
    <div>
      <Drawer
        title="Live Chat"
        placement="left"
        closable={false}
        onClose={toggleDrawer}
        open={visible}
        width={500}
        maskClosable={false}
        mask={false}
        className={Styles.drawer}
      >
        {renderChat()}
      </Drawer>

      <Button
        type="primary"
        shape="circle"
        icon={
          visible ? (
            <ArrowLeftOutlined />
          ) : (
            <div style={{ position: "relative" }}>
              {!visible && <span className={Styles.notificationDot} />}
              <MessageOutlined className={Styles.iconAnimation} />
            </div>
          )
        }
        onClick={toggleDrawer}
        className={`${Styles.drawerToggleButton} ${
          visible ? Styles.drawerOpen : Styles.drawerClosed
        }`}
      />
    </div>
  );
};

export default React.memo(ChatSidebar);
