//  TODO : Needs to re write code for drawers we are using.
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  Avatar,
  Button,
  Drawer,
  Input,
  Space,
  Form,
  Checkbox,
  DatePicker,
  Row,
  Col,
  notification,
  Tabs,
  Radio,
  Select,
} from "antd";
import { Visibility } from "@mui/icons-material";
import DrawerMessage from "../../../assests/FreelancerMessage.png";
import DrawerSaved from "../../../assests/FreelancerSaved.png";
import DrawerLocation from "../../../assests/FreelancerLocation.png";
import chatOutlined from "../../../assests/chatOutlined.svg";
import saveOutlined from "../../../assests/saveOutlined.svg";
import FreelancerProfileStyle from "../../../common/StoryAvatar/StoryAvatar.module.css";
import Social from "../../../assests/socialMediaImage.svg";
import {
  showSuccessCustomModal,
} from "../../../common/CustomModal/CustomModal";

import Styles from "./Table.module.css";
import {
  _GET,
  _POST,
  getSupplierID,
} from "../../../common/commonFunc";
import PopupForm from "../components/PopUpForm";
import { StarFilled } from "@ant-design/icons";
import Cross from "../../../assests/customIcons/Cross";
import RichTextEditor from "../../../components/RichText/Quill2";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import _ from "lodash";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useHistory } from "react-router";
import {
  getFreelancerChatData,
} from "../../../redux/actions/FreelancerProfile";
import { useDispatch } from "react-redux";
import { FreelancerServiceCard } from "../../ServiceProvioder/Service/FreelancerServiceCard";
import TabPane from "antd/es/tabs/TabPane";
import moment from "moment";


const CollabTable = ({ headers, data, status, fetchData, activeTab }: any) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDrawerService, setSelectedDrawerService] = useState<any>([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedService, setSelectedService] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editorContent, setEditorContent] = useState<any>(null);
  const [fixedAmount, setFixedAmount] = useState<any>(null);
  const [editorContentMore, setEditorContentMore] = useState<any>(null);
  const [myProducts, setMyProducts] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>({});
  const [openDrawer, setopenDrawer] = useState<any>(false);
  const [contractType, setContractType] = useState<string | null>(null);
  const [collabType, setCollabType] = useState<string | null>(null);
  const [advancePayPercent, setAdvancePayPercent] = useState(10);

  const { Option } = Select;

  const handleContractType = (event: any) => {
    setContractType(event.target.value);
    setSelectedProducts({})
  };

  const handleCollabType = (event: any) => {
    setCollabType(event.target.value);
    setContractType(null);
    setSelectedProducts({})
  };

  const dispatch = useDispatch<any>();

  const history = useHistory();

  const onCloseDrawerHandler = async () => {
    setopenDrawer(false);
  };

  const handleSendMessage = async (row: any) => {
    await dispatch(getFreelancerChatData(row.freelancer_id, () => { }));
    const receiverID = _.get(row, "freelancer_id", "");
    history.push(
      `${APPLICATION_ROUTES.SUPPLIER_CHAT_FREELANER_PAGE}/${receiverID}`
    );
  };


  const supplierID = getSupplierID();

  const handleSelectProduct = (productId: any, checked: any) => {
    setSelectedProducts((prev: any) => {
      const updatedProducts = checked
        ? {
          ...prev,
          [productId]: {
            product_id: productId,
            selected: checked,
            commission_rate: prev[productId]?.commission_rate || 0,
          },
        }
        : Object.keys(prev).reduce((acc: any, key: any) => {
          if (key !== productId) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});

      return updatedProducts;
    });
  };

  const handleSubmit = async (formValues: any) => {
    if (editorContent === null) {
      notification.error({
        message: "Error",
        description: "Please add collaboration interest expectation",
      });
      return;
    }

    if (editorContentMore === null) {
      notification.error({
        message: "Error",
        description: "Please add collaboration detailed info",
      });
      return;
    }

    if (collabType === 'non_product_based') {
      if (fixedAmount === null || fixedAmount === "") {
        notification.error({
          message: "Error",
          description: "Please enter the fixed amount",
        });
        return;
      }
    }

    if (collabType === null || collabType === "") {
      notification.error({
        message: "Error",
        description: "Please select a collaboration type",
      });
      return;
    }

    if (collabType === 'product_based') {
      if (contractType === null || contractType === "") {
        notification.error({
          message: "Error",
          description: "Please select a contract type",
        });
        return;
      }
      if (Object.keys(selectedProducts).length === 0) {
        notification.error({
          message: "Error",
          description: "Please select at least one product",
        });
        return;
      }
    }

    for (const product of Object.values(selectedProducts) as Array<{ 
      selected: boolean; 
      target_quantity: string; 
      product_id: string; 
  }>) {
      if (product.selected) {
          const { target_quantity, product_id } = product;

          const matchingProduct = myProducts.find((p : any )=> p.product_id === product_id);
          const total_unassigned_quantity = matchingProduct?.total_unassigned_quantity || 0;
  
          if (target_quantity > total_unassigned_quantity) {
              notification.error({
                  message: "Error",
                  description: `Target quantity for exceeds available quantity.`,
              });
              return; // Stop the submission
          }
      }
  }


    const productsArray: any = Object.values(selectedProducts)
      .filter((product: any) => product.selected)
      .map(({ product_id, commission_rate, target_quantity, fixed_amount, advance_amount_percentage }: any) => ({
        product_id,
        product_name: myProducts.find((p: any) => p.product_id === product_id)
          ?.product_name,
        commission_rate: contractType === "fixed_amount" ? null : parseFloat(commission_rate),
        start_date: formValues.collaboration_start_date.unix(),
        collaboration_duration_days: parseInt(
          formValues.collaboration_duration_days,
          10
        ),
        target_quantity: target_quantity || 0,
        fixed_amount: contractType === 'commission_percentage' ? null : fixed_amount,
        contract_type: contractType,
        advance_amount_percentage: contractType === 'commission_percentage' ? 0.0 : advance_amount_percentage ?? 10,
      }));

    let payload


    if (collabType === 'non_product_based') {
      payload = {
        supplier_id: supplierID,
        freelancer_id: selectedDrawerService.freelancer_id,
        products: [],
        collaboration_interest_expectation: editorContent,
        collaboration_detailed_info: editorContentMore,
        collaboration_start_date: formValues.collaboration_start_date.unix(),
        collaboration_duration_days: parseInt(
          formValues.collaboration_duration_days,
          10
        ),
        request_type: collabType,
        non_product_fixed_amount: parseFloat(fixedAmount),
        advance_amount_percentage: advancePayPercent

      };

      console.log("value of fixed amount", fixedAmount)
    } else {
      payload = {
        supplier_id: supplierID,
        freelancer_id: selectedDrawerService.freelancer_id,
        products: productsArray,
        collaboration_interest_expectation: editorContent,
        collaboration_detailed_info: editorContentMore,
        collaboration_start_date: formValues.collaboration_start_date.unix(),
        collaboration_duration_days: parseInt(
          formValues.collaboration_duration_days,
          10
        ),
        request_type: collabType,

      }
    }

    console.log("payload in send collaboration", payload)
    try {
      const response: any = await _POST(
        `supplier/collaboration/sendRequest`,
        payload,
        false
      );
      if (response.status === true && response.statusCode === 200) {
        showSuccessCustomModal({
          title: "Collaboration request sent successfully",
          smallText: "Your collaboration request has been sent successfully.",
          buttonText: "Continue",
        });
        fetchData();
        // activeTab("Sent");
        setVisibleModal(false);
        closeModal();
        form.resetFields();
        setEditorContent(null);
        setEditorContentMore(null);
        setSelectedProducts({});
      } else {
        notification.error({
          message: "Error",
          description: response.message,
        });
      }
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error);
    }
  };

  const handleCommissionChange = (productId: any, commissionRate: any) => {
    setSelectedProducts((prev: any) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        commission_rate: commissionRate.toString(),
      },
    }));
  };

  const handlelumpSumChange = (productId: any, fixed_amount: any) => {
    setSelectedProducts((prev: any) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        fixed_amount: fixed_amount.toString(),
      },
    }));
  };

  const handleTargetQtyChange = (productId: any, targetQuantity: any) => {
    setSelectedProducts((prev: any) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        target_quantity: targetQuantity.toString(),
      },
    }));
  };

  const handleFixedAmtChange = (fixedAmt: any) => {
    setFixedAmount(fixedAmt)
  }

  // const handleCollabTypeChange = (event : any) => {
  //   const {name, checked} = event.target
  //   setCollabType((prevState) => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  //   setContractType(null);
  // }

  const handleScroll = (event: any) => {
    event.target.blur();
  }

  const getProducts = async () => {
    try {
      const response = await _GET(
        `supplier/getAllAssignableProducts`,
        true,
        false
      );
      setMyProducts(response.data);
    } catch (error) {
      console.log("🚀 ~ getProducts ~ error:", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, [data]);

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };

  const handleEditorChangeMore = (content: any) => {
    setEditorContentMore(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };


  const openModal = (item: any) => {
    setSelectedDrawerService(item);
    setVisibleModal(true);
  };
  const closeModal = () => {
    setVisibleModal(false);
    form.resetFields();
    setCollabType(null);
    setFixedAmount(null);
    setContractType(null);
    setEditorContent(null);
    setEditorContentMore(null)
  };

  const handleCancelService = async (serviceId: any, reason: any) => {
    const payload = {
      cancellation_reason: reason,
    };
    try {
      const cancelBookingResponse = await _POST(
        `services/cancelServiceBooking/${serviceId}`,
        payload,
        true
      );
      if (cancelBookingResponse.data === true) {
        showSuccessCustomModal({
          title: "Booking canceled successfully",
          smallText: "Your service has been cancelled successfully.",
          buttonText: "Continue",
        });
        fetchData();
        activeTab("Cancelled");
      } else {
        console.error(
          cancelBookingResponse.error
            ? cancelBookingResponse.error
            : "Booking cancellation failed"
        );
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  };


  const [freelancerProfile, setFreelancerProfile] = useState<any>([]);
  const [categoriesDataOldData, setCategoriesDataOldData] = useState([]);

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const renderAvatar = () => {
    if (
      freelancerProfile.profile_pic &&
      freelancerProfile.profile_pic !== "NULL"
    ) {
      return (
        <img
          src={freelancerProfile.profile_pic}
          style={{
            marginTop: "20px",
            marginLeft: "25px",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = freelancerProfile.first_name
        ? freelancerProfile.first_name[0]
        : "";
      const last = freelancerProfile.last_name
        ? freelancerProfile.last_name[0]
        : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            marginTop: "20px",
            marginLeft: "25px",
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: bgColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  const [reqRow, setReqRow] = useState<any>(null);

  const openFreelancerDrwaer = (row: any) => {
    console.log("🚀 ~ openFreelancerDrwaer ~ row:", row);

    setReqRow(row);

    const getData = async () => {
      try {
        const res = await _GET(
          `userProfile/freelancerProfile/${row.freelancer_id}`,
          true,
          false
        );
        const servicesRes = await _GET(
          `services/getFreelancerAllServices/${row.freelancer_id}`,
          true,
          false
        );
        let freelancerDetails = _.get(res, "data", []);
        const servicesResData = _.get(servicesRes, "data", []);
        if (res && res.statusCode === 200) {
          setFreelancerProfile(freelancerDetails);
        } else {
          notification.error({
            message: "Error",
            description: "Something went wrong",
          });
        }
      } catch (error) { }
    };

    getData();

    setopenDrawer(true);
  };

  const handleViewMore = () => {
    setRowsPerPage((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setPage(0);
    setRowsPerPage(3);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const CustonAvatarName = (pic: any, name: any, width: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          width: width,
        }}
      >
        <Avatar src={pic} alt={name} />
        <p>{`${name}`}</p>
      </div>
    );
  };

  const renderSocialMediaIcons = (socialMedia: any) => {
    return (
      <div className={Styles.social_media_icons}>
        {socialMedia.instagram && (
          <img src={socialMedia.instagram} alt="Instagram" />
        )}
        {socialMedia.facebook && (
          <img src={socialMedia.facebook} alt="Facebook" />
        )}
        {socialMedia.snapchat && (
          <img src={socialMedia.snapchat} alt="Snapchat" />
        )}
        {socialMedia.tiktok && <img src={socialMedia.tiktok} alt="TikTok" />}
      </div>
    );
  };

  const renderRating = () => {
    return (
      <div
        style={{
          backgroundColor: "#30263E",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "5px",
          padding: "5px",
          borderRadius: "35px",
        }}
      >
        <span style={{ color: "#FFB800" }}>
          <StarFilled />
        </span>

        <span style={{ color: "white" }}>0.00</span>
      </div>
    );
  };

  const validatePrice = async (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a price!"));
    }
    const numValue = parseFloat(value);
    if (numValue <= 0) {
      return Promise.reject(new Error("Price must be greater than zero."));
    }
    const regex = /^\d{1,13}(\.\d{0,3})?$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error("Price must be less than 1 trillion."));
    }
    return Promise.resolve();
  };

  const handlePercentageChange = (value: any) => {
    setAdvancePayPercent(value);
  };
  const handlePercentageChange_np = (productId: any, advancePercentage: any) => {
    console.log("advance%", advancePercentage)
    setSelectedProducts((prev: any) => ({
      ...prev,
      [productId]: {
        ...prev[productId],
        advance_amount_percentage: advancePercentage,
      },
    }));
  };



  return (
    <>
      <PopupForm
        isVisible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={(reason) => handleCancelService(selectedService.id, reason)}
        id={selectedService.id}
        text="booking"
      />
      <TableContainer component={Paper} className={Styles.tableContainer}>
        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableHead className={Styles.tableHeader}>
              <TableRow>
                {headers.map(
                  (header: any) =>
                    header.id !== "freelancer_id" && (
                      <TableCell
                        key={header.id}
                        style={{
                          width: header.width,
                          textAlign: "center",
                        }}
                      >
                        {header.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
          </Table>
        </div>

        <div className={Styles.customHeader}>
          <Table className={Styles.table}>
            <TableBody className={Styles.tableBody}>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={headers.length}
                    style={{ textAlign: "center" }}
                  >
                    <h1> No data available</h1>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: any) => (
                    <TableRow key={index}>
                      {headers.map((header: any) => (
                        <TableCell
                          key={`${header.id}-${index}`}
                          style={{
                            width: header.width,
                            textAlign: "center",
                          }}
                        >
                          {header.id === "freelancer_id"
                            ? ""
                            : header.id === "action"
                              ? (
                                <div className={Styles.actionButtons}>
                                  <Button className={Styles.addButton} onClick={() => openModal(row)} >
                                    Send Request
                                  </Button>
                                  <Button
                                    className={Styles.eye}
                                    onClick={() => handleSendMessage(row)}
                                  >
                                    <img src={chatOutlined} alt="" />
                                  </Button>{" "}
                                  <Button className={Styles.eye} onClick={() => console.log(row)}>
                                    <img src={saveOutlined} alt="" />
                                  </Button>
                                  <Button
                                    className={Styles.eye}
                                    onClick={() => openFreelancerDrwaer(row)}
                                  >
                                    <Visibility />
                                  </Button>
                                </div>
                              )
                              : header.id === "full_name"
                                ? CustonAvatarName(
                                  row.profile_pic,
                                  row.display_name ?? row.full_name,
                                  row.width
                                )
                                : header.id === "social_media"
                                  ? renderSocialMediaIcons(row.social_media)
                                  : header.id === "rating"
                                    ? renderRating()
                                    : row[header.id]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
          <div className={Styles.tableFooter}>
            <div className={Styles.viewControls}>
              {data.length > 3 && rowsPerPage > 3 && (
                <button onClick={handleShowLess} className={Styles.showLess}>
                  Show Less
                </button>
              )}
              {data.length > page * rowsPerPage + rowsPerPage && (
                <button onClick={handleViewMore} className={Styles.viewMore}>
                  View More
                </button>
              )}
            </div>
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[3, 5, 10, 25, 50, 100, 200]}
              className={Styles.pagination}
            />
          </div>
        </div>
      </TableContainer>

      {visibleModal && (
        <Drawer
          title="Send collaboration request"
          placement="right"
          closable={false}
          onClose={closeModal}
          open={visibleModal}
          key="right"
          width={"580px"}
          height={"100%"}
          extra={
            <Space style={{ zIndex: 100 }}>
              <Button onClick={closeModal} className="elevatedElement iconBtn">
                <Cross />
              </Button>
            </Space>
          }
        >
          <div className={Styles.avatarDivInfo}>
            <div>
              {" "}
              <Avatar
                src={selectedDrawerService.profile_pic}
                alt={"avatar"}
                size={70}
              />
            </div>
            <div className={Styles.infoDiv}>
              <div className={Styles.nameDiv}>
                <p className={Styles.name}>{selectedDrawerService.full_name}</p>
                <img
                  className={Styles.saveIcon}
                  src={saveOutlined}
                  alt="save"
                />
              </div>
              <div>
                <p className={Styles.info}>
                  ● {selectedDrawerService.octopus_follower_count} Followings ●{" "}
                  {selectedDrawerService.collaboration_requests_count}{" "}
                  Collbrations
                </p>
              </div>
            </div>
          </div>
          <div>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Form.Item
                name="collaboration_interest_expectation"
                label="Collaboration interest expectation"
                required={true}
              >
                <RichTextEditor
                  initialContent={editorContent}
                  onChange={handleEditorChange}
                />
              </Form.Item>
              <Form.Item
                name="collaboration_detailed_info"
                label="Detailed Information on Promotional Product"
                required={true}
              >
                <RichTextEditor
                  initialContent={editorContentMore}
                  onChange={handleEditorChangeMore}
                />
              </Form.Item>


              <Form.Item name="collaboration_type" label="Collaboration type" required={true}>
                <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>

                  <Radio.Group onChange={handleCollabType} value={collabType}>
                    <Radio value="product_based"> Product based Collaboration</Radio>
                    <Radio value="non_product_based">  Non Product based Collaboration</Radio>
                  </Radio.Group>
                </div>
              </Form.Item>


              {collabType === "product_based" && (
                <Form.Item name="contract_type" label="Contract type" required={true}>
                  <div style={{ display: "flex", justifyContent: 'space-between', width: "100%" }}>
                    <Radio.Group onChange={handleContractType} value={contractType}>
                      <Radio value="commission_percentage">Commission Based</Radio>
                      <Radio value="fixed_amount"> Fixed Price</Radio>
                    </Radio.Group>
                  </div>
                </Form.Item>
              )}

              {collabType === "non_product_based" && (
                // <Form.Item name="lump_sum" label="Fixed Amount" required={true}>
                //   <Input
                //     className={Styles.formInput}
                //     placeholder="Enter Amount"
                //     value={fixedAmount || ""}
                //     onChange={e => handleFixedAmtChange(e.target.value)}
                //     type="number"
                //   />
                // </Form.Item>
                <Row gutter={16}>
                  <Col span={12} style={{ marginBottom: "20px" }}>
                    <Form.Item
                      label={
                        <span>
                          Fixed Amount (BHD)
                        </span>
                      }
                      name="fixed_amount"
                      rules={[
                        { required: true, message: "" },

                        {
                          validator: validatePrice,
                        },
                      ]}
                    >
                      <Input
                        className={Styles.formInput}
                        placeholder="Enter Amount"
                        value={fixedAmount || ""}
                        onChange={e => handleFixedAmtChange(e.target.value)}
                        type="number"
                        onWheel={handleScroll}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="advance_pay_percentage"
                      label={
                        <span>
                          Select Advance Payment (%)
                        </span>
                      }
                    >
                      <Select
                        value={advancePayPercent}
                        onChange={handlePercentageChange}
                        style={{ width: 200 }}
                        className={Styles.formSelect}
                      >
                        <Option value={10}>10%</Option>
                        <Option value={25}>25%</Option>
                        <Option value={50}>50%</Option>
                        <Option value={75}>75%</Option>
                        <Option value={100}>100%</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

              )}

              {contractType && (
                <Form.Item name="products" label="Products">
                  <div style={{ backgroundColor: "white", maxHeight: "300px", overflowY: "scroll" }} className={Styles.productList}>
                    {Array.isArray(myProducts) &&
                      myProducts.map(product => (
                        <div key={product.product_id} className={Styles.productListItem}>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Checkbox
                              checked={selectedProducts[product.product_id]?.selected || false}
                              onChange={e => handleSelectProduct(product.product_id, e.target.checked)}
                              className={Styles.checkbox}
                            >
                              <Avatar src={product.prod_images[0]} />
                              {" "}
                              {product.product_name}
                              
                            </Checkbox>
                            <Typography variant="caption" justifyContent={"flex-end"} mt={1}><b>Available Quantity :</b> {product?.total_unassigned_quantity}</Typography>
                          </div>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            {contractType === 'commission_percentage' && (
                              <Input
                                className={Styles.formInput}
                                placeholder="Commission rate(%)"
                                type="number"
                                onWheel={handleScroll}
                                value={selectedProducts[product.product_id]?.commission_rate || ""}
                                onChange={e => handleCommissionChange(product.product_id, e.target.value)}
                                disabled={!selectedProducts[product.product_id]?.selected}
                                required={selectedProducts[product.product_id]?.selected}
                              />
                            )}
                            {(contractType === 'fixed_amount') && (
                              <>
                                <Input
                                  className={Styles.formInput}
                                  placeholder="Fixed amount"
                                  type="number"
                                  onWheel={handleScroll}
                                  value={selectedProducts[product.product_id]?.fixed_amount || ""}
                                  onChange={e => handlelumpSumChange(product.product_id, e.target.value)}
                                  disabled={!selectedProducts[product.product_id]?.selected}
                                  required={selectedProducts[product.product_id]?.selected}
                                />

                                <Select
                                  value={
                                    selectedProducts[product.product_id]?.advance_amount_percentage ||
                                    10
                                  }
                                  onChange={(value) => handlePercentageChange_np(product.product_id, value)}
                                  style={{ width: 150 }}
                                  className={Styles.formSelect}
                                  placeholder={!selectedProducts[product.product_id]?.selected ? "Advance %" : "%"}
                                  disabled={!selectedProducts[product.product_id]?.selected}
                                >
                                  <Option value={10}>10%</Option>
                                  <Option value={25}>25%</Option>
                                  <Option value={50}>50%</Option>
                                  <Option value={75}>75%</Option>
                                  <Option value={100}>100%</Option>
                                </Select>

                              </>
                            )}
                            <Input
                              className={Styles.formInput}
                              placeholder="Target Qty"
                              type="number"
                              onWheel={handleScroll}
                              value={selectedProducts[product.product_id]?.target_quantity || ""}
                              onChange={e => handleTargetQtyChange(product.product_id, e.target.value)}
                              disabled={!selectedProducts[product.product_id]?.selected}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </Form.Item>
              )}


              <Row>
                <Col span={12}>
                  <Form.Item
                    name="collaboration_start_date"
                    label="Start Date"
                    rules={[
                      { required: true, message: "Please select start date" },
                    ]}
                  >
                    <DatePicker
                      className={Styles.formInput}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="collaboration_duration_days"
                    label="Duration"
                    rules={[
                      { required: true, message: "Please enter duration" },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (!/^\d+$/.test(value)) {
                            return Promise.reject("Please enter a valid non-negative integer");
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Input
                      className={Styles.formInput}
                      placeholder="Enter duration in days"
                      onWheel={handleScroll}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item>
                <Button htmlType="submit"
                  className={Styles.addButton}
                  style={{ marginBottom: "10px" }}
                  disabled={collabType === 'product_based' && myProducts.length === 0}
                >
                  Send Request
                </Button>
                <br />
                {collabType === 'product_based' && myProducts.length === 0 && <Typography variant="caption" color={"red"}>Please add more products to the store to send this collaboration!</Typography>}
                {/* <Button htmlType="submit" className={Styles.addButton}>
                  Send Request
                </Button> */}
              </Form.Item>
            </Form>
          </div>
        </Drawer>
      )}

      {openDrawer && (
        <Drawer
          title=" "
          placement="right"
          closable={false}
          //   onClose={onCloseDrawerHandler}
          onClose={onCloseDrawerHandler}
          open={openDrawer}
          key="right"
          width={"550px"}
          extra={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                onClick={handleSendMessage}
                style={{ cursor: "pointer", marginTop: "1rem" }}
                src={DrawerMessage}
                alt=""
              />

              <img
                style={{ cursor: "pointer", marginTop: "1rem" }}
                src={DrawerLocation}
                alt=""
              />

              <img
                style={{ cursor: "pointer", marginTop: "1rem" }}
                onClick={(event) => {
                  event.preventDefault();
                }}
                src={DrawerSaved}
                alt=""
              />

              <Button
                onClick={onCloseDrawerHandler}
                className="elevatedElement iconBtn"
              >
                <Cross />
              </Button>
            </div>
          }
        >
          <div className={FreelancerProfileStyle.blueSection}>
            <Row>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={6}>{renderAvatar()}</Col>
                  <Col
                    span={6}
                    className={FreelancerProfileStyle.freelancerNameText}
                  >
                    <div>
                      {_.get(freelancerProfile, "first_name", "") +
                        " " +
                        _.get(freelancerProfile, "last_name", "")}
                    </div>
                  </Col>
                  <Col span={11}>
                    <div className={FreelancerProfileStyle.bigDiv}>
                      <div className={FreelancerProfileStyle.smallDiv}>
                        <h3>{_.get(freelancerProfile, "numberOfPosts", "")}</h3>

                        <div>Posts</div>
                      </div>

                      <div className={FreelancerProfileStyle.smallDiv}>
                        <h3>
                          {_.get(freelancerProfile, "totalFollowers", "")}K
                        </h3>
                        <div>Followers</div>
                      </div>

                      <div className={FreelancerProfileStyle.smallDiv}>
                        <h3>{_.get(freelancerProfile, "followings", "")}K</h3>
                        <div>Following</div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={11}>
                    {_.get(freelancerProfile, "open_for_collaboration", "") ==
                      true ? (
                      <div className={FreelancerProfileStyle.collabDiv}>
                        Open For Collaboration
                      </div>
                    ) : (
                      <div className={FreelancerProfileStyle.noCollabDiv}>
                        Open For Collaboration
                      </div>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div
                      className={FreelancerProfileStyle.profileTextSec}
                      dangerouslySetInnerHTML={{
                        __html: _.get(freelancerProfile, "user_bio", ""),
                      }}
                    ></div>
                  </Col>
                </Row>
                <Row gutter={2}>
                  {categoriesDataOldData &&
                    categoriesDataOldData.length > 0 &&
                    categoriesDataOldData.map((items: any) => {
                      return (
                        <Col span={6}>
                          <button
                            className={FreelancerProfileStyle.categoryButton}
                          >
                            {items}
                          </button>
                        </Col>
                      );
                    })}
                </Row>
                <Row style={{ marginTop: "20px" }}>
                  <Col span={8}>
                    <Button
                      className={Styles.addButton}
                      onClick={() => {
                        openModal(reqRow);
                        onCloseDrawerHandler();
                      }}
                    >
                      Send Request
                    </Button>
                  </Col>
                  <Col span={16}>
                    <div className={FreelancerProfileStyle.socialDiv}>
                      <img style={{ width: "100%" }} src={Social} alt=""></img>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Tabs defaultActiveKey="2" centered>
            <TabPane tab="Posts" key="1" disabled></TabPane>
            <TabPane tab="Services" key="2">
              <Row gutter={18}>
                {freelancerProfile.freelancerServices &&
                  Array.isArray(freelancerProfile.freelancerServices) ? (
                  freelancerProfile.freelancerServices.map((items: any) => (
                    <Col span={12} key={items.service_category_main}>
                      <FreelancerServiceCard
                        serviceImage={items.service_images[0]}
                        serviceCategory={items.service_category_main}
                        serviceCurrency={items.service_balance_amount_ccy}
                        duration={items.service_duration}
                      />
                    </Col>
                  ))
                ) : (
                  <p>No services available</p>
                )}
              </Row>
            </TabPane>

            <TabPane tab="Stores" key="3" disabled></TabPane>
          </Tabs>
        </Drawer>
      )}
    </>
  );
};

export default CollabTable;
