const { initializeApp } = require("firebase/app");
const { getMessaging, getToken, onMessage } = require("firebase/messaging");
const { notification } = require("antd");

const firebaseConfig = {
  apiKey: "AIzaSyDo1KuAyaTTWIGSZIJfxNmE_n5cuVIVlmQ",
  authDomain: "octopusmarketplace-mja.firebaseapp.com",
  projectId: "octopusmarketplace-mja",
  storageBucket: "octopusmarketplace-mja.firebasestorage.app",
  messagingSenderId: "388874215308",
  appId: "1:388874215308:web:f24b374119b5d6efaf80a9",
  measurementId: "G-GWN7HJ3PG6",
};

const vapidKey =
  "BKMM2BFntggdiT6Hqez0KPOc5lc6udblFiE9GlYK9ePFZS0LDuY_FN-qaCPp1XGoHPsdXegLWC55v5zGSpjuebU";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestFCMToken = async () => {
  return Notification.requestPermission()
    .then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, { vapidKey });
      } else {
        throw new Error("User denied notification permission");
      }
    })
    .catch((error) => {
      console.error("Error requesting FCM token:", error);
      throw error;
    });
};

export const onMessageListener = () => {
  return new Promise((resolve, reject) => {
    onMessage(
      messaging,
      (payload) => {
        console.log("Foreground Notification Received:", payload);

        if (payload.notification) {
          const { title, body, image } = payload.notification;
          console.log(`Notification Received:\nTitle: ${title}\nBody: ${body}`);
          notification.open({
            message: title,
            description: (
              <div>
                <p>{body}</p>
                {image && (
                  <img
                    src={image}
                    alt="Notification"
                    style={{
                      width: "100%",
                      maxWidth: "300px",
                      marginTop: "10px",
                    }}
                  />
                )}
              </div>
            ),
            placement: "topRight",
            duration: 5,
          });
        }

        resolve(payload);
      },
      reject
    );
  });
};
