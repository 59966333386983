import React, { useEffect, useState } from "react";
import CustomLayout from "../../../common/CustomLayout/CustomLayout";
import Card from "../../../components/Card/Card";
import _ from "lodash";
import "./Categories.css";
import CustomButton from "../../../components/Button/Button";
import { useHistory } from "react-router";
import { API_BASE_URL, APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { _POST, getRole, zoho_user_id } from "../../../common/commonFunc";
import { Button, Checkbox, Col, Modal, Row, notification } from "antd";
import TermsAndConditions from "../../TermsAndConditions/TermsAndConditions";
import { STORE } from "../../../redux/store";
import ModalTick from "../../../assests/customIcons/ModalTick";
import SocialMediaInput from "./SocialMediaInput";

const Categories = () => {
  const history = useHistory();
  const [selectedCate, setSetselectedCate] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState(false);

  const [openPrimary, setOpenPrimary] = useState(false);
  const [openSecondary, setOpenSecondary] = useState(false);
  const [primaryChecked, setPrimaryChecked] = useState(false);

  const [socialMediaLinks, setSocialMediaLinks] = useState<any[]>([
    { platform: "instagram", profile_link: "" },
  ]);

  const [secondaryChecked, setSecondaryChecked] = useState(false);
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [visibleCategories, setVisibleCategories] = useState(5);

  const userRole: any = getRole();

  const loadMoreCategories = () => {
    setVisibleCategories((prev) => prev + 5);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}getAllCategories`
      );
      const result = await response.json();
      if (result.status) {
        const filteredCategories = result.data.filter(
          (category: any) => category.category_logo_image !== "NULL"
        );
        setCategoriesData(filteredCategories);
      } else {
        notification.error({ message: "Failed to fetch categories" });
      }
    } catch (error) {
      notification.error({ message: "Error fetching categories" });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const documents: any = {
    user: "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Client).pdf",
    freelancer:
      "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Service+Provider).pdf",
    supplier:
      "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/T%26C_OC_V3.4+(Supplier).pdf",
  };

  const commissionAgreement =
    "https://octopus-internal-docs-public.s3.me-south-1.amazonaws.com/CA_OC_V2.0.pdf";

  const primaryDocument = documents[userRole];

  const onChangecategoriesHadler = (value: any) => {
    let updatedArray = [...selectedCate];
    const index = updatedArray.indexOf(value);

    if (index === -1) {
      updatedArray.push(value);
    } else {
      updatedArray.splice(index, 1);
    }

    setSetselectedCate(updatedArray);
  };

  const onFinishHandler = async () => {
    try {
      if (userRole === "freelancer" || userRole === "supplier") {
        if (!primaryChecked || !secondaryChecked) {
          notification.open({
            type: "error",
            message: "Please agree to all terms and conditions.",
            description:
              "You must agree to both the terms and conditions and the commission agreement.",
          });
          return;
        }
      } else {
        if (!primaryChecked) {
          notification.open({
            type: "error",
            message: "Please agree to the terms and conditions.",
            description: "You must agree to the terms and conditions.",
          });
          return;
        }
      }

      if (selectedCate && selectedCate.length === 0) {
        notification.open({
          type: "error",
          message: "Please choose at least one category.",
          description:
            "Please select at least one of the following categories.",
        });
        return;
      }

      if (userRole !== "supplier") {
        const responseProfile = await _POST(
          `userProfile/social-media`,
          socialMediaLinks,
          true,
          false
        );
        if (
          responseProfile?.status !== true ||
          responseProfile?.statusCode !== 200
        ) {
          notification.open({
            type: "error",
            message: "Failed to update social media links.",
            description: responseProfile.message || "Unknown error occurred.",
          });
          return;
        }
      }

      const res = await _POST(
        `updateCategories/${localStorage.getItem("userEmail")}`,
        {
          categories: selectedCate,
          zoho_user_id: zoho_user_id,
          role: getRole(),
          agreedToTermsAndCondition: primaryChecked,
        },
        false
      );

      if (res.status) {
        setModalVisible(true);
      }
    } catch (E) {
      console.error(E);
    }
  };

  const handleModalOk = () => {
    setModalVisible(false);
    if (userRole === "freelancer") {
      history.push(APPLICATION_ROUTES.DASHBOARD);
    } else {
      history.push(APPLICATION_ROUTES.LOGIN_HOME);
    }
  };

  const handleSocialMediaChange = (updatedLinks: any) => {
    const formattedLinks = updatedLinks.map((link: any) => {
      let baseURL = "";

      switch (link.platform) {
        case "instagram":
          baseURL = "https://instagram.com/";
          break;
        case "facebook":
          baseURL = "https://facebook.com/";
          break;
        case "twitter":
          baseURL = "https://twitter.com/";
          break;
        case "tiktok":
          baseURL = "https://tiktok.com/@";
          break;
        default:
          baseURL = "";
      }

      return {
        platform: link.platform,
        profile_link: `${baseURL}${link.profile_link}`,
      };
    });

    setSocialMediaLinks(formattedLinks);
  };

  return (
    <CustomLayout
      heading1={userRole === "freelancer" ? "" : "Preferences"}
      heading2=""
    >
      <div className="categoriesContainer">
        <h3>{userRole === "freelancer" ? "Categories & Social Media" : ""}</h3>
        <Row>
          <div>
            <Col className="categoriesContainer">
              {_.map(categoriesData.slice(0, visibleCategories), (item) => {
                const isChecked = selectedCate.includes(item.category_name);
                return (
                  <Card
                    key={item.category_name}
                    logo={item.category_logo_image}
                    title={item.category_name}
                    onSelect={onChangecategoriesHadler}
                    defaultChecked={isChecked}
                  />
                );
              })}
            </Col>
            {categoriesData.length > visibleCategories && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <Button onClick={loadMoreCategories}>Load More</Button>
              </div>
            )}
          </div>

          {categoriesData.length === 0 && (
            <Col className="categoriesContainer">
              <h3>No categories found</h3>
            </Col>
          )}
          {userRole !== "supplier" ? (
            <div
              className="socialMediaProfileContainer"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
                margin: "auto",
                minWidth: "80%",
              }}
            >
              <SocialMediaInput onChange={handleSocialMediaChange} />
            </div>
          ) : null}
        </Row>
        <br />
        <br />
        <br />
        <div style={{ width: "80%", margin: "20px 0px" }}>
          <div className="CategoriesTermAndConditionsContainer">
            <Checkbox
              checked={primaryChecked}
              onChange={(e) => setPrimaryChecked(e.target.checked)}
              rootClassName="tncCheckbox"
            />
            &nbsp;&nbsp;&nbsp;
            <p onClick={() => setOpenPrimary(true)}>
              I agree to the Terms And Conditions
            </p>
          </div>
          {userRole === "freelancer" || userRole === "supplier" ? (
            <div className="CategoriesTermAndConditionsContainer">
              <Checkbox
                checked={secondaryChecked}
                onChange={(e) => setSecondaryChecked(e.target.checked)}
                rootClassName="tncCheckbox"
              />
              &nbsp;&nbsp;&nbsp;
              <p onClick={() => setOpenSecondary(true)}>
                I agree to the Commission Agreement
              </p>
            </div>
          ) : null}
          <TermsAndConditions
            documentUrl={primaryDocument}
            setisTCChecked={setPrimaryChecked}
            open={openPrimary}
            setOpen={setOpenPrimary}
          />
          {(userRole === "freelancer" || userRole === "supplier") && (
            <TermsAndConditions
              documentUrl={commissionAgreement}
              setisTCChecked={setSecondaryChecked}
              open={openSecondary}
              setOpen={setOpenSecondary}
            />
          )}
          <CustomButton
            disabled={
              (userRole !== "supplier" &&
                !socialMediaLinks.some((link: any) => {
                  if (link.platform === "instagram") {
                    const username = link.profile_link
                      .replace("https://instagram.com/", "")
                      .trim();
                    return username !== "";
                  }
                  return false;
                })) ||
              (userRole === "supplier" &&
                (!primaryChecked || !secondaryChecked)) ||
              !primaryChecked
            }
            text="Submit"
            onFinish={onFinishHandler}
          />
        </div>
      </div>

      <Modal
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          history.push(APPLICATION_ROUTES.LOGIN_HOME);
        }}
        footer={null}
        rootClassName="modalRoot"
        centered
      >
        <div style={{ textAlign: "center" }}>
          <ModalTick />
          <h2>Thank You for Your Submission!</h2>
          <p>
            {userRole === "freelancer"
              ? "Your application is under review. We will notify you once approved."
              : "Your account has been successfully registered."}
          </p>
        </div>
      </Modal>
    </CustomLayout>
  );
};

export default Categories;
