import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory, matchPath } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Styles from "./DashboardNavBar.module.css";
import { Col, Row, Menu, Dropdown, Drawer, Button } from "antd";
import { CircularProgress, Popover } from "@mui/material";
import { MenuOutlined } from "@ant-design/icons";
import OctopusNavLogo from "../../assests/headerLogoSupplier.svg";
import {
  NavbarRoutesAll,
  NavbarRoutesGuest,
  NavbarRoutesProvider,
} from "../../constant/NavbarRoutes";
import SearchBtn from "../SearchBtn/SearchBtn";
import Chat from "../../assests/customIcons/Chat";
import NotificationIcon from "../../assests/customIcons/Notification";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { RootState, STORE } from "../../redux/store";
import Logo from "../../assests/logo.svg";
import ServicesLogo from "../../assests/Services.svg";
import StoreLogo from "../../assests/Store.svg";
import OrderLogo from "../../assests/Booking.svg";
import ChatLogo from "../../assests/Chats.svg";
import EventLogo from "../../assests/Event.svg";
import MobileSearchBtn from "../SearchBtn/MobileSearchBtn";
import UserIcon from "../../assests/UserIcon.svg";
import LogOutIcon from "../../assests/logOutSvg.svg";
import logoMain from "../../assests/logo.svg";
import {
  _DELETE,
  _DELETENXWithBody,
  _GET,
  _GETNX,
  _PATCHNX,
  _PATCHNXWithBody,
  _POST,
  getRole,
  getUserId,
} from "../commonFunc";
import FilterModal from "../SearchBtn/Filter/Filtermodal";
import { useSearch } from "./Search/SearchContext";
import ChatIcon from "../../assests/customIcons/ChatIcon";
import { ShoppingCartCheckout } from "@mui/icons-material";
import CartDrawer from "./cart/Cart";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  fetchCartItems,
  removeFromCart,
} from "../../redux/actions/cartActions";
import LoginPopUp from "../../pages/Login/LoginPopup";
import NotificationBanner from "./notificationBenner/noti";
import { onMessageListener, requestFCMToken } from "../../firebase";
import NotificationsList from "./Notifications/Notifications";
import axios from "axios";
import { useNotifications } from "../../context/NotificationsContext";

const defaultUserDetails = {
  first_name: "Guest",
  last_name: "",
  profile_pic: "https://picsum.photos/200",
  id: "undefined",
};

const DashboardNavBar = ({
  showFilters,
  toggleFilters,
  filterTypes,
  suggestions,
}: any) => {
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const userId = getUserId();
  const dispatch = useDispatch();
  const history = useHistory();
  const [appliedFilters, setAppliedFilters] = useState<any>([]);
  const { setSearchQuery, setFilters } = useSearch();
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

  const [fcmToken, setFcmToken] = useState<string | null>(null);
  // const [notifications, setNotifications] = useState<any[]>([]);
  const [notificationQueue, setNotificationQueue] = useState<any[]>([]);
  // const [notifications, setNotifications] = useState<any>([]);
  // const [unreadCount, setUnreadCount] = useState(0);

  const { notifications, unreadCount, setNotifications } = useNotifications();

  const fetchUserNotifications = async (userId: string) => {
    if (!userId || userId === "undefined") {
      console.warn("Invalid userId. Skipping notification fetch.");
      return null;
    }
    const endpoint = `notification/users/fetch/notify?userId=${userId}`;
    try {
      const response = await _GETNX(endpoint, true);
      console.log("User notifications fetched successfully:", response);
      return response.data;
    } catch (error) {
      console.error("Error fetching user notifications:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const notifications = await fetchUserNotifications(userId);
      if (notifications) {
        setNotifications(notifications);
        const unread = notifications.filter((n: any) => !n.is_viewed).length;
        // setUnreadCount(unread);
      }
    };
    fetchNotifications();
  }, [userId]);

  useEffect(() => {
    const unread = notifications.filter(
      (notification: any) => !notification.is_viewed
    ).length;
    // setUnreadCount(unread);
  }, [notifications]);

  const [doNotDisturb, setDoNotDisturb] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleNotificationClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const markNotificationAsViewed = async (notificationId: string) => {
    const endpoint = `notification/users/view/notify?notify_id=${notificationId}`;
    try {
      const response = await _PATCHNXWithBody(endpoint, {}, true, true);
      if (response && response.status) {
        console.log("Notification marked as viewed:", response);
        return true;
      }
    } catch (error) {
      console.error("Error marking notification as viewed:", error);
    }
    return false;
  };

  const handleNotificationAction = async (
    action: string,
    notification: any
  ) => {
    if (action === "markAsRead") {
      const success = await markNotificationAsViewed(notification.id);
      if (success) {
        setNotifications((prev: any) =>
          prev.map((n: any) =>
            n.id === notification.id ? { ...n, is_viewed: true } : n
          )
        );
      }
    } else if (action === "delete") {
      setNotifications((prev: any) =>
        prev.filter((n: any) => n.id !== notification.id)
      );
    } else if (action === "open") {
      alert(`Opening notification: ${notification.message}`);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const filterModalVariants = {
    hidden: { opacity: 0, translateY: -50, translateZ: 0 },
    visible: { opacity: 1, translateY: 0, translateZ: 0 },
  };

  const globalCartItems = useSelector(
    (state: RootState) => state.globalCart.items
  );
  const handleCloseLoginPopup = () => setIsLoginPopupOpen(false);
  const handleOpenLoginPopup = () => setIsLoginPopupOpen(true);

  useEffect(() => {
    dispatch(fetchCartItems());
  }, [dispatch]);

  const handleAddToCart = (item: any) => {
    dispatch(addToCart(item));
  };

  const handleRemoveFromCart = (itemId: string) => {
    dispatch(removeFromCart(itemId));
  };

  const handleOpenCart = () => {
    setIsCartOpen(true);
  };

  const handleCloseCart = () => {
    setIsCartOpen(false);
  };

  const handleFilterApply = (filters: any) => {
    setAppliedFilters(filters);
    setFilters(filters);
    // toggleFilters(false);
  };

  const toggleModal = () => {
    toggleFilters(!toggleFilters);
  };

  const [userRole, setUserrole] = useState(getRole());
  useEffect(() => {
    const userrole: any = getRole();
    setUserrole(userrole);
  });

  const NavbarRoutes =
    userRole === "guest"
      ? NavbarRoutesGuest
      : userRole === "freelancer"
      ? NavbarRoutesProvider
      : NavbarRoutesAll;

  const UserDetails = JSON.parse(UserDetailsLS)
    ? JSON.parse(UserDetailsLS)
    : defaultUserDetails;
  const [avatarColor, setAvatarColor] = useState("");

  if (!UserDetailsLS) {
    localStorage.setItem("userInfo", JSON.stringify(defaultUserDetails));
    localStorage.setItem("octo_role", "guest");
  }

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  useEffect(() => {
    // Retrieve the color from Local Storage or generate a new one
    let color = localStorage.getItem("avatarColor");
    if (!color) {
      color = getRandomColor();
      localStorage.setItem("avatarColor", color);
    }
    setAvatarColor(color);
  }, []);

  const renderAvatar = () => {
    if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: avatarColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = () => {
    setLoggingOut(true);

    const userInfo = localStorage.getItem("userInfo");
    const fcmToken = localStorage.getItem("fcmToken");

    let payload = {
      user_id: "",
      fcm_token: fcmToken || "",
    };

    try {
      if (userInfo) {
        const parsedUserInfo = JSON.parse(userInfo);
        payload.user_id = parsedUserInfo?.id || "";
      }
    } catch (error) {}

    _DELETENXWithBody(`notification/remove/fcm`, payload, true, true)
      .then((response) => {
        setLoggingOut(false);
        STORE.dispatch({ type: "LOGOUT_USER" });
        window.location.href = APPLICATION_ROUTES.LOGIN;
      })
      .catch(() => {
        setLoggingOut(false);
        STORE.dispatch({ type: "LOGOUT_USER" });
        window.location.href = APPLICATION_ROUTES.LOGIN;
      })
      .finally(() => {
        setLoggingOut(false);
      });
  };

  const handleProfileSettings = () => {
    history.push(APPLICATION_ROUTES.PROFILE);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const dynamicRouteMap: any = {
    "/productDetails": "/store",
    "/service": "/service",
    "/eventDetails": "/events",
  };

  const getMappedRoute = (pathname: any) => {
    for (const key in dynamicRouteMap) {
      if (pathname.startsWith(key)) {
        return dynamicRouteMap[key];
      }
    }
    return pathname;
  };

  const hiddenRoutes = [
    "/login",
    "/register",
    "/service/:id",
    "/eventDetails/:id",
  ];

  const isRouteHidden = (pathname: any) => {
    return hiddenRoutes.some((route) =>
      matchPath(pathname, { path: route, exact: true })
    );
  };

  const shouldHideNavBarBottom = isRouteHidden(location.pathname);

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <div
              onClick={handleProfileSettings}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "25px",
                margin: 0,
              }}
            >
              <img
                src={UserIcon}
                alt=""
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: "#F5F4FA",
                  padding: "2px",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
              <p>Profile</p>
            </div>
          ),
        },
        {
          key: "2",
          label: (
            <div
              onClick={handleLogout}
              style={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "25px",
                margin: 0,
              }}
            >
              <img
                src={LogOutIcon}
                alt=""
                style={{
                  height: "15px",
                  width: "15px",
                  backgroundColor: "#F5F4FA",
                  padding: "2px",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
              <p>Logout</p>
            </div>
          ),
        },
      ]}
    />
  );

  function parseUserAgent(userAgent: any) {
    let deviceType = "unknown";
    let deviceModel = "unknown";

    if (userAgent.includes("Macintosh")) {
      deviceType = "Mac";
      const macVersionMatch = userAgent.match(/Mac OS X (\d+_\d+_\d+)/);
      if (macVersionMatch) {
        deviceModel = `macOS ${macVersionMatch[1].replace(/_/g, ".")}`;
      }
    } else if (userAgent.includes("Windows")) {
      deviceType = "Windows";
    } else if (userAgent.includes("Android")) {
      deviceType = "Android";
    } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
      deviceType = "iOS";
    }

    return { deviceType, deviceModel };
  }

  const fetchFCMToken = async () => {
    try {
      const userID = await getUserId();
      if (userID !== "undefined" && userID !== undefined && userID !== null) {
        const token = await requestFCMToken();
        const storedToken = localStorage.getItem("fcmToken");

        if (storedToken !== token) {
          if (fcmToken !== token) {
            setFcmToken(token);

            const deviceInfo = navigator.userAgent;

            const { deviceType, deviceModel } = parseUserAgent(deviceInfo);

            let payload = {
              user_id: userID,
              fcm_token: token,
              device_type: deviceType,
              device_model: deviceModel,
            };

            const storeToken = await _POST(
              "notification/token/users",
              payload,
              false,
              false
            );

            if (storeToken.status === true) {
              console.log("Stored");
              localStorage.setItem("fcmToken", token);
            } else {
              localStorage.setItem("fcmToken", token);
            }
          }
        }
      } else {
        console.log("User ID not found, can't fetch FCM token.");
      }
    } catch (error) {
      console.error("Error fetching FCM token:", error);
    }
  };

  const [showBanner, setShowBanner] = useState(true);
  const [noPermission, setNoPermission] = useState(false);

  useEffect(() => {
    const checkNotificationPermission = async () => {
      try {
        // @ts-ignore
        const permission = Notification.permission;
        const dismissalStatus = localStorage.getItem("notificationPermission");

        if (dismissalStatus === "dismissed") {
          setNoPermission(true);
        }

        if (permission === "granted" || dismissalStatus === "dismissed") {
          setShowBanner(false);
          fetchFCMToken();
        }
      } catch (error) {
        console.error("Error checking notification permission:", error);
      }
    };

    checkNotificationPermission();
  }, []);

  const handlePermissionGranted = () => {
    fetchFCMToken();
    localStorage.setItem("notificationPermission", "granted");
    setShowBanner(false);
    setNoPermission(false);
  };

  const handleDismiss = () => {
    localStorage.setItem("notificationPermission", "dismissed");
    setShowBanner(false);
    setNoPermission(true);
  };

  useEffect(() => {
    const setupForegroundNotificationListener = async () => {
      onMessageListener()
        .then((payload) => {
          const { notification } = payload;

          if (notification) {
            const newNotification = {
              title: notification?.title,
              body: notification?.body,
              image: notification?.image,
            };
            setNotificationQueue((prevQueue) => [
              ...prevQueue,
              newNotification,
            ]);
          } else {
            console.warn(
              "Notification payload is missing 'notification' object"
            );
          }
        })
        .catch((error) => {
          console.error("Error in foreground notification listener:", error);
        });
    };

    setupForegroundNotificationListener();
  }, []);

  return (
    <>
      {showBanner && userRole !== "guest" && (
        <NotificationBanner
          onPermissionGranted={handlePermissionGranted}
          onDismiss={handleDismiss}
        />
      )}

      <div
        className={`${Styles.dashboardNavBarContainer} ${
          showFilters ? Styles.open : ""
        } ${showBanner && userRole !== "guest" ? Styles.showBanner : ""}`}
      >
        <Row className={Styles.navbarRow}>
          <Col span={3}>
            <img
              src={OctopusNavLogo}
              alt=""
              style={{ width: "90%", height: "90%", cursor: "pointer" }}
              onClick={() => history.push(APPLICATION_ROUTES.DASHBOARD)}
            />
          </Col>
          <Col span={4} style={{ marginRight: "10px" }}>
            <SearchBtn
              filterTypes={filterTypes}
              toggleFilters={toggleFilters}
              suggestions={suggestions}
            />
          </Col>
          <Col span={15} className={Styles.rightNav}>
            <Row style={{ width: "100%", height: "100%" }}>
              <div className={Styles.navLinks}>
                {NavbarRoutes.map(({ id, route, label }) => {
                  const currentRoute = getMappedRoute(location.pathname);
                  const isRouteActive = currentRoute === route;

                  return (
                    <Link
                      to={route}
                      className={`${Styles.navLink} ${
                        isRouteActive ? Styles.activeLink : ""
                      }`}
                      key={id}
                      style={{
                        pointerEvents: label !== "Store" ? "auto" : "auto",
                      }}
                    >
                      {label}
                    </Link>
                  );
                })}
              </div>

              <div className={Styles.navIcons}>
                {userRole !== "freelancer" && (
                  <button
                    onClick={() =>
                      userRole !== "guest"
                        ? handleOpenCart()
                        : history.push(APPLICATION_ROUTES.LOGIN)
                    }
                    className="elevatedElement iconBtn"
                    style={{ position: "relative" }}
                  >
                    <ShoppingCartCheckout />
                    <div className={Styles.countOnIcon}>
                      {globalCartItems.length || 0}
                    </div>
                  </button>
                )}
                {userRole !== "guest" && (
                  <button
                    onClick={() =>
                      userRole !== "guest"
                        ? history.push(APPLICATION_ROUTES.CHAT_PAGE)
                        : history.push(APPLICATION_ROUTES.LOGIN)
                    }
                    className="elevatedElement iconBtn"
                    style={{ position: "relative" }}
                  >
                    <ChatIcon />
                    <div className={Styles.countOnIcon}>0</div>
                  </button>
                )}
                {userRole !== "guest" && (
                  <button
                    // onClick={() => {}}
                    className="elevatedElement iconBtn"
                    style={{ position: "relative" }}
                    onClick={handleNotificationClick}
                  >
                    <NotificationIcon />
                    {noPermission ? (
                      <div
                        onClick={() => setShowBanner(true)}
                        className={Styles.newNotification}
                      >
                        New
                      </div>
                    ) : (
                      <div className={Styles.countOnIcon}>{unreadCount}</div>
                    )}
                  </button>
                )}

                {userRole !== "guest" ? (
                  loggingOut ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Dropdown
                      overlay={menu}
                      placement="bottomCenter"
                      trigger={["click"]}
                    >
                      <button
                        className="elevatedElement iconBtn"
                        style={{
                          padding: 5,
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                      >
                        {renderAvatar()}
                      </button>
                    </Dropdown>
                  )
                ) : (
                  <Link to="/login" className={Styles.navLink}>
                    Login/SignUp
                  </Link>
                )}
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    sx: {
                      mt: 2,
                      borderRadius: 3,
                      backgroundColor: "transparent",
                      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                      border: "none",
                    },
                  },
                }}
              >
                <NotificationsList
                  notifications={notifications}
                  onMarkAllAsRead={markNotificationAsViewed}
                  setNotifications={setNotifications}
                  onNotificationAction={handleNotificationAction}
                  doNotDisturb={doNotDisturb}
                  onToggleDoNotDisturb={() => setDoNotDisturb((prev) => !prev)}
                />
              </Popover>
            </Row>
          </Col>
        </Row>
        <AnimatePresence>
          {showFilters && (
            <motion.div
              className={Styles.filterOptions}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={filterModalVariants}
              transition={{ duration: 0.3, ease: "backInOut" }}
            >
              <FilterModal
                filterOptions={filterTypes}
                initialFilters={appliedFilters}
                onApply={handleFilterApply}
                onCancel={toggleModal}
                isVisible={showFilters}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {!shouldHideNavBarBottom && (
        <div className={Styles.dashboardNavBarContainerBottom}>
          <Row className={Styles.navbarRow}>
            <Col span={4}>
              <Link
                className={
                  location.pathname === "/service" ? "selectedLink" : ""
                }
                to={"/service"}
                style={{
                  color: location.pathname === "/service" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={ServicesLogo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/service"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/service" ? "selectedLink" : ""
                    }
                    to={"/service"}
                    style={{
                      color: location.pathname === "/service" ? "" : "gray",
                    }}
                  >
                    Services
                  </Link>
                </div>
              </Link>
            </Col>
            <Col span={4} style={{ pointerEvents: "none", opacity: 0.5 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={StoreLogo}
                  alt=""
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: "grayscale(100%)",
                  }}
                />

                <Link style={{ color: "gray" }} to={"/service"}>
                  Store
                </Link>
              </div>
            </Col>

            <Col span={4}>
              <Link
                className={
                  location.pathname === "/dashboard" ? "selectedLink" : ""
                }
                to={"/dashboard"}
                style={{
                  color: location.pathname === "/dashboard" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Logo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/dashboard"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/dashboard" ? "selectedLink" : ""
                    }
                    to={"/dashboard"}
                    style={{
                      color: location.pathname === "/dashboard" ? "" : "gray",
                    }}
                  >
                    Home
                  </Link>
                </div>
              </Link>
            </Col>

            <Col span={4}>
              <Link
                className={location.pathname === "/order" ? "selectedLink" : ""}
                to={"/order"}
                style={{
                  color: location.pathname === "/order" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={OrderLogo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/order"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/order" ? "selectedLink" : ""
                    }
                    to={"/order"}
                    style={{
                      color: location.pathname === "/order" ? "" : "gray",
                    }}
                  >
                    Order
                  </Link>
                </div>
              </Link>
            </Col>

            <Col span={4}>
              <Link
                className={location.pathname === "/evens" ? "selectedLink" : ""}
                to={"/events"}
                style={{
                  color: location.pathname === "/events" ? "" : "gray",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={EventLogo}
                    alt=""
                    style={{
                      width: "35px",
                      height: "35px",
                      filter:
                        location.pathname === "/events"
                          ? "grayscale(0%)"
                          : "grayscale(100%)",
                    }}
                  />

                  <Link
                    className={
                      location.pathname === "/evens" ? "selectedLink" : ""
                    }
                    to={"/events"}
                    style={{
                      color: location.pathname === "/events" ? "" : "gray",
                    }}
                  >
                    Events
                  </Link>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      )}
      <CartDrawer visible={isCartOpen} onClose={handleCloseCart} />
      {/* <LoginPopUp open={isLoginPopupOpen} onClose={handleCloseLoginPopup} /> */}
    </>
  );
};

export default DashboardNavBar;
