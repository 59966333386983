import React from "react";
import "./Card.css";
import { Checkbox } from "antd";

type ICard = {
  logo: string;
  title: string;
  defaultChecked?: boolean;
  onSelect: (value: string) => void;
  isEditable?: boolean; // Optional property
};

const Card = ({
  logo,
  title,
  onSelect,
  defaultChecked,
  isEditable = true,
}: ICard) => {
  return (
    <div className="card">
      <div>
        {logo ? (
          <img
            src={logo}
            alt={title}
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
        ) : (
          // Display the first character of title as a placeholder
          <div
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #ccc",
              borderRadius: "5px",
              background: "linear-gradient(92.54deg, #80f, #00b2ff)",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            {title.charAt(0).toUpperCase()}
          </div>
        )}
      </div>
      <div>{title}</div>
      {/* Checkbox is disabled if isEditable is false */}
      <Checkbox
        checked={defaultChecked}
        onChange={() => isEditable && onSelect(title)}
        disabled={!isEditable}
      />
    </div>
  );
};

export default Card;
