import React from "react";
import { Button } from "antd";
import { getCapitalize } from "../../../utils/helpers";
import Direction from "../../../assests/customIcons/Direction";
import styles from "./EventCard.module.css";
import moment from "moment";

const EventCard = React.memo(({ booking, isReserved }: any) => {
  console.log("BOOKING DATA", booking);
  const isService = booking?.type === "SERVICE_SLOT";
  const isEvent = booking?.type === "EVENT_SLOT";
  const isRFQ = booking?.is_quoted_service;

  let serviceAddress = booking?.service_location?.address || "";
  if (!serviceAddress && booking?.service_location) {
    try {
      const locationData = JSON.parse(booking?.service_location);
      serviceAddress = locationData?.address || "";
    } catch (err) {
      serviceAddress = "";
    }
  }

  const bookingEndTime =
    booking?.service_end_time || booking?.event_end_time || booking?.end_time;
  const bookingStartTime =
    booking?.service_start_time ||
    booking?.event_start_time ||
    booking?.start_time;

  const duration = Math.abs(bookingEndTime - bookingStartTime) * 1000;
  const tempTime = moment.duration(duration);
  const durationText = `${tempTime.hours()}h ${tempTime.minutes()}m`;

  let imgSrc =
    "https://octopus-social-market.s3.me-south-1.amazonaws.com/registration_freelancer/octopus_logo2.png";
  if (isService) {
    imgSrc =
      booking?.service_images?.length > 0 ? booking.service_images[0] : imgSrc;
  } else if (isEvent) {
    imgSrc =
      booking?.event_images?.length > 0 ? booking.event_images[0] : imgSrc;
  }

  const displayName = isService ? "Service" : "Event";
  const titleName = getCapitalize(
    booking?.service_name || booking?.event_name || `${displayName} Name`
  );
  const description =
    booking?.service_desc || booking?.event_desc || "Booking Description";

  let pricingDisplay;
  if (isRFQ) {
    const advanceAmount = booking?.service_advance_amount || 0;
    const rfqAmount = booking?.service_tot_amount || 0;
    const advanceText = advanceAmount ? `${advanceAmount}` : "N/A";
    const totalText = rfqAmount ? `${rfqAmount}` : "N/A";
    pricingDisplay = (
      <>
        <span>RFQ</span>{" "}
        <span className={styles.rfqDetails}>
          (Advance: {advanceText}, Total: {totalText})
        </span>
      </>
    );
  } else if (isEvent) {
    const eventCurrency = booking?.event_price_ccy || "BHD";
    const eventAmount = booking?.event_price || 0;
    const eventTotalAmount = booking?.event_total_amount_to_pay || 0;
    pricingDisplay = (
      <>
        <span className={styles.currency}>{eventCurrency}</span>
        <span className={styles.priceVal}>{eventAmount}</span>
        <span className={styles.separator}>|</span>
        <span className={styles.duration}>{durationText}</span>
        <span className={styles.totalAmount}>
          (Total: {eventCurrency} {eventTotalAmount})
        </span>
      </>
    );
  } else {
    const currency =
      booking?.service_tot_amount_ccy || booking?.service_fees_ccy || "BHD";
    const amount =
      booking?.service_tot_amount ||
      booking?.service_fee ||
      booking?.service_balance_amount ||
      booking?.service_provider_fee ||
      "Amount not available";

    pricingDisplay = (
      <>
        <span className={styles.currency}>{currency}</span>
        <span className={styles.priceVal}>{amount}</span>
        <span className={styles.separator}>|</span>
        <span className={styles.duration}>{durationText}</span>
      </>
    );
  }

  const borderColor = isService ? "#4e4dff" : "#f9a503";

  return (
    <div className={styles.eventCardContainer}>
      <div
        className={styles.leftBorder}
        style={{ backgroundColor: borderColor }}
      ></div>
      <div className={styles.eventCard}>
        <img className={styles.smallImg} src={imgSrc} alt={titleName} />
        <div className={styles.serviceInfo}>
          <p className={styles.subTitle}>
            {isReserved ? "Reserved For This " : ""}
            {displayName}
          </p>
          <p className={styles.mainTitle} style={{ color: "#31263e" }}>
            {titleName}
          </p>
          <div className={styles.pricingContainer}>
            <div className={styles.cardPricing} style={{ color: "#97929e" }}>
              {pricingDisplay}
            </div>
          </div>
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {serviceAddress && (
            <div className={styles.location}>
              <p className={styles.address}>{serviceAddress}</p>
              <button
                onClick={(event) => event.preventDefault()}
                className={styles.directionButton}
              >
                <Direction />
              </button>
            </div>
          )}
        </div>
        {booking?.is_booked && !isReserved && (
          <Button
            shape="round"
            type="primary"
            htmlType="button"
            className={styles.completeButton}
            onClick={(event) => event.preventDefault()}
          >
            Complete
          </Button>
        )}
      </div>
    </div>
  );
});

export default EventCard;
