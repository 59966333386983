import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import {
  BellOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoreOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Input, Avatar, Dropdown } from "antd";
import Sidebar from "./Sidebar/Sidebar";
import Styles from "./Supplier.module.css";
import NotificationIcon from "../../assests/customIcons/Notification";
import SearchIcon from "../../assests/searchiconnew.svg";
import OctoPusLogo from "../../assests/headerLogoSupplier.svg";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import Dashboard from "./Dashboard/Dashboard";
import SupplierProfilePage from "./Profile/Profile";
import ProfileDetails from "./Profile/ProfileDetails";
import EditProfileDetails from "./Profile/EditProfile";
import GenericPage from "./Profile/GenericPage";
import { STORE } from "../../redux/store";
import Mystore from "./Store/Mystore";
import Collab from "./Collabration/SupplierCollab";
import AddNewProduct from "./Store/Newproduct";
import Inventory from "./Inventory/Inventory";
import InventoryDetails from "./Inventory/InventoryDetails";
import Chat from "../../assests/customIcons/Chat";

import Orderlisting from "./ManageOrders/Orderlisting";
import { DaySeriesModel } from "@fullcalendar/core/internal";
import ManageServiceProviders from "./ManageServiceProviders/ManageServiceProviders";
import Productdetails from "./Store/Productdetails";
import CollabDetails from "./Inventory/CollabDetails";
import ChatConversations from "../chats/ChatConversations";
import ChatSupplierLoginHome from "../chats/ChatSupplierConversationHome";
import ChatSupplierLogin from "../chats/ChatConversationSuppplier";
import AdvancePaymentSupplier from "./Collabration/AdvancePaymentSupplier";
import CollabDetailsNonProduct from "./Inventory/CollabDetailsNonProduct";
import ShareholdersInfo from "./EditProfile/Profile/ShareHolders/ShareHolders";
import {
  _GET,
  _GETNX,
  _PATCHNX,
  _POST,
  getUserId,
} from "../../common/commonFunc";
import { Popover } from "@mui/material";
import NotificationsList from "../../common/DashboardNavBar/Notifications/Notifications";
import NotificationBanner from "../../common/DashboardNavBar/notificationBenner/noti";
import { requestFCMToken } from "../../firebase";

const { Header, Sider, Content } = Layout;

const SupplierHome: React.FC = () => {
  let { component, id }: any = useParams();
  const history = useHistory<any>();

  const [collapsed, setCollapsed] = useState(false);
  const { path } = useRouteMatch();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [searchText, setSearchText] = useState("");
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const UserDetails = JSON.parse(UserDetailsLS);
  const [avatarColor, setAvatarColor] = useState("");
  const [profileData, setProfileData] = useState<any>(null);
  const [componentKey, setComponentKey] = useState(0);
  const [selectedKey, setSelectedKey] = useState("2");

  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const [doNotDisturb, setDoNotDisturb] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [fcmToken, setFcmToken] = useState<string | null>(null);

  function parseUserAgent(userAgent: any) {
    let deviceType = "unknown";
    let deviceModel = "unknown";

    if (userAgent.includes("Macintosh")) {
      deviceType = "Mac";
      const macVersionMatch = userAgent.match(/Mac OS X (\d+_\d+_\d+)/);
      if (macVersionMatch) {
        deviceModel = `macOS ${macVersionMatch[1].replace(/_/g, ".")}`;
      }
    } else if (userAgent.includes("Windows")) {
      deviceType = "Windows";
    } else if (userAgent.includes("Android")) {
      deviceType = "Android";
    } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
      deviceType = "iOS";
    }

    return { deviceType, deviceModel };
  }

  const fetchUserNotifications = async (userId: any) => {
    if (!userId) return;
    try {
      const response = await _GETNX(
        `notification/users/fetch/notify?userId=${userId}`,
        true
      );
      setNotifications(response.data);
      setUnreadCount(response.data.filter((n: any) => !n.is_viewed).length);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const markNotificationAsViewed = async (notificationId: any) => {
    try {
      const response = await _PATCHNX(
        `notification/users/view/notify?notify_id=${notificationId}`,
        false,
        true
      );
      if (response?.status) {
        setNotifications((prev: any) =>
          prev.map((n: any) =>
            n.id === notificationId ? { ...n, is_viewed: true } : n
          )
        );
        setUnreadCount((count) => count - 1);
      }
    } catch (error) {
      console.error("Error marking notification as viewed:", error);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId"); // Replace with actual user ID retrieval
    fetchUserNotifications(userId);
  }, []);

  const open = Boolean(anchorEl);
  const OneId = open ? "notification-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationAction = async (
    action: string,
    notification: any
  ) => {
    if (action === "markAsRead") {
      const success: any = await markNotificationAsViewed(notification.id);
      if (success) {
        setNotifications((prev: any) =>
          prev.map((n: any) =>
            n.id === notification.id ? { ...n, is_viewed: true } : n
          )
        );
      }
    } else if (action === "delete") {
      setNotifications((prev: any) =>
        prev.filter((n: any) => n.id !== notification.id)
      );
    } else if (action === "open") {
      alert(`Opening notification: ${notification.message}`);
    }
  };

  const handleNotificationClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const [showBanner, setShowBanner] = useState(true);
  const [noPermission, setNoPermission] = useState(false);

  const updateSelectedKey = (key: any) => {
    setSelectedKey(key);
  };

  const redirectlinkClick = (route: any) => {
    setSelectedKey("Profile");
    history.push(`/supplier/${route}`);
  };

  const fetchData = async () => {
    try {
      const response = await _GET(`userProfile/profile`, true);

      if (response.data && response.status === true) {
        setProfileData(response.data);
        setComponentKey((prevKey) => prevKey + 1);
      } else {
        console.log("No data received from the request");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (component === "shareholders") {
      fetchData();
    }
  }, [component]);

  const renderComponent = () => {
    switch (component) {
      case "dashboard":
        return <Dashboard />;
      case "chatPageSupplierHome":
        return <ChatSupplierLoginHome />;
      case "chatPageSupplier":
        return <ChatSupplierLogin />;
      case "profile":
        return (
          <SupplierProfilePage
            updateSelectedKey={updateSelectedKey}
            avatar={renderAvatar}
            ud={UserDetails}
          />
        );
      case "home":
        return <Dashboard />;
      case "profiledetails":
        return (
          <ProfileDetails
            avatar={renderAvatar}
            updateSelectedKey={updateSelectedKey}
          />
        );
      case "editprofile":
        return (
          <EditProfileDetails
            avatar={renderAvatar}
            updateSelectedKey={updateSelectedKey}
          />
        );
      case "mystore":
        return <Mystore />;
      case "addproduct":
        return <AddNewProduct />;

      case "collabration":
        return <Collab />;
      case "inventory":
        return <Inventory />;
      case "manageorders":
        return <Orderlisting />;
      case "inventory-details":
        if (id) {
          return <InventoryDetails id={id} />;
        }
        break;
      case "manageserviceproviders":
        return <ManageServiceProviders />;
      case "product-details":
        if (id) {
          return <Productdetails id={id} />;
        }
        break;
      case "collab-details":
        if (id) {
          return <CollabDetails id={id} />;
        }
        break;
      case "collab-details-non-product":
        if (id) {
          return <CollabDetailsNonProduct id={id} />;
        }
        break;
      case "advance-payment":
        return <AdvancePaymentSupplier id={id} />;
      case "shareholders":
        return (
          <ShareholdersInfo
            key={componentKey}
            shareholders={profileData?.shareHoldersData}
            onAddShareholderSuccess={fetchData}
            showAdd={true}
          />
        );
      default:
        return <GenericPage pageTitle={"404"} />;
    }
  };

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  useEffect(() => {
    let color = localStorage.getItem("avatarColor");
    if (!color) {
      color = getRandomColor();
      localStorage.setItem("avatarColor", color);
    }
    setAvatarColor(color);
  }, []);

  useEffect(() => {
    const checkNotificationPermission = async () => {
      try {
        // @ts-ignore
        const permission = Notification.permission;
        console.log(
          "🚀 ~ checkNotificationPermission ~ permission:",
          permission
        );

        const dismissalStatus = localStorage.getItem("notificationPermission");
        console.log(
          "🚀 ~ checkNotificationPermission ~ dismissalStatus:",
          dismissalStatus
        );

        if (dismissalStatus === "dismissed") {
          setNoPermission(true);
        }

        if (permission === "granted" || dismissalStatus === "dismissed") {
          setShowBanner(false);
          fetchFCMToken();
        }
      } catch (error) {
        console.error("Error checking notification permission:", error);
      }
    };

    checkNotificationPermission();
  }, []);

  const renderAvatar = () => {
    if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: avatarColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  const fetchFCMToken = async () => {
    try {
      const userID = await getUserId();
      if (userID !== "undefined" && userID !== undefined && userID !== null) {
        const token = await requestFCMToken();
        const storedToken = localStorage.getItem("fcmToken");

        if (storedToken !== token) {
          if (fcmToken !== token) {
            setFcmToken(token);

            const deviceInfo = navigator.userAgent;

            const { deviceType, deviceModel } = parseUserAgent(deviceInfo);

            let payload = {
              user_id: userID,
              fcm_token: token,
              device_type: deviceType,
              device_model: deviceModel,
            };

            const storeToken = await _POST(
              "notification/token/users",
              payload,
              false,
              false
            );

            if (storeToken.status === true) {
              console.log("Stored");
              localStorage.setItem("fcmToken", token);
            } else {
              localStorage.setItem("fcmToken", token);
            }
          }
        }
      } else {
        console.log("User ID not found, can't fetch FCM token.");
      }
    } catch (error) {
      console.error("Error fetching FCM token:", error);
    }
  };

  const handlePermissionGranted = () => {
    fetchFCMToken();
    localStorage.setItem("notificationPermission", "granted");
    setShowBanner(false);
    setNoPermission(false);
  };

  const handleDismiss = () => {
    localStorage.setItem("notificationPermission", "dismissed");
    setShowBanner(false);
    setNoPermission(true);
  };

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={() => redirectlinkClick("profile")} key="0">
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={handleLogout} key="3">
        LogOut
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
        marginTop: showBanner ? "35px" : "0",
      }}
      className={Styles.customLayoutBackground}
    >
      <Sidebar />
      <Layout className={Styles.customLayoutBackground}>
        {showBanner && (
          <NotificationBanner
            onPermissionGranted={handlePermissionGranted}
            onDismiss={handleDismiss}
          />
        )}
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            margin: "10px 20px",
            borderRadius: borderRadiusLG,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: 10,
                marginLeft: 10,
              }}
            >
              {/* <img src={OctoPusLogo} alt="Search" style={{ marginLeft: 5 }} /> */}
              {/* <input
                type="text"
                placeholder="What you want to search ..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  width: 200,
                  border: "none",
                  boxShadow: "none",
                  outline: "none",
                  backgroundColor: "none",
                }}
              /> */}
            </div>
          </div>

          <div className={Styles.iconsContainer}>
            <button
              onClick={() =>
                history.push(APPLICATION_ROUTES.SUPPLIER_CHAT_PAGE)
              }
              className="elevatedElement iconBtn"
              style={{ position: "relative" }}
            >
              <Chat />
              {/* <div className={Styles.countOnIcon}></div> */}
            </button>
            <button
              // onClick={() => {}}
              className="elevatedElement iconBtn"
              style={{ position: "relative" }}
              onClick={handleNotificationClick}
            >
              <NotificationIcon />
              {noPermission ? (
                <div
                  onClick={() => setShowBanner(true)}
                  className={Styles.newNotification}
                >
                  New
                </div>
              ) : (
                <div className={Styles.countOnIcon}>{unreadCount}</div>
              )}
            </button>

            <Popover
              id={OneId}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handleClose}
              slotProps={{
                paper: {
                  sx: {
                    mt: 2,
                    borderRadius: 3,
                    backgroundColor: "transparent",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    border: "none",
                  },
                },
              }}
            >
              <NotificationsList
                notifications={notifications}
                onMarkAllAsRead={markNotificationAsViewed}
                setNotifications={setNotifications}
                onNotificationAction={handleNotificationAction}
                doNotDisturb={doNotDisturb}
                onToggleDoNotDisturb={() => setDoNotDisturb((prev) => !prev)}
              />
            </Popover>
            <Dropdown overlay={menu} trigger={["click"]}>
              <button
                className="elevatedElement iconBtn"
                style={{
                  padding: 5,
                  cursor: "pointer",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                // onClick={() => {
                //   redirectlinkClick("profile");
                // }}
                // on click toggle menu
              >
                {renderAvatar()}
              </button>
            </Dropdown>

            {/* <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                className={Styles.userMenu}
                type="text"
                icon={<MoreOutlined />}
              />
            </Dropdown> */}
          </div>
        </Header>
        <Content
          className={Styles.hiddenScrollbar}
          style={{
            margin: "5px 20px",
            paddingTop: 0,
            minHeight: 280,
            borderRadius: borderRadiusLG,
            overflow: "auto",
            marginBottom: "20px",
          }}
        >
          {renderComponent()}
          {/* {selectedKey === "2" ? (
            <Dashboard />
          ) : selectedKey === "Profile" ? (
            <SupplierProfilePage
              updateSelectedKey={updateSelectedKey}
              avatar={renderAvatar}
              ud={UserDetails}
            />
          ) : selectedKey === "Profile details" ? (
            <ProfileDetails
              avatar={renderAvatar}
              updateSelectedKey={updateSelectedKey}
            />
          ) : (
            "Default Content or another component"
          )} */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default SupplierHome;
