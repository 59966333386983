import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CometChatThemeContext,
  CometChatPalette,
  CometChatTheme,
  CometChatTypography,
  CometChatUsersWithMessages,
} from "@cometchat/chat-uikit-react";
import { Button, Dropdown, Input, Layout, Tabs, Menu } from "antd";
// import SearchIcon from "../../../assests/searchiconnew.svg";
import {
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { CometChatConversationsWithMessages } from "@cometchat/chat-uikit-react";
import Styles from ".././Events/Service/Service.module.css";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import backImg from "../../assests/Back.png";
import DashboardNavBar from "../../common/DashboardNavBar/DashboardNavBar";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
// import { CometChat } from "@cometchat-pro/react-ui-kit";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import { URL } from "url";
import Filter from "../../assests/customIcons/Filter";
import Sidebar from "../SupplierScreens/Sidebar/Sidebar";
import { Content, Header } from "antd/es/layout/layout";
import Chat from "../../assests/customIcons/Chat";
import Notification from "../../assests/customIcons/Notification";
import { STORE } from "../../redux/store";

const { TabPane } = Tabs;
const ChatSupplierLogin = () => {
  const history = useHistory();
  const { profileInfo: freelancerProfile } = useSelector(
    (state: any) => state.freelancerProfile
  );
  const UserDetailsLS: any = localStorage.getItem("userInfo");
  const UserDetails = JSON.parse(UserDetailsLS);
  const [selectedKey, setSelectedKey] = useState("2");
  const [avatarColor, setAvatarColor] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [cometChatUser, setCometChatUser] = useState<any>(false);
  let { id } = useParams<{ id: any }>();
  id = id || false;
  const COMETCHAT_CONSTANTS = {
    APP_ID: "258835f9d7b5c927",
    REGION: "EU",
    AUTH_KEY: "8835837b4af0af1335a8277f37a9c38ab381a008",
  };

  React.useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
      onPageHandler();
    }
  }, [id]);

  const onPageHandler = async () => {
    try {
      // const user = await CometChat.getUser(id);
      const user = await CometChat.getUser(id);
      await setCometChatUser(user);
    } catch (error: any) {
      console.log("Chat not available right now")
      // const userId = _.get(freelancerProfile, "id", "");
      // const createUser = await new CometChat.User(id);
      // const fulName = _.get(freelancerProfile, "first_name", "");
      // await createUser.setName(fulName);
      // if (
      //   _.get(freelancerProfile, "profile_pic", "") &&
      //   _.get(freelancerProfile, "profile_pic", "") !== "NULL"
      // ) {
      //   const userProfilePic = _.get(
      //     freelancerProfile,
      //     "profile_pic",
      //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfos3N8omryPTTqGm07emv6QlbFPuhdjiH1A&usqp=CAU"
      //   );
      //   await createUser.setAvatar(userProfilePic);
      // } else {
      //   await createUser.setAvatar(
      //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfos3N8omryPTTqGm07emv6QlbFPuhdjiH1A&usqp=CAU"
      //   );
      //   // createUser.setAvatar()
      // }

      // const createdUser2 = await CometChat.createUser(
      //   createUser,
      //   COMETCHAT_CONSTANTS.AUTH_KEY
      // ).catch((error) => console.log("error is", error));
      // setCometChatUser(createdUser2);
    }
  };
  const { theme } = useContext(CometChatThemeContext);

  const themeContext = useMemo(() => {
    let res = theme;
    res = new CometChatTheme({
      palette: new CometChatPalette({
        mode: "light",

        primary: {
          light: "#0ba1f8",
          dark: "#D422C2",
        },
        background: {
          light: "#FFFFFF",
          dark: "#141414",
        },

        accent: {
          light: "Gray",
          dark: "#B6F0D3",
        },
        accent50: {
          light: "#ffffff",
          dark: "#141414",
        },
        accent900: {
          light: "white",
          dark: "black",
        },
      }),
    });

    return { theme: res };
  }, [theme]);

  const handleLogout = () => {
    STORE.dispatch({ type: "LOGOUT_USER" });
    window.location.href = APPLICATION_ROUTES.LOGIN;
  };

  const redirectlinkClick = (route: any) => {
    setSelectedKey("Profile");
    history.push(route);
  };
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => redirectlinkClick("profile")} key="0">
        Profile
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item onClick={handleLogout} key="3">
        LogOut
      </Menu.Item>
    </Menu>
  );

  const renderAvatar = () => {
    if (UserDetails.profile_pic && UserDetails.profile_pic !== "NULL") {
      return (
        <img
          src={UserDetails.profile_pic}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
          alt="avatar"
        />
      );
    } else {
      const initial = UserDetails.first_name ? UserDetails.first_name[0] : "";
      const last = UserDetails.last_name ? UserDetails.last_name[0] : "";
      const comp = initial + last;
      const bgColor = getRandomColor();
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            backgroundColor: avatarColor,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: "sans-serif",
          }}
        >
          {comp.toUpperCase()}
        </div>
      );
    }
  };

  return (
    <>
      <Layout
        style={{
          height: "100vh",
          backgroundColor: "#F5F4FA !important",
          background: "#F5F4FA !important",
        }}
        className={Styles.customLayoutBackground}
      >
        {React.useMemo(() => {
          return (
            cometChatUser && (
              <CometChatThemeContext.Provider value={themeContext}>
                <CometChatConversationsWithMessages user={cometChatUser} />
              </CometChatThemeContext.Provider>
            )
          );
        }, [cometChatUser])}
      </Layout>
    </>
  );
};

export default ChatSupplierLogin;
