import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "../../assests/customIcons/Search";
import FilterIcon from "../../assests/customIcons/Filter";
import "./SearchBtn.css";
import { useHistory } from "react-router-dom";
import { Menu, Dropdown, Spin } from "antd";
import { API_BASE_URL, APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";

const SearchBtn = (props: any) => {
  const [input, setInput] = useState<string>("");
  const [debouncedInput, setDebouncedInput] = useState<string>("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const getOS = () => {
    const userAgent = window.navigator.userAgent;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

    if (macosPlatforms.some((platform) => userAgent.includes(platform))) {
      return "Mac";
    } else if (
      windowsPlatforms.some((platform) => userAgent.includes(platform))
    ) {
      return "Windows";
    } else {
      return "Other";
    }
  };

  const osPlaceholder =
    getOS() === "Mac" ? "Cmd + / to Search" : "Win + / to Search";

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "/") {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInput(input);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [input]);

  useEffect(() => {
    if (debouncedInput) {
      setLoading(true);
      fetch(
        `${API_BASE_URL}search/octopus/home?searchTerm=${debouncedInput}&limit=5`
      )
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data.data.results || []);
          setShowSuggestions(true);
        })
        .catch((error) => {
          console.error("Error fetching search suggestions:", error);
        })
        .finally(() => setLoading(false));
    } else {
      setShowSuggestions(false);
    }
  }, [debouncedInput]);

  const handleSelect = (item: any) => {
    let url = "";

    switch (item.type) {
      case "events":
        url = `${APPLICATION_ROUTES.EVENT_DETAILS_NEW}/${item.id}`;
        break;
      case "services":
        url = `${APPLICATION_ROUTES.SERVICE}/${item.id}`;
        break;
      case "users":
        url = `${APPLICATION_ROUTES.PROFILE}/${item.id}`;
        break;
      default:
        console.error("Unknown item type");
        return;
    }

    history.push(url);
    setShowSuggestions(false);
  };

  const extractImage = (images: any, title: string) => {
    if (!images || images.toLowerCase() === "null") {
      return (
        <div className="image-placeholder">
          {title ? title.charAt(0).toUpperCase() : "N"}
        </div>
      );
    }
    const imageArray = images.replace(/[{}]/g, "").split(",");
    return (
      <img
        src={imageArray[0].trim()}
        alt={title || "Result"}
        className="image-preview"
      />
    );
  };

  const handleKeyNavigation = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === "Enter" && highlightedIndex >= 0) {
      e.preventDefault(); // Prevent the default search page navigation
      handleSelect(suggestions[highlightedIndex]);
    }
  };

  const handleDropdownVisibleChange = (visible: boolean) => {
    if (!visible) {
      setShowSuggestions(false);
    }
  };

  const menu = (
    <Menu>
      {loading ? (
        <Menu.Item key="loading">
          <Spin size="small" style={{ color: "#4e4dff" }} /> Searching...
        </Menu.Item>
      ) : suggestions.length > 0 ? (
        suggestions.map((item: any, index: number) => (
          <Menu.Item
            key={item.id}
            onClick={() => handleSelect(item)}
            className={index === highlightedIndex ? "highlighted" : ""}
          >
            <div className="search-result-item">
              {extractImage(item.images, item.title)}
              <span>{item.title}</span>
            </div>
          </Menu.Item>
        ))
      ) : (
        <Menu.Item>No results found</Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="inputBox">
      <SearchIcon />
      <Dropdown
        overlay={menu}
        visible={showSuggestions}
        trigger={["click"]}
        onVisibleChange={handleDropdownVisibleChange}
      >
        <input
          ref={searchInputRef}
          placeholder={osPlaceholder}
          className="inputField"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && highlightedIndex === -1) {
              e.preventDefault(); // Prevent search page navigation if no item selected
              history.push(`/search?searchTerm=${input}`);
            }
          }}
          onKeyDown={handleKeyNavigation}
        />
      </Dropdown>
      <button className="filterIcon">
        {loading ? (
          <Spin size="small" style={{ color: "#4e4dff" }} />
        ) : (
          <FilterIcon />
        )}
      </button>
    </div>
  );
};

export default SearchBtn;
