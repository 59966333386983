import React, { useEffect, useState } from "react";
import { Button, DatePicker, Drawer, Form, Space, TimePicker, message, Radio, notification, Row, Col } from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import Styles from "./OrderTable.module.css"

import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import AddStoreAddress from "./AddStoreAddress";


interface ReadyToDispatchProps {
    visible: boolean;
    onClose: () => void;
    data: any;
}

const ReadyToDispatchOwn: React.FC<ReadyToDispatchProps> = ({
    visible,
    onClose,
    data,
}) => {

    const dateFormat = "DD/MM/YYYY";
    const timeFormat = "HH:mm";
    const [form] = Form.useForm();

    console.log("data in ready to dispatch data : ", data, visible)

    const disabledDate = (current: any) => {
        return current && current < moment().startOf("day");
    };

    const orderId = Object.keys(data)
    const handleSubmit = async (values: any) => {
        try {
            const { pickupDate, pickupTime } = values;
            console.log("Pickupdate and time values are : ", pickupDate, pickupTime);

            const formattedDate = pickupDate.format("DD/MM/YYYY");
            const formattedTime = pickupTime.format("HH:mm");

            const [day, month, year] = formattedDate.split("/").map(Number); 
            const [hour, minute] = formattedTime.split(":").map(Number);
            const dateObject = new Date(year, month - 1, day, hour, minute, 0, 0);
            const delivery_date_time = Math.floor(dateObject.getTime() / 1000);

            const payload = {
                order_item_ids: orderId,
                expected_delivery_date_time : delivery_date_time
            };

            // console.log('Payload:', payload);

            const response = await _PATCH(
                `supplier/order/ready-to-dispatch`,
                payload,
                true
            );

            if (response.status === true) {
                message.success("Order dispatched successfully!");
                form.resetFields();
                onClose();
            } else {
                message.error(
                    response.data && response.message
                        ? response.message
                        : "Error in dispatching order"
                );
            }

        } catch (error) {
            console.error("Error in dispatching order:", error);
        }
    };


    return (
        <>
            <Drawer
                title="Set Courier Company & Schedule"
                placement="right"
                closable={false}
                onClose={onClose}
                open={visible}
                key="right"
                width={"550px"}
                height={"100%"}
                extra={
                    <Space style={{ zIndex: 100 }}>
                        <Button onClick={onClose} className="elevatedElement iconBtn">
                            <Cross />
                        </Button>
                    </Space>
                }
            >
                <div style={{ marginBottom: "30px" }}>

                    {/* dfljgfldkjg */}
                    <div style={{
                        backgroundColor: "rgba(255, 184, 0, 0.15)",
                        marginBottom: "20px"

                    }}>
                        <Typography variant="subtitle1" marginX={2}> <span style={{ color: "blue" }} >({Object.keys(data).length})</span> products ready to dispatch</Typography>
                    </div>
                    <Typography variant="caption" color={"grey"} margin={3}>Select a courier service to deliver the products to customers</Typography>

                    <Box component="div" sx={{ display: "flex", flexDirection: "column", background: "#e9e9fa", borderRadius: "15px" }} margin={2}>

                        <Typography margin={2} variant="h6">Supplier's Courier Service</Typography>
                        {/* <Typography margin={2} variant="caption">
                            This is the courier service description.
                            Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.
                        </Typography> */}
                    </Box>


                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                    >
                        <Typography variant="caption" color={"grey"}>Set a schedule for the courier company to recieve your products</Typography>
               

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
                            <Form.Item
                                label="Select delivery date"
                                name="pickupDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select the delivery date!",
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabledDate={disabledDate}
                                    format={dateFormat}
                                    className={Styles.pickupInput}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Select a delivery time"
                                name="pickupTime"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a delivery time",
                                    },
                                ]}
                            >
                                <TimePicker
                                    format={timeFormat}
                                    disabledHours={() => [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23]}
                                    hideDisabledOptions
                                    className={Styles.pickupInput}
                                />
                            </Form.Item>
                        </div>

                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className={Styles.refundButton}
                            >
                                Continue
                            </Button>
                        </div>
                    </Form>

                </div>
            </Drawer>
        </>
    );
};

export default ReadyToDispatchOwn;
