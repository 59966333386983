import React, { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Space,
  TimePicker,
  message,
  Radio,
  notification,
  Row,
  Col,
  Input,
  InputNumber,
} from "antd";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _PATCH, _POST } from "../../../common/commonFunc";
import _ from "lodash";
import { Box, Checkbox, Tooltip, Typography } from "@mui/material";
import Styles from "./MyStore.module.css";
import FileUpload from "../../../components/InputComponents/FileUpload";
import moment from "moment";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import RichTextEditor from "../../../components/RichText/Quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

interface EditProductDetails {
  visible: boolean;
  onClose: () => void;
  data: any;
  id: string;
}

const EditProductDetails: React.FC<EditProductDetails> = ({
  visible,
  onClose,
  data,
  id,
}) => {
  const [editorContent, setEditorContent] = useState<any>(null);
  const [prodImages, setProdImages] = useState<any>([]);
  const [courierPartner, setCourierPartner] = useState<string | null>(null);
  const [courierCharges, setCourierCharges] = useState<number | null>(0);
  const [previews, setPreviews] = useState(data.product_images);
  const [editorContentMore, setEditorContentMore] = useState<any>(null);
  const [isReturnable, setIsReturnable] = useState<any>(null);
  const [form] = Form.useForm();
  const [productData, setProductData] = useState({ ...data });

  useEffect(() =>{
    setIsReturnable(!!data?.product_is_returnable)
    console.log('Checking is returnable',isReturnable, data, data?.product_is_returnable)
  }, [data])

  useEffect(() => {
    if (!data) return; // Guard clause to handle undefined or null data

    // Set all form fields in one go using the spread operator
    form.setFieldsValue({
      ...data,
      product_description: data.product_description || "", // Fallback to empty string if undefined
      product_other_specs: data.product_other_specs || "",
      courier_charge: data.product_courier_charges_per_delivery || 0,
      is_returnable: data?.product_is_returnable || true
    });

    // Set states for other fields
    setEditorContent(data.product_description || ""); // Fallback for rich text editor
    setEditorContentMore(data.product_other_specs || "");
    setPreviews(data.product_images || []); // Handle cases where images might not exist
    setCourierPartner(data.product_courier_service_choice || "");
    data && data?.product_is_returnable && setIsReturnable(data?.product_is_returnable) // Default to empty string if not set
  }, [data, form]);

  const handleCourierChargesChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCourierCharges(Number(event.target.value));
  };

  const handleEditorChange = (content: any) => {
    setEditorContent(content);
    const converter = new QuillDeltaToHtmlConverter(content.ops, {});
    const html = converter.convert();
  };

  const updateServiceImagesMain = (data: any) => {
    const index = Object.keys(data)[0];
    const value = data[index];
    if (value) setProdImages(() => [value]);
    else {
      const updatedImages = prodImages;
      updatedImages.splice(index, 1);
      setProdImages(updatedImages);
    }
  };

  const handleCourierPartnerChange = (event: any) => {
    const newCourierPartner = event.target.value;
    setCourierPartner(newCourierPartner);

    if (newCourierPartner === "supplier_courier_service") {
      form.setFieldsValue({
        courier_charge: data.product_courier_charges_per_delivery || 0,
      });
    } else {
      form.setFieldsValue({ courier_charge: 0 });
    }
  };

  const handleScroll = (event: any) => {
    event.target.blur();
  };

  const handleEditorChangeMore = (content: any) => {
    setEditorContentMore(content);
  };

  const convertDataToFormData = async (data: any) => {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((ele: any) => {
        if (ele === "images") {
          data[ele].forEach((image: any) => {
            formData.append("images", image);
          });
        } else {
          formData.append(ele, data[ele]);
        }
      });
    }
    return formData;
  };

  const getUpdatedImagesMain = async () => {
    if (prodImages.length > 0) {
      const allValues = {
        images: prodImages,
      };

      const mainValues = await convertDataToFormData(allValues);

      const response = await _POST(
        "services/uploadServiceImagesToS3",
        mainValues,
        false
      );
      if (response) {
        if (response.data?.length > 0) {
          return response.data;
        } else {
          notification.open({
            type: "error",
            message: "Something Went Wrong",
            description: "Please try again to upload your images",
          });
        }
      }
    } else {
      return [];
    }
  };

  const onFinish = async (values: any) => {
    console.log('Checking values', values)
    const uploadedImages = await getUpdatedImagesMain();

    const product_images = [...data.product_images, ...uploadedImages];

    const payload = {
      productData: {
        product_name: values.product_name,
        description: editorContent,
        other_specs: editorContentMore,
        prod_unit_price: values.product_unit_price,
        prod_style: values.product_style,
        prod_discount: values.prod_discount?.toString() || "0",
        is_returnable: isReturnable,
        prod_images: product_images,
        courier_service_choice: courierPartner,
        courier_charges_per_delivery:
          courierPartner === "supplier_courier_service"
            ? values?.courier_charge
            : 0.0,
      },
    };

    console.log("payload for edit product details is : ", payload);

    try {
      const response = await _PATCH(
        `supplier/product/edit/product/${id}`,
        payload,
        true
      );
      if (response.status === true && response.statusCode === 200) {
        message.success("Product details updated Sucessfully!");
        onClose();
        setProdImages([]);
      } else {
        message.error(
          response.data && response.message
            ? response.message
            : "Error in updating product details"
        );
      }
    } catch (error) {
      console.error("Error in updating Product Details:", error);
    }
  };

  const handleCheckboxChange = (e: any) => {
    setIsReturnable(!isReturnable);
  };

  return (
    <>
      <Drawer
        title="Edit Product Details"
        placement="right"
        closable={false}
        onClose={onClose}
        open={visible}
        key="right"
        width={"550px"}
        height={"100%"}
        extra={
          <Space style={{ zIndex: 100 }}>
            <Button onClick={onClose} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <FileUpload
          title1="Upload Image"
          description="PDF, JPG, PNG file with max size of 5MB."
          setData={updateServiceImagesMain}
          name="serviceImages"
          uploadMultiple
          existingPreviews={previews}
          setExistingPreviews={setPreviews}
        />

        <Form
          form={form}
          name="product_form"
          onFinish={onFinish}
          layout="vertical"
          className={Styles.form}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item
              name="product_name"
              label="Product name"
              rules={[{ required: true }]}
              className={Styles.formItem}
            >
              <Input className={Styles.formInput} placeholder="Enter name" />
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              hasFeedback
              style={{ width: "100%" }}
            >
              <RichTextEditor
                initialContent={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
            <Form.Item
              name="other_specs"
              label="Other Specs"
              hasFeedback
              style={{ width: "100%" }}
            >
              <RichTextEditor
                initialContent={editorContentMore}
                onChange={handleEditorChangeMore}
              />
            </Form.Item>
            <Form.Item
              name="product_style"
              label="Style"
              rules={[{ required: true }]}
              className={Styles.formItem}
            >
              <Input className={Styles.formInput} placeholder="Enter style" />
            </Form.Item>
            <Form.Item
              name="courier_partner"
              label="Please select your Logistic Partner"
              required={true}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Radio.Group
                  onChange={handleCourierPartnerChange}
                  value={courierPartner}
                >
                  <Radio value="octopus_courier_service">
                    Octopus's Courier service
                  </Radio>
                  <Radio value="supplier_courier_service">
                    {" "}
                    Other Courier service
                  </Radio>
                </Radio.Group>
              </div>
            </Form.Item>

            {courierPartner === "supplier_courier_service" && (
              <Form.Item
                name="courier_charge"
                label="Add Courier Charges"
                required={true}
              >
                <Input
                  className={Styles.formInput}
                  style={{ width: "50%" }}
                  placeholder="Enter Amount"
                  value={courierCharges || 0}
                  onChange={handleCourierChargesChange}
                  type="number"
                  onWheel={handleScroll}
                />
              </Form.Item>
            )}
            <Form.Item
              name="product_unit_price"
              label="Price (BHD)"
              rules={[{ required: true }]}
              className={Styles.inlineFormItem}
            >
              <InputNumber
                min={0}
                className={Styles.formInput}
                placeholder="Enter price"
              />
            </Form.Item>
            <Form.Item
              name="prod_discount"
              label="Discount ( % )"
              className={Styles.inlineFormItem}
              rules={[
                {
                  type: "number",
                  min: 0,
                  max: 100,
                  message: "Discount should not goes beyond 100%",
                },
              ]}
            >
              <InputNumber
                min={0}
                className={Styles.formInput}
                placeholder="Enter discount"
              />
            </Form.Item>
            <Form.Item
              name="is_returnable"
              valuePropName="checked"
              className={Styles.formItem}
            >
              <Checkbox
                checked={isReturnable}
                onChange={handleCheckboxChange}
              />{" "}
              Check this if this product policy is not returnable{" "}
              <InfoCircleOutlined className={Styles.infoIcon} />
            </Form.Item>

            <Form.Item className={Styles.saveButton}>
              <Button
                htmlType="submit"
                className={Styles.addButton}
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};


export default EditProductDetails;
