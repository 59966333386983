import { AnyAction, Dispatch } from "redux";
import { getUserId, _GET } from "../../common/commonFunc";
import { RootState } from "../store";

export const FETCH_CART_ITEMS = "FETCH_CART_ITEMS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const RESET_CART_FETCHED = "RESET_CART_FETCHED";

export interface CartItem {
  id: string;
  name: string;
  quantity: number;
}

export const fetchCartItems =
  (): any => async (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();

    if (state.globalCart.isCartFetched) {
      return;
    }

    const userId = getUserId();
    if (!userId || userId === "undefined") {
      console.warn("Invalid userId. Skipping cart fetch.");
      return null;
    }

    try {
      const res = await _GET(`cart/getItem/${userId}`, true, false);
      if (res?.status) {
        const itemsArray: CartItem[] = Object.values(res.data)
          .map((item: any) => JSON.parse(item))
          .flat();
        dispatch({
          type: FETCH_CART_ITEMS,
          payload: itemsArray,
        });
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

export const resetCartFetched = () => {
  return {
    type: RESET_CART_FETCHED,
  };
};

export const addToCart = (item: CartItem): AnyAction => ({
  type: ADD_TO_CART,
  payload: item,
});

export const removeFromCart = (itemId: string): AnyAction => ({
  type: REMOVE_FROM_CART,
  payload: itemId,
});
