import React, { useState } from "react";
import { Layout, Row, Col, Breadcrumb } from "antd";
import { Card, CardContent, Typography, Avatar } from "@mui/material";
import ProfileBox from "./ProfileBox";
import Styles from "./Profile.module.css";

import icon2 from "../../../assests/PerosnalInfo.png";
import icon12 from "../../../assests/logout.png";
import FrameIcon from "../../../assests/Frame.svg";
import contactUsIcon from "../../../assests/contactUs.svg";
import faqIcon from "../../../assests/faq.svg";
import changePasswordIcon from "../../../assests/lockIcon.svg";
import cardicon from "../../../assests/cardIcon.svg";
import blockIcon from "../../../assests/blockIcon.svg";
import TnCIcon from "../../../assests/tncIcon.svg";
import returnIcon from "../../../assests/returnIcon.svg";
import breadcrumbIcon from "../../../assests/breadCrumbSeprator.svg";
import cancelpolicyicon from "../../../assests/querlisttickIcon.svg";
import ShareIcon from "../../../assests/ShareIconprofile.svg";
import ChangePasswordDrawer from "./Chnagepassword";
import { STORE } from "../../../redux/store";
import { APPLICATION_ROUTES } from "../../../constant/ApplicationRoutes";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import ContactUsDrawer from "./ContactUs";
import { _DELETENXWithBody } from "../../../common/commonFunc";

const SupplierProfilePage = ({ updateSelectedKey, avatar, ud }: any) => {
  const [changePassDrawer, setChangePassDrawer] = useState(false);
  const [contactUsDrawer, setContactUsDrawer] = useState(false);

  const history = useHistory<any>();

  const handleBreadcrumbClick = (route: any) => {
    history.push(route);
  };

  const closeChangePaassDrawer = () => {
    setChangePassDrawer(false);
  };

  const closeContactUsDrawer = () => {
    setContactUsDrawer(false);
  };

  const cardclick = (route: any) => {
    history.push(route);
  };
  const accountsData = [
    {
      name: "My Profile",
      //   link: APPLICATION_ROUTES.PERSONAL_INFO,
      img: icon2,
    },
    {
      name: "My transactions",
      //   link: APPLICATION_ROUTES.PROFILE_SAVED_SERVICES,
      img: FrameIcon,
    },
    {
      name: "Change password",
      img: changePasswordIcon,
    },
    {
      name: "Contact Us",
      img: contactUsIcon,
    },
    {
      name: "FAQs",
      img: faqIcon,
    },
    {
      name: "Payment method",
      //   link: APPLICATION_ROUTES.PREFERENCES,
      img: cardicon,
    },
    {
      name: "Blocked freelancer",
      img: blockIcon,
      //   link: APPLICATION_ROUTES.BANK_INFORMATION,
    },

    {
      name: "Terms & Conditions",
      img: TnCIcon,
    },

    {
      name: "Return policy",
      img: returnIcon,
    },
    {
      name: "Cancellation policy",
      img: cancelpolicyicon,
    },
    {
      name: "Logout",
      img: icon12,
      //   link: APPLICATION_ROUTES.LOGIN_HOME,
    },
  ];

  // const handleLogout = () => {
  //   STORE.dispatch({ type: "LOGOUT_USER" });
  //   window.location.href = APPLICATION_ROUTES.LOGIN;
  // };

  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = () => {
    setLoggingOut(true);
    const userInfo = localStorage.getItem("userInfo");
    const fcmToken = localStorage.getItem("fcmToken");

    if (userInfo && fcmToken) {
      const parsedUserInfo = JSON.parse(userInfo);

      const payload = {
        user_id: parsedUserInfo?.id || "",
        fcm_token: localStorage.getItem("fcmToken") || "",
      };

      _DELETENXWithBody(`notification/remove/fcm`, payload, true, true)
        .then((response) => {
          console.log("FCM token removed successfully:", response.data);
          setLoggingOut(false);
          STORE.dispatch({ type: "LOGOUT_USER" });
          window.location.href = APPLICATION_ROUTES.LOGIN;
        })
        .catch((error) => {
          console.error("Error removing FCM token:", error);
          setLoggingOut(false);
        })
        .finally(() => {
          setLoggingOut(false);
        });
    } else {
      setLoggingOut(false);
      STORE.dispatch({ type: "LOGOUT_USER" });
      window.location.href = APPLICATION_ROUTES.LOGIN;
    }
  };

  return (
    <Layout
      style={{
        height: "100vh",
        backgroundColor: "#F5F4FA !important",
        background: "#F5F4FA !important",
      }}
      className={Styles.customLayoutBG}
    >
      <h1>My Account</h1>
      <Breadcrumb
        style={{ margin: "16px 0" }}
        separator={<img src={breadcrumbIcon} alt=">" />}
      >
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/home")}
        >
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={Styles.breadcrumbItem}
          onClick={() => handleBreadcrumbClick("/supplier/profile")}
        >
          My Account
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className={Styles.parentContentdiv1}>
        <div className={Styles.customLayoutBackground}>
          {accountsData.map((item: any, i: any) => {
            return (
              <ProfileBox
                onClickHandler={() => {
                  if (item.name === "Change password") {
                    setChangePassDrawer(true);
                  } else if (item.name === "My Profile") {
                    cardclick("/supplier/profiledetails");
                  } else if (item.name === "Logout") {
                    handleLogout();
                  } else if (item.name === "Contact Us") {
                    setContactUsDrawer(true);
                  } else {
                    history.push(item.link);
                  }
                }}
                key={i}
                name={item.name}
                link={item.link}
                img={item.img}
              />
            );
          })}
        </div>

        <div className={Styles.container_pc}>
          <div className={Styles.profileContent}>
            <div className={Styles.profileHeader}>
              <div className={Styles.profileImageWrapper}>{avatar()}</div>
              <div>
                {ud?.first_name?.length > 20 ? (
                  <>
                    <h3> {ud.first_name} </h3> <h3>{ud.last_name}</h3>{" "}
                  </>
                ) : (
                  <h3>{`${ud.first_name} ${ud.last_name}`}</h3>
                )}
                <p>{ud.user_bio !== null ? ud.user_bio : "Not yet set"}</p>
                <h4>{ud.email}</h4>
              </div>
            </div>
            <button
              style={{
                backgroundColor: "white",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "none",
                marginBottom: "1rem",
                cursor: "pointer",
              }}
            >
              <img src={ShareIcon} alt="" />
            </button>
          </div>
        </div>
      </div>
      <ChangePasswordDrawer
        open={changePassDrawer}
        onClose={closeChangePaassDrawer}
      />
      <ContactUsDrawer open={contactUsDrawer} onClose={closeContactUsDrawer} />
    </Layout>
  );
};

export default SupplierProfilePage;
