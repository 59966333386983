import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

const Unauthorized: React.FC = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.push("/");
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>403 - Unauthorized</h1>
      <p style={styles.text}>You don’t have permission to view this page.</p>
      <Button type="primary" onClick={handleGoBack}>
        Go to Home
      </Button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex" as "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    height: "100vh" as "100vh",
    textAlign: "center" as "center",
    backgroundColor: "#f0f0f0" as "#f0f0f0",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#ff4d4f",
  },
  text: {
    fontSize: "1.2rem",
    color: "#555",
    marginBottom: "1.5rem",
  },
};

export default Unauthorized;
