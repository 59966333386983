import React from "react";
import { message, notification } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import googleLogo from "../../assests/googleLogo.png";
import _ from "lodash";
import "./GoogleBtn.css";
import { _POST } from "../commonFunc";
import axios from "axios";
import { useHistory } from "react-router";
import { showSuccessCustomModal } from "../CustomModal/CustomModal";
import { APPLICATION_ROUTES } from "../../constant/ApplicationRoutes";
import { setCookie } from "../../utils/tokenService";

const GoogleBtn = ({ pageType, onClick }: any) => {
  const history = useHistory();
  const userRole = localStorage.getItem("octo_role");
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        let data: any = {};
        if (pageType === "register") {
          axios
            .get(
              `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
              {
                headers: {
                  Authorization: `Bearer ${codeResponse.access_token || ""}`,
                  Accept: "application/json",
                },
              }
            )
            .then(async ({ data }) => {
              let [first_name, last_name] = data.name.split(" ");
              const firstName = first_name;
              const lastName = last_name;
              const validResponse = {
                email: data.email,
                firstName,
                lastName,
                token: codeResponse.access_token,
              };
              onClick(validResponse);
              notification.open({
                type: "success",
                message: "Google Login Successful!",
                description: "Your details fetched from Google Successfully",
              });
            });
        } else {
          data = await _POST(
            "auth/google/authenticate",
            {
              accessToken: codeResponse.access_token,
              role: localStorage.getItem("octo_role"),
            },
            false
          );

          console.log("data?.data", userRole);

          const userInfo = {
            id: data?.data?.id,
            first_name: data?.data?.first_name || data?.data?.user?.first_name,
            last_name: data?.data?.last_name || data?.data?.user?.last_name,
            email: data?.data?.email || data?.data?.user?.last_name,
            phone_number:
              data?.data?.phone_number || data?.data?.user?.last_name,
            birthdate: data?.data?.birthdate || data?.data?.user?.last_name,
            gender: data?.data?.gender || data?.data?.user?.last_name,
            country: data?.data?.country || data?.data?.user?.last_name,
            register_type:
              data?.data?.register_type || data?.data?.user?.last_name,
            zoho_user_id:
              data?.data?.zoho_user_id || data?.data?.user?.last_name,
            countryCode:
              data?.data?.country_code || data?.data?.user?.last_name,
            phoneNumber:
              data?.data?.phone_number || data?.data?.user?.last_name,
            userType: data?.data?.user_type || data?.data?.user?.last_name,
            token: data?.data?.token || data?.data?.user?.last_name,
            phone_no: data?.data?.phone_no || data?.data?.user?.last_name,
            country_code:
              data?.data?.country_code || data?.data?.user?.last_name,
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          localStorage.setItem(
            "octo_role",
            data.data?.role || data?.data?.user?.role
          );
          localStorage.setItem(
            "userEmail",
            data?.data?.email || data?.data?.user?.email
          );

          console.log("data?.data", data?.data);
          const userRoleNew = data?.data?.user?.role;
          const isUserOrSupplier =
            userRoleNew === "user" || userRoleNew === "supplier";
          console.log("isUserOrSupplier", isUserOrSupplier);
          const isDocumentNotUpdated =
            data?.data?.is_document_updated === false ||
            data?.data?.user?.is_document_updated === false;

          if (
            (data?.data && data?.data.is_email_verified === false) ||
            data?.data?.user?.is_email_verified === false
          ) {
            history.push(APPLICATION_ROUTES.EMAIL_VERIFY);
            return;
          }

          if (
            (data?.data && data?.data.is_phone_verified === false) ||
            data?.data?.user?.is_phone_verified === false
          ) {
            history.push(APPLICATION_ROUTES.VERIFY_OTP);
            return;
          }

          if (!isUserOrSupplier && isDocumentNotUpdated) {
            console.log("Not A User", data?.data?.user?.ekyc_response_id);
            if (
              data?.data?.user?.ekyc_response_id !== null &&
              data?.data?.user?.ekyc_response_id !== undefined
            ) {
              localStorage.setItem(
                "ekyc_response_id",
                data?.data?.user?.ekyc_response_id
              );
            }
            history.push(APPLICATION_ROUTES.VERIFY_DOCUMENTS);
            return;
          }

          if (
            (userRole !== "supplier" &&
              data?.data &&
              data?.data.agreed_to_terms_and_condition === false) ||
            data?.data?.user?.agreed_to_terms_and_condition === false
          ) {
            message.info("Please accept terms and conditions");
            history.push(APPLICATION_ROUTES.CATEGORIES);
            return;
          }

          if (
            (userRole === "supplier" || data?.data?.role === "supplier") &&
            data?.data &&
            (data?.data.agreed_to_terms_and_condition === false ||
              data?.data.agreed_to_terms_and_condition === null)
          ) {
            history.push(APPLICATION_ROUTES.SUPPLIER_HOME);
            localStorage.setItem(
              "supplier_form_step",
              data?.data?.supplier_form_step
            );
            localStorage.setItem("supplier_id", data?.data?.supplier_id);
            localStorage.setItem("userEmail", data?.data?.email);
            return;
          }
          if (
            (userRole === "supplier" || data?.data?.role === "supplier") &&
            data?.data &&
            data?.data.supplier_form_step < 4
          ) {
            history.push(APPLICATION_ROUTES.SUPPLIER_REGISTRATION);
            localStorage.setItem(
              "supplier_form_step",
              data?.data?.supplier_form_step
            );
            localStorage.setItem("supplier_id", data?.data?.supplier_id);
            localStorage.setItem("userEmail", data?.data?.email);
            return;
          }
          if (
            (userRole !== "supplier" || data?.data?.role !== "supplier") &&
            data?.data &&
            data?.data.agreed_to_terms_and_condition === false
          ) {
            history.push(APPLICATION_ROUTES.CATEGORIES);
            return;
          }

          if (data.data && data.data?.token) {
            localStorage.setItem("jwtToken", data?.data.token);
            setCookie("refreshToken", data?.data?.refreshToken, 720);
            const currentTime = Date.now().toString();
            localStorage.setItem("loginTime", currentTime);

            showSuccessCustomModal({
              title: "Login SuccessFully",
              smallText: "You are logged in successfully",
              buttonText: "Continue",
            });
            localStorage.setItem("octo_role", data.data?.user.role);
            localStorage.setItem("userInfo", JSON.stringify(data?.data?.user));
            axios.defaults.headers.common["Authorization"] = data.data?.token;
            history.push(APPLICATION_ROUTES.DASHBOARD);
          }
        }
      } catch (error) {
        console.log("Checking Error in google login", error);
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      return notification.open({
        type: "error",
        message: "Something went wrong!. Please try again",
        description: _.get(error, "message", "error"),
      });
    },
  });

  return (
    <div className="flex">
      <button onClick={() => googleLogin()} type="button" className="googleBtn">
        <img height="30px" src={googleLogo} alt="" />
        Continue With Google
      </button>
    </div>
  );
};

export default GoogleBtn;
