import React, { useMemo, useCallback, useState, useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { Roles } from "../utils/rolesConfig";
import styles from "./UnauthorizedModal.module.css";
import { getRole } from "../common/commonFunc";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  allowedRoles: Roles[];
}

const UnauthorizedModal: React.FC<{
  userRole: string;
  onClose: () => void;
}> = ({ userRole, onClose }) => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setCursorPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className={styles.backgroundWrapper}>
      <video autoPlay loop muted className={styles.backgroundVideo}>
        <source
          src="https://octopus-platform-assets.s3.me-south-1.amazonaws.com/web_homepage/Octopus_homepage_animation.mp4"
          type="video/mp4"
        />
      </video>
      <div
        className={styles.octopusCursor}
        style={{ top: `${cursorPosition.y}px`, left: `${cursorPosition.x}px` }}
      />
      <div className={styles.modalOverlay}>
        <div className={styles.modalContainer}>
          <h2 className={styles.modalTitle}>Access Denied</h2>
          <p className={styles.modalMessage}>
            {userRole !== "guest" ? (
              <>
                You are currently logged in as{" "}
                <strong className={styles.bold}>{userRole}</strong> and do not
                have permission to view this page.
              </>
            ) : (
              "You need to be logged in to access this page."
            )}
          </p>
          <button onClick={onClose} className={styles.modalButton}>
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedRoles,
  ...rest
}) => {
  const userRole: any = useMemo(() => getRole(), []);
  const history = useHistory();

  const hasAccess = useMemo(
    () => allowedRoles.includes(userRole),
    [allowedRoles, userRole]
  );

  const handleCloseModal = useCallback(() => {
    if (userRole === "supplier") {
      history.push("/supplier/dashboard");
    } else {
      history.push(userRole !== "guest" ? "/dashboard" : "/login");
    }
  }, [history, userRole]);

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAccess ? (
          <Component {...props} />
        ) : (
          <UnauthorizedModal userRole={userRole} onClose={handleCloseModal} />
        )
      }
    />
  );
};

export default React.memo(ProtectedRoute);
