import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Drawer,
  Space,
  Form,
  Row,
  Col,
  Radio,
  Checkbox,
} from "antd";
import "./Address.css";
import Cross from "../../../assests/customIcons/Cross";
import { _GET, _POST } from "../../../common/commonFunc";
import LocationPicker from "../../Map/LocationPicker";
import { RequestType, geocode, setKey } from "react-geocode";
import { GOOGLE_MAP_API_KEY } from "../../../constant/ApplicationRoutes";
setKey(GOOGLE_MAP_API_KEY);

interface Address {
  address: string;
  selected: boolean;
}

function LocationSelector({
  onLocationChange,
  serviceLocation,
  prefFee,
  serviceAtCustomerLocation,
  coords,
  setCoords,
}: {
  onLocationChange: (location: string, address: string) => void;
  serviceLocation: string;
  prefFee?: string;
  serviceAtCustomerLocation?: any;
  coords?: { lat: number; lng: number };
  setCoords?: any;
}) {
  const [selectedLocation, setSelectedLocation] = useState("service-provider");
  const [homeAddresses, setHomeAddresses] = useState<Address[]>([]);
  const [newAddress, setNewAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedHomeIndex, setSelectedHomeIndex] = useState("-1");
  const [saveDefault, setSaveDefault] = useState(false);
  const [isAddAddressModalVisible, setIsAddAddressModalVisible] =
    useState(false);
  const [newStreet, setNewStreet] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [form] = Form.useForm();

  const userData: any = localStorage.getItem("userInfo");
  const userId = JSON.parse(userData).id;
  const getLocationData = async () => {
    const latitude = coords?.lat ?? 0;
    const longitude = coords?.lng ?? 0;
    try {
      if (geocode) {
        const response = await geocode(
          RequestType.LATLNG,
          `${latitude},${longitude}`
        );
        const { results } = response;
        console.log("results", results);
        if (results && results.length > 0) {
          const data = results[0];
          const { city, state, country, pinCode, landmark, building_no } =
            data.address_components.reduce((acc: any, component: any) => {
              if (component.types.includes("administrative_area_level_3"))
                acc.city = component.long_name;
              else if (component.types.includes("administrative_area_level_1"))
                acc.state = component.long_name;
              else if (component.types.includes("country"))
                acc.country = component.long_name;
              else if (component.types.includes("postal_code"))
                acc.pinCode = component.long_name;
              else if (component.types.includes("locality"))
                acc.landmark = component.long_name;
              else if (component.types.includes("street_number"))
                acc.building_no = component.long_name;
              return acc;
            }, {});
          form.setFieldsValue({
            address: data.formatted_address,
            landmark,
            pinCode,
            city,
            state,
            country,
            building_no,
          });
        } else {
          console.error("Geocoding request returned zero results.");
        }
      } else {
        alert("HIHIH");
      }
    } catch (error) {
      console.error("Error during geocoding request:", error);
    }
  };

  useEffect(() => {
    if (serviceAtCustomerLocation === "no") {
      setSelectedLocation("service-provider");
      setSelectedAddress(serviceLocation);
      onLocationChange("service-provider", serviceLocation);
    }
  }, [serviceAtCustomerLocation, serviceLocation, onLocationChange]);

  useEffect(() => {
    if (serviceAtCustomerLocation === "yes") {
      const apiUrl = `userProfile/getUserAllAddress/${userId}`;
      _GET(apiUrl, true)
        .then((data) => {
          if (data.status && data.data && data.data.length > 0) {
            const userAddresses = data.data[0].user_addresses;
            const allAddresses: Address[] = [];
            userAddresses.forEach((addressData: any) => {
              const concatenatedAddress = `${addressData?.address_name}, ${addressData?.building_no}, ${addressData?.floor}, ${addressData?.address}, ${addressData?.landmark}, ${addressData?.pinCode ?? ""}${addressData?.city ?? ""}${addressData?.state ?? ""}${addressData?.country}`;
              console.log("Concateated Address is: ", concatenatedAddress)
              allAddresses.push({
                address: concatenatedAddress,
                selected: addressData.isDefault,
              });
            });

            if (allAddresses.length > 0) {
              const selectedHomeAddress = allAddresses.find(
                (address) => address.selected
              );
              const address = selectedHomeAddress
                ? selectedHomeAddress.address
                : allAddresses[0].address || "";
              let index = -1;

              allAddresses.forEach((address, i) => {
                if (address.selected) {
                  index = i;
                  return;
                }
              });
              if (index === -1) {
                index = 0;
              }
              setSelectedHomeIndex(`${index}`);
              setSelectedAddress(address);
              onLocationChange("home", address);
            }
            setHomeAddresses(allAddresses);
            // setSelectedLocation("home");
          }
        })
        .catch((error) => {
          console.error("Error fetching addresses:", error);
        });
    }
  }, []);

  const handleLocationChange = (event: any) => {
    const location = event.target.value;
    let address = "";

    if (location === "service-provider") {
      address = serviceLocation;
      setSelectedHomeIndex("-1");
    } else if (location === "home") {
      if (homeAddresses.length > 0) {
        const selectedHomeAddress = homeAddresses.find(
          (address) => address.selected
        );
        address = selectedHomeAddress
          ? selectedHomeAddress.address
          : homeAddresses[0].address || "";
        let index = -1;
        homeAddresses.forEach((address, i) => {
          if (address.selected) {
            index = i;
            return;
          }
        });
        if (index === -1) {
          index = 0;
        }
        setSelectedHomeIndex(`${index}`);
      }
    }

    setSelectedLocation(location);
    setSelectedAddress(address);
    onLocationChange(location, address);
  };

  const handleAddAddress = () => {
    setIsAddAddressModalVisible(true);
  };
  useEffect(() => {
    const storedAddresses = localStorage.getItem("userAddresses");
    if (storedAddresses) {
      setHomeAddresses(JSON.parse(storedAddresses));
    }
  }, [selectedAddress]);

  function generateUUID() {
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );

    return uuid;
  }

  const newUUID = generateUUID();
  // console.log(newUUID);

  const handleOk = async (values: any) => {
    const {
      address_name,
      building_no,
      floor,
      address,
      landmark,
      pincode,
      city,
      state,
      country,
    } = values;
    const requestBody = {
      address_id: newUUID,
      address_name,
      building_no,
      floor,
      address,
      landmark,
      pinCode: pincode ?? "",
      city : "",
      state : "",
      country,
      isDefault: false,
      coords: coords,
    };

    const newHomeAddress = `${address_name}, ${building_no}, ${floor}, ${address}, ${landmark}, ${pincode ?? ""}, ${city ?? ""}, ${state ?? ""}, ${country}`;
    const updatedAddresses = homeAddresses.map((addr) => ({
      ...addr,
      selected: false,
    }));

    try {
      const response = await _POST(
        `/userProfile/${userId}/add_address`,
        requestBody,
        true
      );
    } catch (error) {
      console.log(error);
    }

    const updatedAddressList = [
      ...updatedAddresses,
      { address: newHomeAddress, selected: true },
    ];
    localStorage.setItem("userAddresses", JSON.stringify(updatedAddressList));

    setHomeAddresses(updatedAddressList);

    setSelectedAddress(newHomeAddress);
    setSelectedLocation("home");
    setSelectedHomeIndex(`${updatedAddressList.length - 1}`);
    onLocationChange("home", newHomeAddress);
    setIsAddAddressModalVisible(false);
  };

  const handleCancel = () => {
    setNewStreet("");
    setNewCity("");
    setNewCountry("");
    setIsAddAddressModalVisible(false);
  };

  useEffect(() => {
    if (selectedLocation === "service-provider") {
      onLocationChange("service-provider", serviceLocation);
    }
  }, [selectedLocation, serviceLocation, onLocationChange]);

  const handleAddressSelection = (index: number) => {
    const updatedAddresses = homeAddresses.map((address, i) => ({
      ...address,
      selected: i === index,
    }));
    setHomeAddresses(updatedAddresses);
    setSelectedAddress(updatedAddresses[index].address);
    setSelectedLocation("home");
    setSelectedHomeIndex(`${index}`);
    onLocationChange("home", updatedAddresses[index].address);
    // console.log(updatedAddresses);
  };

  return (
    <div className="mainDivAddress">
      <div className="mainDivAddress">
        <div className="mainDivAddressOne">
          <Form className="location-form" form={form}>
            <Form.Item name="selectedLocation">
              <Radio.Group
                className="radio-col"
                value={selectedLocation}
                onChange={(e) => handleLocationChange(e)}
                defaultValue={"service-provider"}
              >
                {serviceAtCustomerLocation === "yes" && (
                  <Radio value="home" key="home" className="radio-info">
                    <div className="home-selection">
                      <div className="title-add">
                        <p className="mainTitle">Your Addresses</p>
                      </div>
                      {homeAddresses.length > 0 && (
                        <div className="home-extra">
                          <p className="subTitle">Select Address</p>
                        </div>
                      )}
                      <div className="address-list">
                        {homeAddresses.length > 0 && (
                          <Form.Item>
                            <Radio.Group
                              className="radio-row"
                              value={selectedHomeIndex}
                              onChange={(e) =>
                                handleAddressSelection(e.target.value)
                              }
                            >
                              {homeAddresses.map((address, index) => (
                                <Radio
                                  value={index.toString()}
                                  key={index.toString()}
                                >
                                  <p
                                    className={`subTitle multiLine ${
                                      selectedHomeIndex === index.toString() &&
                                      "bold"
                                    }`}
                                  >
                                    {address?.address}
                                  </p>
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        )}
                      </div>
                      <Button
                        onClick={handleAddAddress}
                        className="blackOutline"
                      >
                        <p className="bold m-0">+ Add Address</p>
                      </Button>
                    </div>
                  </Radio>
                )}
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Drawer
        title="Add New Address"
        placement="right"
        closable={false}
        onClose={handleCancel}
        open={isAddAddressModalVisible}
        key="right"
        width={"550px"}
        extra={
          <Space>
            <Button onClick={handleCancel} className="elevatedElement iconBtn">
              <Cross />
            </Button>
          </Space>
        }
      >
        <LocationPicker coords={coords} setCoords={setCoords} />
        <Row
          style={{ width: "95%", marginLeft: "auto", paddingBottom: "20px" }}
        >
          <Button
            shape="round"
            type="primary"
            htmlType="button"
            className="buttonBG"
            onClick={() => getLocationData()}
          >
            Fill the form for selected location
          </Button>
        </Row>
        <Form layout="vertical" onFinish={handleOk} form={form}>
          <Row style={{ marginTop: "-50px" }}>
            <Col span={24}>
              <Form.Item
                label="Address Name"
                name="address_name"
                rules={[
                  { required: true, message: "Please enter address name!" },
                ]}
              >
                <Input
                  placeholder="Enter your Address name"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Building name/no."
                name="building_no"
                rules={[
                  { required: true, message: "Please enter building name/no!" },
                ]}
              >
                <Input
                  placeholder="Add Building name/no."
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Floor, apartment or villa no." name="floor">
                <Input
                  type="number"
                  placeholder="Floor, apartment or villa no."
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Street name"
                name="address"
                rules={[
                  { required: true, message: "Please enter your Street name!" },
                ]}
              >
                <Input
                  placeholder="Add your Street name"
                  className="drawerInput"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: "Please enter country!" }]}
              >
                <Input placeholder="Enter country" className="drawerInput" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="Nearest landmark"
                name="landmark"
                rules={[
                  {
                    required: true,
                    message: "Please enter a nearest landmark!",
                  },
                ]}
              >
                <Input placeholder="Nearest landmark" className="drawerInput" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="isDefault">
                <Checkbox onChange={(e) => setSaveDefault(e.target.checked)}>
                  Save as Default
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginTop: "20px", marginBottom: "-50px" }}>
            <Button
              shape="round"
              type="primary"
              htmlType="submit"
              className="buttonBG"
            >
              Add
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default LocationSelector;
