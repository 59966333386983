import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Styles from "./DocumentPreview.module.css";
import Cross from "../../../assests/customIcons/Cross";

interface DocumentPreviewProps {
  document: File | null;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ document }) => {
  const [visible, setVisible] = useState<boolean>(true);

  const toggleDrawer = () => setVisible(!visible);

  const renderContent = (file: File | null) => {
    if (!file) return <div>No document available</div>;

    const fileType = file.type;

    if (fileType.startsWith("image/")) {
      return (
        <img
          src={URL.createObjectURL(file)}
          alt="Preview"
          className={Styles.imagePreview}
        />
      );
    } else if (fileType === "application/pdf") {
      return (
        <iframe
          src={URL.createObjectURL(file)}
          className={Styles.pdfPreview}
          title="PDF Preview"
        />
      );
    }
    return <div>Unsupported file type</div>;
  };

  return (
    <div>
      {document && (
        <>
          <Drawer
            title="Document Preview"
            placement="left"
            closable={false}
            onClose={toggleDrawer}
            open={visible}
            width={500}
            maskClosable={false}
            mask={false}
            className={Styles.drawer}
          >
            <div className={Styles.drawerContent}>
              {renderContent(document)}
            </div>
          </Drawer>

          <Button
            type="primary"
            shape="circle"
            icon={visible ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}
            onClick={toggleDrawer}
            className={`${Styles.drawerToggleButton} ${
              visible ? Styles.drawerOpen : Styles.drawerClosed
            }`}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(DocumentPreview);
