import React, { useState } from "react";
import { Select, Button, message } from "antd";
import {
  PlusOutlined,
  InstagramOutlined,
  FacebookOutlined,
  TwitterOutlined,
  CloseOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import styles from "./SocialMedia.module.css";

const socialPlatforms = [
  {
    name: "Instagram",
    icon: <InstagramOutlined className={styles.icon} />,
    value: "instagram",
    baseURL: "https://instagram.com/",
  },
  {
    name: "Facebook",
    icon: <FacebookOutlined className={styles.icon} />,
    value: "facebook",
    baseURL: "https://facebook.com/",
  },
  {
    name: "Twitter",
    icon: <TwitterOutlined className={styles.icon} />,
    value: "twitter",
    baseURL: "https://twitter.com/",
  },
  {
    name: "TikTok",
    icon: <TikTokOutlined className={styles.icon} />,
    value: "tiktok",
    baseURL: "https://tiktok.com/@",
  },
];

const SocialMediaInput: React.FC<{ onChange: (data: any) => void }> = ({
  onChange,
}) => {
  const [socialMediaLinks, setSocialMediaLinks] = useState<any[]>([
    { platform: "instagram", profile_link: "" },
  ]);

  const [availablePlatforms, setAvailablePlatforms] = useState<any>(
    socialPlatforms.filter((platform) => platform.value !== "instagram")
  );

  const [selectedPlatform, setSelectedPlatform] = useState<any>(undefined);

  const handleInputChange = (platform: string, username: string) => {
    const updatedLinks = socialMediaLinks.map((link) =>
      link.platform === platform ? { ...link, profile_link: username } : link
    );
    setSocialMediaLinks(updatedLinks);
    onChange(updatedLinks);
  };

  const addNewPlatform = (platform: any) => {
    if (socialMediaLinks.some((link) => link.platform === platform)) {
      message.error(
        `${
          platform.charAt(0).toUpperCase() + platform.slice(1)
        } is already added.`
      );
      return;
    }

    const updatedLinks = [...socialMediaLinks, { platform, profile_link: "" }];
    setSocialMediaLinks(updatedLinks);
    setAvailablePlatforms(
      socialPlatforms.filter(
        (p: any) => !updatedLinks.some((link) => link.platform === p.value)
      )
    );
    setSelectedPlatform(undefined);
    onChange(updatedLinks);
  };

  const removePlatform = (platform: any) => {
    // Prevent Instagram from being removed
    if (platform === "instagram") {
      message.error("Instagram is mandatory and cannot be removed.");
      return;
    }

    const updatedLinks = socialMediaLinks.filter(
      (link) => link.platform !== platform
    );
    setSocialMediaLinks(updatedLinks);
    setAvailablePlatforms(
      socialPlatforms.filter(
        (p: any) => !updatedLinks.some((link) => link.platform === p.value)
      )
    );
    onChange(updatedLinks);
  };

  const confirmPlatformAddition = () => {
    if (selectedPlatform) {
      addNewPlatform(selectedPlatform);
    }
  };

  return (
    <div className={styles.socialMediaContainer}>
      <h3>Social Media Links</h3>
      {socialMediaLinks.map((link) => {
        const platformData = socialPlatforms.find(
          (p) => p.value === link.platform
        );

        return (
          <div key={link.platform} className={styles.socialMediaField}>
            {platformData?.icon}
            <div className={styles.urlDisplay}>
              {platformData?.baseURL}
              <input
                className={styles.inputField}
                placeholder={`Enter ${
                  link.platform.charAt(0).toUpperCase() + link.platform.slice(1)
                } username`}
                value={link.profile_link}
                onChange={(e) =>
                  handleInputChange(link.platform, e.target.value)
                }
                type="text"
              />
            </div>
            {link.platform !== "instagram" && (
              <Button
                type="link"
                icon={<CloseOutlined />}
                className={styles.removeButton}
                onClick={() => removePlatform(link.platform)}
              />
            )}
          </div>
        );
      })}

      {availablePlatforms.length > 0 && (
        <>
          <Select
            className={styles.selectField}
            placeholder="Select Social Media"
            onChange={(value) => setSelectedPlatform(value)}
            style={{ width: "100%" }}
            suffixIcon={<PlusOutlined />}
            value={selectedPlatform}
          >
            {availablePlatforms.map((platform: any) => (
              <Select.Option key={platform.value} value={platform.value}>
                {platform.icon} {platform.name}
              </Select.Option>
            ))}
          </Select>

          <Button
            type="primary"
            onClick={confirmPlatformAddition}
            disabled={!selectedPlatform}
            style={{ marginTop: "10px" }}
            className={styles.addButton}
          >
            Add Platform
          </Button>
        </>
      )}
    </div>
  );
};

export default SocialMediaInput;
